.root {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.empty {
  padding: 2rem 1rem;
  color: var(--colors-neutral-50);
  font-weight: 600;
  font-size: 1.5rem;
  text-align: center;
  height: 100%;
}

.buttons {
  padding: 0 0 2rem;
  margin: 0 1rem;
  border-bottom: 1px solid var(--colors-neutral-20);
  display: flex;
}
