.root {
  width: 74rem;

  .question {
    font-size: 3rem;
    font-weight: 600;
    line-height: 4rem;
    color: var(--colors-neutral-90);

    margin-bottom: 4rem;
  }

  .description {
    font-size: 1.75rem;
    font-weight: 500;
    line-height: 3rem;
    color: var(--colors-neutral-90);

    margin-bottom: 2rem;
  }

  .answers {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
  }

  .footer {
    display: flex;
    justify-content: space-between;

    width: 100%;
    margin-top: 4rem;
  }
}
