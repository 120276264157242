@import '../ListMixins.scss';

.root {
  @include rootHeader;

  .header {
    padding-left: 1rem;
    display: flex;
    align-items: center;
    height: 100%;

    &.flex {
      flex: 1;
      min-width: 40rem;
    }

    &:hover {
      .icon {
        display: flex;
      }
    }

    .icon {
      display: none;

      &.show {
        display: flex;
      }
    }
  }
}

.cursor {
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}
