.root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.emptyView {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 6rem;

  .label {
    margin-top: 1rem;
    color: var(--colors-neutral-80);
    font-weight: 600;
    font-size: 1.75rem;
  }
}

.total {
  color: var(--colors-neutral-60);
  font-size: 1.5rem;
  height: 2rem;
  line-height: 2rem;
  margin: 1rem 0 2rem 2rem;
}

.comment {
  + .comment {
    margin-top: 1rem;
  }
}
