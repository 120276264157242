.root {
  height: 100%;
  width: 100%;

  .setting {
    display: flex;
    align-items: flex-start;
    padding: 2.5rem 0;
    border-bottom: 1px solid var(--colors-neutral-10);

    .label {
      color: var(--colors-neutral-100);
      font-size: 14px;
      min-width: 25rem;
      padding-left: 2rem;
    }

    .value {
      display: flex;
      justify-content: space-between;
      width: 40rem;
      font-size: 14px;
      color: var(--colors-neutral-50);
    }

    .pencil {
      position: relative;
      margin-left: 1rem;

      .input {
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0;
        width: 100%;
        height: 100%;
        font-size: 0;
      }
    }
  }
}
