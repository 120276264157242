placeholder-element {
  display: inline-flex;
  align-items: baseline;
  text-decoration: none;
  overflow: hidden;
  --padding: 44px;

  &[onlyChild] {
    width: 100%;
  }

  img {
    width: 100%;
    height: 100%;
  }

  .container {
    user-select: none;
    height: 6rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 0;
    position: relative;

    .tooltip {
      user-select: none;
      pointer-events: none;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }

  .contentWrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: var(--colors-neutral-50);
    outline: 0;
    /* Neutral/2 */
    background-color: var(--colors-neutral-2);
    /* Neutral/10 */

    border: 1px solid var(--colors-neutral-10);
    box-sizing: border-box;
    border-radius: 4px;
    padding-left: var(--padding);
    padding-right: var(--padding);

    .iconWrapper {
      margin-right: 16px;
    }

    .info {
      font-family: Inter;
      font-size: 12px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      text-align: center;
      text-decoration: none;
    }
  }
}
