.content {
  flex: 1;
  margin-left: 4rem;
  display: flex;
  flex-direction: column;

  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  .headerContainer {
    display: flex;
    height: 4rem;
    margin-bottom: 3rem;
    justify-content: space-between;
    align-items: center;

    .backArrow {
      cursor: pointer;
    }

    .label {
      font-weight: 500;
      font-size: 16px;
      color: var(--colors-neutral-100);
      margin-left: 1rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 280px;
    }
  }

  .listContainer {
    flex: 1;
  }
}
