.root {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;

  .tabsWrapper {
    display: flex;
    flex-direction: row;
  }
}
