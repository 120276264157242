.noResults {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 8.75rem;

  .title {
    font-size: 14px;
    font-weight: 500;
    color: var(--colors-neutral-100);
    margin-bottom: 1rem;
  }

  .description {
    font-size: 12px;
    text-align: center;
    color: var(--colors-neutral-80);
  }
}
