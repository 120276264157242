.root {
  position: relative;
  padding: 0 1rem 0 1rem;
  background: var(--colors-white);

  .grid {
    display: grid;
    grid-gap: 0.5rem;

    &.invisible {
      top: 1rem;
      position: absolute;
      grid-gap: 0;
    }

    .square {
      height: 2rem;
      width: 2rem;
      background: var(--colors-neutral-10);
      border: 1px solid var(--colors-neutral-20);

      &.selected {
        background: var(--colors-blue-40);
        border: 1px solid var(--colors-blue-80);
      }

      &.invisible {
        background: transparent;
        border: 0;
        height: 2.5rem;
        width: 2.5rem;

        &:focus {
          outline: 0;
        }

        &:active {
          outline: 0;
        }
      }
    }
  }

  .label {
    display: flex;
    justify-content: center;
    color: var(--colors-neutral-100);
    width: 100%;
    line-height: 2rem;
    padding-top: 1rem;
  }
}
