.note {
  display: flex;
  position: relative;
  width: 100%;
  background-color: var(--colors-white);
  border-top: 1px solid var(--colors-neutral-20);

  //Make sure the svg doesnt overlap other elements (resizer, onboarding box, etc.)
  & > * {
    &:not(svg) {
      z-index: 1;
    }
  }

  .resizer {
    position: absolute;
    top: 0;
    width: 100%;
    height: 1rem;
    cursor: ns-resize;
  }

  .textContainer {
    padding: 1.5rem 1rem;
  }
}
