.container {
  background-color: var(--colors-neutral-2);
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr auto 6rem;
  grid-template-rows: 8rem 6rem 1fr 6rem;
  gap: 0px 0px;
  grid-template-areas:
    'title title title title'
    'menu menu menu menu'
    'leftpanel content rightpanel sidebar'
    'footer footer footer footer';
}
