@mixin rootHeader {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  border-bottom: 1px solid var(--colors-neutral-20);
  font-size: 1.75rem;
  color: var(--colors-neutral-50);
  height: 6rem;
  font-weight: 600;
  margin-left: 6rem;
}

@mixin rootRow {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 1px solid var(--colors-neutral-20);
  user-select: none;
  cursor: default;
  padding: 0;
  position: relative;
  font-size: 14px;
}
