.rootPreview {
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
  padding-top: 1rem;
}

.title {
  font-weight: 500;
  line-height: 3rem;
  color: var(--colors-neutral-100);
  margin-bottom: 1rem;
}
