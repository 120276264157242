.content {
  display: flex;

  .grid-container {
    display: grid;
    grid-template-columns: repeat(20, 1fr);
    grid-auto-columns: 3rem;
    grid-auto-rows: 3rem;
    align-items: center;
    justify-items: center;
    width: 60rem;

    .grid-item {
      cursor: pointer;
      width: 100%;
      height: 100%;
      line-height: 3rem;
      text-align: center;

      &:hover {
        border-radius: 0.5rem;
        border: 1px solid var(--colors-neutral-50);
      }
    }
  }

  .preview {
    width: 25%;
    height: auto;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .value {
      font-size: 6rem;
    }

    .description {
      margin-top: 2rem;
      color: var(--colors-neutral-60);
    }
  }
}
