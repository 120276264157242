column-break-element {
  font-family: Inter;
  color: var(--colors-neutral-60);
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  outline: 0;
  text-rendering: optimizeSpeed;

  &.inline {
    display: inline;

    &[onlyChild] {
      width: 98%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
