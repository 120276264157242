.root {
  display: flex;
  flex-direction: row;

  .multiPreviews {
    display: flex;
    flex-direction: column;
    width: 100%;

    > :not(:first-child) {
      margin-top: 2rem;
    }
  }
}
