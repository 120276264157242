.defaultContainer {
  .detailsContainer {
    display: flex;
    flex-direction: column;
    padding: 4rem 0 1rem 0;
    margin: 0 1rem;
  }

  .emptyItem {
    color: var(--colors-neutral-40);
  }
}

.root {
  display: flex;
  flex-direction: column;

  .dateValues {
    color: var(--colors-neutral-100);
    font-size: 1.75rem;
    line-height: 3rem;
  }

  .dueDateLabels {
    display: flex;
    align-self: flex-start;
    flex-direction: column;
    gap: 3rem;
    margin-top: 0.5rem;
  }
}
