.popoverContainer {
  padding: 0;
  border-radius: 2px;
  border: solid 1px var(--colors-neutral-10);
  box-shadow: 0 4px 6px 0 rgba(46, 46, 76, 0.2);
}

.optionsContainer {
  border-radius: 2px;
  background-color: var(--colors-white);
  box-shadow: 0 4px 6px 0 rgba(46, 46, 76, 0.2);
  border: 0;
  padding: 1rem 0rem;
  width: 240px;
}

.buttonsContainer {
  width: 240px;
  height: 38px;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    background: var(--colors-blue-10);
  }
}

.text {
  height: 4rem;
  font-size: 14px;
  line-height: 2.29;
  margin: 0rem 5rem;
  color: var(--colors-neutral-100);
  font-family: Inter, 'system-ui', sans-serif;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
