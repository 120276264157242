.errorReport {
  .header {
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-weight: 600;
  }

  .list {
    margin-left: 1rem;
    max-height: 20rem;
    overflow-y: auto;
    .row {
      font-size: 1.5rem;
      padding-bottom: 0.5rem;
      .title {
        font-weight: 500;
        color: var(--colors-neutral-50);
      }
      .errors {
        line-height: 2.5rem;
      }
    }
  }
}
