.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;

  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2rem;

  overflow: hidden;

  .labelsWrap {
    display: flex;
    flex-direction: column;

    flex: 1;
    min-width: 0;

    .title {
      color: var(--colors-neutral-60);
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 2rem;
    }

    .userName {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .date {
      font-weight: 400;
      color: var(--colors-neutral-80);
    }
  }
}
