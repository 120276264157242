.table {
  position: 'relative';
  > svg {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.td {
  position: relative;
  word-break: break-all;

  > svg {
    position: absolute;
    top: 0;
    left: 0;
  }
}
