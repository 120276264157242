.root {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 1rem 0 1rem 1rem;

  .title {
    font-size: 2rem;
    font-weight: 600;
    color: var(--colors-white);
    line-height: 3rem;
  }
}
