.root {
  letter-spacing: 1px;
  font-size: 1.25rem;
  line-height: 2rem;
  font-weight: 500;

  &.hero {
    color: var(--colors-blue-50);
  }
  &.white {
    color: var(--colors-blue-30);
  }
}
