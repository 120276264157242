.root {
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  .properties {
    display: flex;
    gap: 1rem;
  }
}
