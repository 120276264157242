.root {
  display: flex;
  height: 4rem;

  .avatar {
    margin-right: 1.5rem;
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 2rem;

    .name {
      font-size: 1.75rem;
    }
    .number {
      font-size: 1.5rem;
      color: var(--colors-neutral-60);
    }
  }
}
