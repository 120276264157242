.root {
  display: flex;
  flex-direction: column;

  .description {
    font-size: 12px;
    line-height: 16px;
  }

  .options {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 2rem;

    .optionDescription {
      font-size: 10px;
      line-height: 16px;
      color: var(--colors-neutral-70);

      margin-left: 3.5rem;
    }
  }
}
