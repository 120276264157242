.toolbar {
  display: flex;
  height: 7rem;
  margin-bottom: 4rem;

  .separator {
    margin: 0 3.4rem;
    width: 1px;
    background-color: var(--colors-neutral-20);
  }
}
