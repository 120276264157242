.subOptionsContainer {
  margin: 1rem 0;

  .subOption {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    line-height: 1.33;
    border-radius: 0.5rem;
    padding: 1rem 2.5rem;
    height: 4rem;
    margin: 0 0 1rem 0;

    &:hover {
      background-color: var(--colors-blue-10);
    }

    &.selected {
      background-color: var(--colors-blue-20);
    }
  }
}
