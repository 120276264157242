:root {
  --width: 20rem;
}

.root {
  flex: 1;
  padding: 2rem 6rem 0 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .contentContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .content {
    display: flex;
    flex: 1;
    min-height: 0;
    height: 100%;
    margin-left: 6rem;
    margin-bottom: 3rem;

    .table {
      display: flex;
      flex-direction: column;
      flex: 1;
      height: 100%;
    }
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8rem;
  flex-direction: column;

  .message {
    font-weight: 500;
    font-size: 1.75rem;
    line-height: 3rem;
    margin-top: 2rem;
    color: var(--colors-neutral-100);
  }
}

.metadataCellEllipsis {
  white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: table-cell;
    max-width: 180px;
}
