.hyphenate {
  hyphens: auto;
}

.dodoc_page,
.dodoc_page:focus {
  outline: none !important;
  tab-size: 4;
  flex-direction: column;
  padding: 92px 0 30mm 0;
  height: 100%;
  /* min-height: 297mm; */
  white-space: pre-wrap;
  margin-right: 88px;
  margin-left: 88px;
}

td[lock] {
  border: solid 1px var(--colors-neutral-50);
  background-color: var(--colors-neutral-5);
}

.dodoc_page {
  font-family: Arial;
  color: var(--colors-black);
  position: relative;
  z-index: 1; /* important for image positioning */

  & * {
    outline: 0;
  }

  &[data-show-invisible-content='false'] {
    /* [data-vanish="true"], */
    [vanish='true'] {
      display: none;
    }
  }

  &.canvas {
    padding: 92px 0 30mm;
    height: 100%;

    & > * {
      /* transition: width 0.25s ease-out; */
      /* width: 736px; */
      @supports not (-ms-high-contrast: none) {
        /* Non-IE styles here */
        /* max-width: 736px; */
      }
    }

    & > table {
      transition: none;
    }
  }

  &[data-layout='WEB'] {
    approved-element,
    approve-view-element,
    permission-view-element,
    > track-ins-element,
    > track-del-element {
      width: fit-content;

      table-of-labels-element,
      table-of-contents-element {
        width: calc(var(--contentWidth) - var(--marginSize, 0px));
      }

      table-of-contents-legacy-element,
      references-section-element,
      list-of-tables-element,
      list-of-figures-element,
      keywords-element,
      authors-element,
      paragraph-element {
        --contentWidth: var(--sectionWidth);

        width: calc(var(--contentWidth) - var(--marginSize, 0px));
      }
    }

    > table,
    /* > table-of-labels-element,
    > table-of-contents-element, */
    > figure-element,
    > page-break-element,
    > invalid-element,
    > section-break-element {
      --contentWidth: var(--sectionWidth);
    }

    > table-of-contents-legacy-element,
    > references-section-element,
    > redacted-element,
    > loader-element,
    > list-of-tables-element,
    > list-of-figures-element,
    > keywords-element,
    > authors-element,
    > paragraph-element {
      --contentWidth: var(--sectionWidth);

      width: calc(var(--contentWidth) - var(--marginSize, 0px));
    }

    td {
      paragraph-element,
      track-ins-element,
      track-del-element {
        width: auto;
      }

      figure-element {
        max-width: auto;
      }
    }
  }

  &[data-layout='PAGE'] {
    background-color: var(--colors-neutral-10);
    margin: 0;
    display: flex;
    align-items: center;
  }

  & > track-del-element,
  & > track-ins-element {
    display: block;
  }

  & *[task] {
    box-shadow: inset var(--contentWidth) 0px 0px rgba(46, 146, 230, 0.15),
      -2rem 0 0 rgba(46, 146, 230, 0.15), 2rem 0 0 rgba(46, 146, 230, 0.15);

    &[lock] {
      box-shadow: inset var(--contentWidth) 0px 0px var(--colors-neutral-5),
        0 0 0 1px var(--colors-neutral-20), -2rem 0 0 rgba(46, 146, 230, 0.15),
        2rem 0 0 rgba(46, 146, 230, 0.15);
    }

    &[selectedtask='true'] {
      box-shadow: inset var(--contentWidth) 0px 0px var(--colors-alternate-blue-70),
        -2rem 0 0 var(--colors-alternate-blue-70), 2rem 0 0 var(--colors-alternate-blue-70),
        0px 8px 8px rgba(0, 0, 0, 0.25);
    }

    &[selectedtask='true'][lock] {
      box-shadow: inset var(--contentWidth) 0px 0px var(--colors-neutral-5),
        0 0 0 1px var(--colors-neutral-20), -2rem 0 0 var(--colors-alternate-blue-70),
        2rem 0 0 var(--colors-alternate-blue-70), 0px 8px 8px rgba(0, 0, 0, 0.25);
    }
  }

  & > table[task] {
    box-shadow: inset var(--width) 0px 0px rgba(46, 146, 230, 0.15),
      -2rem 0 0 rgba(46, 146, 230, 0.15), 2rem 0 0 rgba(46, 146, 230, 0.15);

    &[selectedtask='true'] {
      box-shadow: inset var(--width) 0px 0px var(--colors-alternate-blue-70),
        -2rem 0 0 var(--colors-alternate-blue-70), 2rem 0 0 var(--colors-alternate-blue-70),
        0px 8px 8px rgba(0, 0, 0, 0.25);
    }
  }

  div {
    clear: both !important;
    font-size: inherit;
    font-style: normal;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      width: 100%;
    }
  }

  cross-reference-element {
    color: var(--colors-dark-blue-100);
  }
}

@page {
  size: A4 landscape;
  margin: 2cm;
}
