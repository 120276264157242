.subComment {
  border-radius: 0;
  background-color: var(--colors-neutral-5);
  border-top: solid 1px var(--colors-neutral-20);
}

.imported {
  margin-bottom: 1rem;
  color: var(--colors-neutral-40);
}

.labels {
  flex: 1;

  .author {
    display: flex;
    align-items: center;
    font-weight: 500;
    color: var(--colors-neutral-100);
    font-size: 12px;
    height: 2rem;
  }

  .authorName {
    max-width: 25rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .time {
    font-size: 12px;
    color: var(--colors-neutral-80);
    line-height: 2rem;
  }
}

.readMore {
  cursor: pointer;
  font-size: 12px;
  color: var(--colors-blue-70);
  margin-top: 1rem;
}

.text {
  color: var(--colors-neutral-100);
  font-size: 1.5rem;
  word-break: break-word;
  white-space: pre-line;

  &.readLess {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
}

.replyContainer {
  padding: 2rem;
  border-top: solid 1px var(--colors-neutral-20);
  background-color: var(--colors-neutral-5);
  width: 100%;

  .reply {
    display: flex;
  }

  .replyButtons {
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;
  }
}

.notImportedContent {
  margin-top: 1rem;
  font-size: 12px;
  line-height: 1.33;
  color: var(--colors-neutral-50);
}

.numberOfReplies {
  color: var(--colors-neutral-60);
  font-size: 1.75rem;
  padding-top: 0.6rem;
}

.leftPanel {
  margin-right: auto;
  display: flex;
  align-items: center;

  .likes {
    display: flex;
    flex-direction: row;

    .counter {
      display: flex;
      margin-top: 0.6rem;
    }
  }
}
