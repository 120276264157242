redacted-element {
  --leftMargin: 0pt;
  --rightMargin: 0pt;
  --marginSize: calc(var(--leftMargin) + var(--rightMargin));
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: var(--leftMargin);
  margin-right: var(--rightMargin);
  width: auto;
  user-select: none;

  @supports not (-ms-high-contrast: none) {
    /* Non-IE styles here */
    display: flex;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE styles here */
    display: block;
  }
}

.container {
  position: relative;
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 2px 0;
}

.icon {
  position: absolute;
  height: 100%;
  width: 40px;
  min-width: 40px;
  max-width: 40px;
  margin-left: -40px;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  cursor: default;
}

.iconContent {
  height: 24px;
  width: 24px;
  opacity: 0.5;
  background-color: transparent;
  background-image: url('./preview-copy.svg');
}

.wrapper {
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: var(--colors-neutral-5);
  border: solid 1px var(--colors-neutral-20);
  color: var(--colors-neutral-50);
  outline: 0;
  user-select: none;
}

.info {
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
}
