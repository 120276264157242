.queryBuilder {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .builderSelects {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 2rem;
    margin-right: 2rem;

    .preceding {
      font-size: 1.5rem;
      align-self: center;
      white-space: nowrap;
    }
  }

  .builderActions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
  }
}
