.cardWrapper {
  margin-bottom: 1rem;

  .body {
    display: flex;
    align-items: center;
    .dropdown {
      margin-left: auto;
    }
  }

  &.cardPointer {
    cursor: pointer;
  }
}
