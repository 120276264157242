.header {
  margin-top: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 8rem;
  padding: 0 2rem;
  background: var(--colors-dark-blue-5);
  color: var(--colors-neutral-80);
  font-size: 2.25rem;
  line-height: 3rem;
  font-weight: 600;
}
