.instructions {
  height: 15rem;
  font-size: 1.75rem;
  line-height: 1.71;
  color: var(--colors-neutral-100);
}

.successInstructions {
  height: 8rem;
  border-radius: 0.5rem;
  background-color: var(--colors-green-60);
  border: solid 1px var(--colors-green-80);

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem;
  color: var(--colors-white);
  font-size: 1.75rem;
  text-align: center;
}

.blueBox {
  height: 11rem;
  border-radius: 0.5rem;
  background: var(--colors-blue-10);
  border: solid 1px var(--colors-blue-20);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem;
  color: var(--colors-neutral-80);
  font-size: 1.75rem;
  text-align: center;
}

.forgotPassword {
  cursor: pointer;
  color: var(--colors-blue-80);
  font-size: 14px;
  line-height: 3rem;
  text-align: left;
  margin-right: auto !important; /*space-between*/
}
