.root {
  + .root {
    margin-top: 1rem;
  }

  .info {
    height: 5rem;
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 1rem;
    background-color: var(--colors-blue-5);
    cursor: pointer;
    color: var(--colors-neutral-80);
  }

  .angleIcon {
    margin-right: 1rem;

    &.isAngleClose {
      transform: rotate(-90deg);
    }
  }

  .close {
    margin-left: auto;
  }

  .fields {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: 1fr 40rem;
    gap: 1rem;
  }
}
