.underlinedText {
  cursor: pointer;
  color: var(--colors-blue-90);
  text-decoration: underline;
  text-decoration-color: var(--colors-blue-90);
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
