.container {
  display: flex;

  .customizePane {
    height: 69rem;
    overflow: auto;
    margin-right: 1.25rem;
    padding: 0.5rem 1rem 0 0.5rem;

    .customizableItem {
      display: flex;
      flex-direction: column;
      justify-content: center;
      cursor: pointer;
      width: 13rem;
      height: 12rem;
      padding: 1rem;
      border: solid 1px var(--colors-neutral-20);
      margin-bottom: 3rem;

      &.selected {
        box-shadow: 0 0 0 2px rgba(29, 148, 243, 0.4);
        border: solid 1px var(--colors-neutral-60);
      }
    }
  }

  .header {
    font-weight: 500;
    line-height: 1.71;
    padding: 1.5rem 0;
    border-bottom: solid 1px var(--colors-neutral-20);
    margin-bottom: 1rem;
  }

  .text {
    height: 5rem;
    padding: 1.5rem 0;
    font-size: 12px;
    line-height: 1.33;
  }

  .listPane {
    .listHeaderContainer {
      display: flex;
      padding-left: 2rem;
      height: 6rem;
      border-bottom: solid 1px var(--colors-neutral-20);

      .headerText {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.71;
        color: var(--colors-neutral-50);
      }
    }

    .listItemsContainer {
      display: flex;
      flex-direction: column;

      .item {
        display: flex;
        align-items: center;
        height: 7rem;
        padding: 1.5rem 0 1.5rem 2rem;
        border-bottom: solid 1px var(--colors-neutral-20);

        &:hover {
          cursor: pointer;
          background-color: var(--colors-blue-10);
        }

        &.selected {
          background-color: var(--colors-blue-20);
        }

        .level {
          font-size: 12px;
          width: 11rem;
        }

        .style {
          width: 27rem;
        }

        .type {
          font-size: 12px;
          width: 25rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          .notDefined {
            color: var(--colors-neutral-40);
          }
        }
      }
    }
  }

  .emptyState {
    height: 69rem;
    width: 53rem;
    margin-left: 5rem;
    padding-right: 2rem;
    padding-left: 14rem;
    margin-right: -2rem;
    padding-top: 7rem;

    &.customizeEmptyState {
      padding-left: 11rem;
      margin-left: 0;
    }
  }

  .formattingPane {
    height: 69rem;
    overflow: auto;
    margin-left: 5rem;
    padding-right: 2rem;
    padding-left: 1rem;
    margin-right: -2rem;

    &.customizeFormattingPane {
      margin-left: 2.5rem;
    }

    .formattingContent {
      display: flex;
      flex-direction: column;

      .boxWrapper {
        display: flex;

        .leftBox {
          display: flex;
          flex-direction: column;
        }

        .rightBox {
          display: flex;
          flex-direction: column;
          margin-left: 2rem;
        }
      }

      .specialIdentation {
        display: flex;
      }
    }

    .applyStyles {
      display: flex;
      align-items: center;
      height: 4rem;
      margin-bottom: 4rem;
    }

    .gridContainer {
      display: grid;
      margin-top: 2rem;
      grid-template-columns: repeat(7, 1fr);
      grid-column-gap: 2rem;
    }

    .gridItem {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3rem;
      border-radius: 4px;
    }

    .moreBullets {
      display: flex;
      align-items: center;
      height: 5rem;
      margin-top: 3rem;
      margin-bottom: 4rem;
      border-radius: 4px;
      font-size: 12px;
      line-height: 1.33;

      &:hover {
        cursor: pointer;
        background-color: var(--colors-blue-5);
      }

      &.isOpen {
        margin-bottom: 2rem;
      }

      .angleIcon {
        margin-right: 1rem;

        &.isAngleClose {
          transform: rotate(-90deg);
        }
      }
    }

    .moreBulletsContainer {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      grid-column-gap: 2rem;
      grid-row-gap: 2rem;
      margin-bottom: 4rem;
      height: 16rem;
      overflow: auto;
    }
  }
}
