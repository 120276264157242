.root {
  display: flex;
  color: var(--colors-neutral-80);
  font-weight: 500;
  align-items: center;
  font-size: 1.5rem;
  height: 6rem;
  border-top: 1px solid var(--colors-neutral-20);
  margin-left: 6rem;
}

.selectedItems {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.filter {
  flex: 1;
  display: flex;
  align-items: center;
  cursor: pointer;
}
