.container {
  &:hover {
    .background {
      background-color: var(--colors-blue-10);
      cursor: pointer;
    }

    .number {
      div {
        background-color: var(--colors-blue-10);
      }
    }
  }

  &.current {
    .background {
      background-color: var(--colors-blue-30);
    }

    .number {
      div {
        background-color: var(--colors-blue-70);
        color: white;
      }
    }
  }

  .background {
    border-radius: 0.75rem;
    padding: 1rem;
    margin: 0 auto 1rem;

    .thumbnail {
      position: relative;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
    }
  }
  .number {
    margin-top: 1rem;
    text-align: center;
    line-height: 3rem;
    font-size: 2rem;
    font-weight: 500;
    color: var(--colors-neutral-50);
    display: flex;
    justify-content: center;

    div {
      display: flex;
      border-radius: 0.25rem;
      padding: 0 0.5rem;
    }
  }
}
