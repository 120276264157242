.preview {
  position: sticky;
  top: -32px;
  margin-top: -32px;
  z-index: 4;
  background-color: var(--colors-white);
  padding: 32px 16px 0px 16px;

  .title {
    line-height: 3rem;
    color: var(--colors-neutral-100);
    padding: 1rem 0;
    font-weight: 500;
  }

  .previewCrossReference {
    height: auto;
    border: 1px dashed var(--colors-neutral-30);
    box-sizing: border-box;
    border-radius: 4px;
    padding: 2rem;
    max-height: 6rem;
    margin-bottom: 3rem;

    &.noSupport {
      padding: 1rem 2rem;
    }

    .text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 2rem;
      color: var(--colors-neutral-100);

      &.empty {
        padding-left: 1rem;
      }
    }

    .noPreview {
      color: var(--colors-neutral-40);
      line-height: 2rem;
    }
  }

  .warningState {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.content {
  display: flex;
  flex-direction: column;
  padding: 0 2rem;

  .title {
    line-height: 3rem;
    color: var(--colors-neutral-100);
    padding: 1rem 0;
    font-weight: 500;
  }

  .captionsList {
    max-height: 18rem;
    border-radius: 4px;
    border: 1px solid var(--colors-neutral-20);
    overflow: auto;
    min-height: 18rem;
    background-color: var(--colors-white);

    .captionElement {
      height: 6rem;
      background-color: var(--colors-white);
      padding: 1rem 4rem;
      border-bottom: 1px solid var(--colors-neutral-20);
      line-height: 4rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:hover {
        background-color: var(--colors-blue-10);
      }

      &.selected {
        background-color: var(--colors-blue-20);
      }
    }
  }

  .emptyList {
    display: flex;
    flex-direction: column;

    .emptyCaptionsList {
      padding: 2rem;
      height: 14.75rem;

      .emptyCaptionsListTitle {
        font-size: 1.75rem;
        color: var(--colors-neutral-100);
        font-weight: 600;
        line-height: 3rem;
        text-align: center;
      }

      .emptyCaptionsListMessage {
        color: var(--colors-neutral-80);
        font-size: 1.5rem;
        line-height: 2rem;
        text-align: center;
      }
    }
  }

  .filterOption {
    padding: 2rem 0 0 2rem;
    display: flex;
    width: 20.25rem;
    height: 5rem;

    &:last-child {
      padding-bottom: 2rem;
      height: 7rem;
    }
  }
}
