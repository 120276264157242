.root {
  width: 52.75rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  border-left: 1px solid var(--colors-neutral-20);
  flex: 1;
  min-height: 0;
  max-height: calc(100vh - 57rem);

  .empty {
    margin-top: 18rem;
    display: flex;
    justify-content: center;
  }

  .entry {
    padding: 0 2rem;
  }
}

.fields {
  margin-top: 1rem;
  overflow: auto;
  flex: 1;
  padding-right: 2rem;
  padding-left: 2rem;

  .field {
    display: flex;
    flex-direction: column;
    padding-top: 0.5rem;
    margin-bottom: 2rem;
    border-top: 1px solid var(--colors-neutral-10);

    .author {
      display: flex;
      flex-direction: row;

      margin-top: 0.5rem;

      .nameInputs {
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }

      .input {
        flex: 1;
      }
    }

    .input {
      display: flex;
      align-items: center;
      min-width: 29rem;
    }
  }
}
