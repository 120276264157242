.root {
  display: flex;
  gap: 4rem;

  overflow: auto;
  max-height: inherit;

  padding: 3rem 0rem 0.5rem;
  margin-bottom: 10rem; //Fixes issue with overflow due to wrong height from 'inherit'

  .column {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    flex: 1;
  }
}
