.root {
  position: absolute;
  pointer-events: none;
  box-shadow: 0 0 0 1px var(--colors-black);

  * {
    pointer-events: auto;
  }

  .anchor {
    width: 1rem;
    height: 1rem;
    border: 1px solid var(--colors-black);
    background-color: var(--colors-white);
    position: absolute;
    z-index: 5;

    &:hover {
      background-color: var(--colors-neutral-50);
    }

    &.nw {
      cursor: nwse-resize;
      top: -6px;
      left: -6px;
    }

    &.ne {
      cursor: nesw-resize;
      top: -6px;
      right: -6px;
    }

    &.sw {
      cursor: nesw-resize;
      bottom: -6px;
      left: -6px;
    }

    &.se {
      cursor: nwse-resize;
      bottom: -6px;
      right: -6px;
    }

    &.n {
      cursor: ns-resize;
      top: -6px;
      left: 50%;
      transform: translateX(-50%);
    }
    &.e {
      cursor: ew-resize;
      top: 50%;
      right: -6px;
      transform: translateY(-50%);
    }
    &.s {
      cursor: ns-resize;
      bottom: -6px;
      left: 50%;
      transform: translateX(-50%);
    }
    &.w {
      cursor: ew-resize;
      top: 50%;
      left: -6px;
      transform: translateY(-50%);
    }
  }
}
