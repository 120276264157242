.root {
  display: flex;
  flex-direction: row;
  gap: 1rem;

  padding: 2rem 1rem;

  .emptyState {
    justify-content: center;

    margin: 1rem;

    font-size: 1.5rem;
    line-height: 2rem;
    color: var(--colors-neutral-60);
  }

  .collaborators {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
  }
}
