.bottomWrapper {
  position: absolute;
  bottom: 0;

  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  padding: 0.5rem;
}

.element {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  padding: 0.5rem;

  background-color: rgba(245, 245, 246, 0.8);
  border-radius: 0.5rem;

  &.note {
    position: absolute;
    top: 0.5rem;
    right: 0;
    border-radius: 0.5rem 0 0 0.5rem;
  }

  > span {
    font-size: 1.25rem;
    line-height: 2rem;
    font-weight: 500;
    color: var(--colors-neutral-70);
  }
}
