track-del-element {
  --marginSize: 0px;
  --strikeColor: initial;

  width: auto;
  background-color: transparent;
  margin: 0;

  text-decoration-line: line-through;
  text-decoration-style: initial;

  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  vertical-align: inherit;

  &[replacewith]:empty:after,
  &[replacewithsibling]:empty:after {
    content: '\00B6';
  }

  & > figure-element {
    max-width: max-content;
  }

  & > citation-element:not(:first-child) {
    &::before {
      content: ',';
    }
  }

  & > authors-element,
  & > keywords-element,
  & > list-of-figures-element,
  & > list-of-tables-element,
  & > page-break-element,
  & > section-break-element,
  & > table-of-contents-legacy-element
  {
    text-decoration: none;
    display: inline-block;
  }

  > references-section-element,
  > table-of-contents-element,
  > table-of-labels-element {
    text-decoration: inherit;

     > * {
      text-decoration: inherit !important;
     }
  }
  
  equation-element {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      border-top: 1px solid;
      border-color: var(--strikeColor);
      top: 50%;
      left: 0;
      right: 0;
    }
  }

  img {
    opacity: 0.5;
  }

  &[lock] {
    box-shadow: inset var(--colors-neutral-5) var(--contentWidth) 0,
      0 0 0 1px var(--colors-neutral-20);
  }
}

track-del-element[selected] {
  background-color: transparent;
}

track-del-element[hide='true'] > * {
  display: none;
}

track-del-element[hide='true']::after {
  content: '\00B6';
  font-size: 12px;
}

track-del-element[hide='true'] {
  font-size: 0px;
  text-decoration: none;
}

/* track-del-element[replaceable] > * {
  display: none;
}

track-del-element[replaceable]::after {
  content: '\00B6';
  font-size: 12px;
  text-decoration: line-through;
}

track-del-element[replaceable] {
  font-size: 0px;
  text-decoration: none;
} */
