/* stylelint-disable scale-unlimited/declaration-strict-value */
@import './config.scss';

/* Inter Regular */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Inter/Inter-Regular.woff'), url('../fonts/Inter/Inter-Regular.woff2');
  font-display: block;
}
/* Inter Regular Italic */
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/Inter/Inter-Italic.woff'), url('../fonts/Inter/Inter-Italic.woff2');
  font-display: block;
}
/* Inter Medium */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/Inter/Inter-Medium.woff'), url('../fonts/Inter/Inter-Medium.woff2');
  font-display: block;
}
/* Inter Medium Italic */
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 500;
  src: url('../fonts/Inter/Inter-MediumItalic.woff'), url('../fonts/Inter/Inter-MediumItalic.woff2');
  font-display: block;
}
/* Inter SemiBold */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/Inter/Inter-SemiBold.woff'), url('../fonts/Inter/Inter-SemiBold.woff2');
  font-display: block;
}
/* Inter SemiBold Italic */
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 600;
  src: url('../fonts/Inter/Inter-SemiBoldItalic.woff'),
    url('../fonts/Inter/Inter-SemiBoldItalic.woff2');
  font-display: block;
}
/* Inter Bold */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/Inter/Inter-Bold.woff'), url('../fonts/Inter/Inter-Bold.woff2');
  font-display: block;
}
/* Inter Bold Italic*/
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/Inter/Inter-BoldItalic.woff'), url('../fonts/Inter/Inter-BoldItalic.woff2');
  font-display: block;
}

/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/OpenSans-Regular.woff2') format('woff2'),
    url('../fonts/OpenSans-Regular.woff') format('woff');
  font-display: block;
}

/* Carlito-regular - latin */
@font-face {
  font-family: 'Calibri';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Carlito-Regular.woff2') format('woff2');
  font-display: block;
}

/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/OpenSans-SemiBold.woff2') format('woff2'),
    url('../fonts/OpenSans-SemiBold.woff') format('woff');
  font-display: block;
}

/* Carlito-600 - latin */
@font-face {
  font-family: 'Calibri';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/Carlito-Bold.woff2') format('woff2');
  font-display: block;
}

/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/OpenSans-Bold.woff2') format('woff2'),
    url('../fonts/OpenSans-Bold.woff') format('woff');
  font-display: block;
}

/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/OpenSans-Italic.woff2') format('woff2'),
    url('../fonts/OpenSans-Italic.woff') format('woff');
  font-display: block;
}

/* Carlito-italic - latin */
@font-face {
  font-family: 'Calibri';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/Carlito-Italic.woff2') format('woff2');
  font-display: block;
}

/* Carlito-italic-600 - latin */
@font-face {
  font-family: 'Calibri';
  font-style: italic;
  font-weight: 600;
  src: url('../fonts/Carlito-BoldItalic.woff2') format('woff2');
  font-display: block;
}

/* Symbola */
@font-face {
  font-family: 'Symbola';
  src: url('../fonts/Symbola.ttf') format('truetype');
  font-display: block;
}

html {
  font-size: 8px;
  height: 100%;

  &:global(.po) {
    margin-right: 17px;
  }
}

body {
  font-family: Inter, 'Open Sans', Helvetica, Arial, sans-serif;
  font-size: 1.75rem;
  color: var(--colors-neutral-100);
  margin: 0;
}

body:global(.modal-open) {
  overflow-x: hidden;
  overflow-y: hidden;
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

mark {
  color: var(--colors-neutral-70);
  background: var(--colors-yellow-20);
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

fieldset {
  padding: 4rem;
  width: 50rem;
  border: 1px solid var(--colors-neutral-20);
  border-radius: 10px;
  margin: 4.5rem auto;

  &:global(.noThirdParty) {
    padding-bottom: 6rem;
  }

  legend {
    color: var(--colors-neutral-60);
    margin: 0 auto;
    padding: 0 20px;
    font-size: 18px;
    width: auto;
    border-bottom: none;
    text-transform: uppercase;
    text-align: center;
  }
}

textarea {
  resize: none;
}

:global(.auth-link-container) {
  margin: 3rem 0;
  text-align: center;
}

:global(.link) {
  color: var(--colors-blue-100) !important;
  font-size: 1.75rem;

  &:hover {
    color: var(--colors-blue-100);
    border-bottom: 1px solid var(--colors-blue-100);
    cursor: pointer;
  }
}

button {
  &:focus {
    outline: 0;
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

:global(.form-heading) {
  margin-bottom: 2rem;
  font-size: 2rem;
  color: var(--colors-neutral-80);
  font-weight: 600;
}

table {
  border-collapse: collapse;
  margin: 0;
}

/*******************************************************************************
** MathJax
*******************************************************************************/

:global(#MathJax_Message) {
  display: none !important;
}

/*******************************************************************************
** Panel
*******************************************************************************/

:global(.dd-panel) {
  color: var(--colors-neutral-80);
  border: 1px solid var(--colors-neutral-40);
  border-radius: 4px;

  :global(.dd-panel-head) {
    font-weight: 700;
    height: 5rem;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    background: var(--colors-neutral-5);
    border-bottom: 1px solid var(--colors-neutral-40);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
}

:global(.dd-table) {
  :global(.dd-table-head) {
    height: 5rem;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    font-weight: 600;
    border-bottom: 1px solid var(--colors-neutral-20);
  }

  :global(.dd-table-row) {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    font-weight: normal;
    box-shadow: 0 -1px 0 var(--colors-neutral-20);
    overflow: visible;
    height: 6.75rem;
  }
}

/*******************************************************************************
** CSSTransitionGroup (csstg)
*******************************************************************************/

:global(.csstg-scale-enter) {
  opacity: 0.01;
  transform: scale3d(0.8, 0.8, 1);
}

:global(.csstg-scale-enter.csstg-scale-enterActive) {
  opacity: 1;
  transform: scale3d(1, 1, 1);
  transition: all 150ms ease-out;
}

:global(.csstg-scale-leave) {
  opacity: 1;
  transform: scale3d(1, 1, 1);
}

:global(.csstg-scale-leave.csstg-scale-leaveActive) {
  opacity: 0.01;
  transform: scale3d(0.8, 0.8, 1);
  transition: all 150ms ease-out;
}

:global(.csstg-drop-enter) {
  opacity: 0.01;
  transform: translate3d(0, -10%, 0);
}

:global(.csstg-drop-enter.csstg-drop-enterActive) {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: all 150ms ease-out;
}

:global(.csstg-drop-leave) {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

:global(.csstg-drop-leave.csstg-drop-leaveActive) {
  opacity: 0.01;
  transform: scale3d(0.8, 0.8, 1);
  transition: all 150ms ease-out;
}

:global(.csstg-enter) {
  opacity: 0.01;
  height: 6.75rem;
}

:global(.csstg-enter5) {
  opacity: 0.01;
  height: 5rem;
}

:global(.csstg-enter--slide__medium) {
  opacity: 0.01;
  height: 1px !important;
}

:global(.csstg-enter):global(.csstg-enterActive) {
  opacity: 1;
  height: 6.75rem;
  transition: opacity 500ms ease-in;
  transform: translate3d(0, 0, 0);
}

:global(.csstg-enter5):global(.csstg-enterActive) {
  opacity: 1;
  height: 5rem;
  transition: opacity 500ms ease-in;
  transform: translate3d(0, 0, 0);
}

:global(.csstg-enter--slide__medium):global(.csstg-enterActive) {
  opacity: 1;
  height: 5rem !important;
  transition: all 300ms ease-in;
  transform: translate3d(0, 0, 0);
}

:global(.csstg-leave) {
  opacity: 1;
  height: 6.75rem !important;
  overflow: hidden !important;
}

:global(.csstg-leave5) {
  opacity: 1;
  height: 5rem !important;
  overflow: hidden !important;
}

:global(.csstg-leave--slide__medium) {
  opacity: 1;
  height: 5rem !important;
  overflow: hidden !important;
}

:global(.csstg-leave):global(.csstg-leaveActive) {
  opacity: 0.01;
  height: 1px !important;
  transition: all 300ms ease-out;
  transform: translate3d(0, 0, 0);
  overflow: hidden !important;
}

:global(.csstg-leave5):global(.csstg-leaveActive) {
  opacity: 0.01;
  height: 1px !important;
  transition: all 300ms ease-out;
  transform: translate3d(0, 0, 0);
  overflow: hidden !important;
}

:global(.csstg-leave--slide__medium):global(.csstg-leaveActive) {
  opacity: 0.01;
  height: 1px !important;
  transition: all 300ms ease-out;
  transform: translate3d(0, 0, 0);
  overflow: hidden !important;
}

/*******************************************************************************
** React Virtualized
*******************************************************************************/

:global(.ReactVirtualized__Table__headerRow) {
  font-weight: 600;
  text-transform: initial;
  font-size: 1.75rem;
}

:global(.ReactVirtualized__Masonry) {
  padding: 0;

  &:focus {
    outline: none;
  }
}

:global(.ReactVirtualized__Grid.ReactVirtualized__List) {
  overflow-x: hidden !important;
}

:global(.ReactVirtualized__Grid.ReactVirtualized__Table__Grid):focus,
:global(.ReactVirtualized__Grid.ReactVirtualized__List):focus {
  outline: none;
}

:global(.ReactVirtualized__Grid__innerScrollContainer) {
  overflow: initial !important;
  margin-right: -2rem;
  padding-right: 2rem;

  /* margin-bottom: 3rem; */
}

:global(.ReactVirtualized__Table__row):focus {
  outline: none;
}

:global(.browser-row) {
  border-bottom: 1px solid var(--colors-neutral-20);

  &:hover {
    :global(.rv-checkbox) {
      opacity: 1;
    }
  }
}

:global(.rv-checkbox) {
  opacity: 0;
}

/*******************************************************************************
** React Infinite Calendar
*******************************************************************************/

:global(.Cal__Day__root) {
  font-size: 14px;
  width: 40px;
}

:global(.Cal__Day__month) {
  top: 0;
  font-size: 10px;
  color: var(--colors-neutral-50) !important;
}

:global(.Cal__Day__year) {
  bottom: 0;
  font-size: 10px;
  color: var(--colors-neutral-50) !important;
}

:global(.Cal__Weekdays__day) {
  font-size: 12px;
  font-weight: 700;
  padding: 10px 0;
}

:global(.Cal__Header__dateWrapper.Cal__Header__day) {
  font-size: 14px;
  font-weight: 700;
  width: 175px;
  height: 40px;
  line-height: 40px;
}

:global(.Cal__Header__dateWrapper.Cal__Header__year) {
  font-size: 14px;
  font-weight: 700;
}

:global(.Cal__Day__root.Cal__Day__selected) :global(.Cal__Day__selection) {
  top: 50%;
  left: 0;
  width: 40px;
  height: 40px;
  margin-top: 0;
  margin-left: 0;
  border-radius: 8px;
  line-height: 40px;
  transform: translateY(-50%);
}

:global(.Cal__Day__root.Cal__Day__selected) :global(.Cal__Day__selection) :global(.Cal__Day__day) {
  font-size: 14px;
  top: 0;
}

:global(.Cal__Day__root.Cal__Day__selected)
  :global(.Cal__Day__selection)
  :global(.Cal__Day__month) {
  display: none;
}

:global(.Cal__Header__root) {
  padding: 0 10px;
  background-color: var(--colors-blue-90) !important;
  min-height: 40px;
  height: 40px;
}

:global(.Cal__Weekdays__root) {
  background-color: var(--colors-blue-90) !important;
}

:global(.Cal__Day__selection) {
  background-color: var(--colors-blue-90) !important;
}

:global(.Cal__Header__wrapper) {
  flex-direction: row;
}

:global(.Cal__Header__dateWrapper.Cal__Header__year) {
  font-size: 14px;
  font-weight: 700;
  width: 45px;
  position: absolute;
  height: 40px;
  line-height: 40px;
  right: 10px;
  text-align: right;
}

:global(.Cal__Day__root.Cal__Day__enabled.Cal__Day__highlighted)::before,
:global(.Cal__Day__root.Cal__Day__enabled):active::before,
:global(.Cal__Day__root.Cal__Day__enabled):hover::before {
  top: 50%;
  left: 0;
  width: 40px;
  height: 40px;
  margin-top: 0;
  margin-left: 0;
  border-radius: 8px;
  line-height: 40px;
  transform: translateY(-50%);
}

:global(.Cal__Day__root.Cal__Day__today)::before {
  top: 50%;
  left: 0;
  width: 40px;
  height: 40px;
  margin-top: 0;
  margin-left: 0;
  border-radius: 8px;
  line-height: 40px;
  transform: translateY(-50%);
  box-shadow: inset 0 0 0 1px;
}

:global(.Cal__Day__root.Cal__Day__today.Cal__Day__enabled) {
  color: var(--colors-blue-90) !important;
}

:global(.Cal__Month__label) {
  font-size: 20px;
  font-weight: bold;
  background-color: rgba(255, 255, 0, 0.6);
}

:global(.Cal__Header__wrapper.Cal__Header__blank) {
  height: 40px;
  line-height: 40px;
  font-size: 14px;
}

:global(.Cal__Today__root) {
  height: 25px;
  font-size: 11px;
  padding: 0;
  padding-right: 15px;

  :global(.Cal__Today__chevron) {
    width: 10px;

    path {
      fill: white !important;
    }
  }
}

:global(.Cal__Years__root) {
  height: 231px !important;
}

:global(.Cal__Years__year) {
  padding: 0;

  &:global(.Cal__Years__withMonths) {
    label {
      padding-left: 0;
      padding-top: 18px;
    }
  }

  ol {
    font-size: 12px;

    li {
      width: 32px;
      height: 32px;
    }
  }
}

:global(.Cal__Years__list) {
  height: 231px !important;
}

:global(.Cal__Month__label.Cal__Month__partialFirstRow) {
  top: 40px;
}

:global(.Cal__Month__label) {
  background-color: rgba(255, 255, 255, 0.9);
}

:global(.Cal__Header__range) {
  :global(.Cal__Header__dateWrapper.Cal__Header__day) {
    width: 135px;
  }

  :global(.Cal__Header__wrapper:first-child) {
    :global(.Cal__Header__year) {
      left: -3px;
      top: 14px;
    }

    &::before {
      content: none;
    }

    &::after {
      content: none;
    }
  }

  :global(.Cal__Header__wrapper:last-child) {
    margin-left: 0;

    :global(.Cal__Header__year) {
      right: 12px;
      top: 14px;
    }

    :global(.Cal__Header__day) {
      text-align: right;
    }
  }
}

:global(.Cal__Day__range.Cal__Day__selected.Cal__Day__start::after),
:global(.Cal__Day__range.Cal__Day__selected.Cal__Day__end::after) {
  content: none;
}

:global(.Cal__Day__range.Cal__Day__selected.Cal__Day__end .Cal__Day__selection .Cal__Day__day) {
  top: -1px;
  color: white;
}

:global(.Cal__Day__range) {
  :global(.Cal__Day__selection) {
    border-color: var(--colors-blue-90) !important;
    height: 38px !important;
  }
}

:global(.Cal__Day__range.Cal__Day__selected.Cal__Day__start .Cal__Day__selection) {
  border-radius: 8px 0 0 8px;
}

:global(.Cal__Day__range.Cal__Day__selected.Cal__Day__end .Cal__Day__selection) {
  border-radius: 0 8px 8px 0;
  background: var(--colors-blue-90) !important;
  border-width: 0 !important;
}

:global(.Cal__Day__betweenRange) {
  :global(.Cal__Day__selection) {
    background-color: var(--colors-blue-20) !important;
    color: var(--colors-neutral-80) !important;

    :global(.Cal__Day__day) {
      font-weight: 400 !important;
    }
  }
}

:global(.Cal__Day__range.Cal__Day__selected.Cal__Day__start::after) {
  content: none;
}

:global(.Cal__Years__currentMonth) {
  border-color: var(--colors-blue-90) !important;
  border-radius: 8px !important;
}

:global(.Cal__Years__selected) {
  border-radius: 8px !important;
}

:global(.Cal__Years__year) ol li:hover {
  border-radius: 8px !important;
}

:global(.magic-shadows) {
  position: relative;
  z-index: 1;
  overflow: auto;
  background: var(--colors-white) no-repeat;
  background-image: radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0)),
    radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
  background-position: 0 0, 0 100%;
  background-size: 100% 7px;

  &::after,
  &::before {
    content: '';
    position: relative;
    z-index: -1;
    display: block;
    height: 30px;
    margin: 0 0 -30px;
    background: linear-gradient(
      to bottom,
      var(--colors-white),
      var(--colors-white) 30%,
      rgba(255, 255, 255, 0)
    );
  }

  &::after {
    margin: -30px 0 0;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      var(--colors-white) 70%,
      var(--colors-white)
    );
  }
}
:global(button) {
  font-size: 14px;
  color: inherit;
}

:global(.popover) {
  z-index: 1050;
  border-radius: 4px;
  border: 0;
  font-size: unset;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  font-family: Inter, 'Open Sans', Helvetica, Arial, sans-serif;
  :global(.popover-body) {
    padding: 0;
    color: inherit;
  }
}

/*******************************************************************************
** react-tippy
*******************************************************************************/

:global(.tippy-tooltip) {
  font-size: 1.5rem;
  font-weight: 500;
  border-radius: 2px;
  background-color: var(--colors-neutral-90);
  font-family: Inter, system-ui, sans-serif;
  line-height: 2rem;
  padding: 1rem 1rem;
  opacity: 1;
  filter: alpha(opacity=100);
}

:global(.tippy-popper[x-placement^='top'] .tippy-arrow) {
  border-top: 4px solid var(--colors-neutral-90);
  margin: 4px 9px;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

:global(.tippy-popper[x-placement^='bottom'] .tippy-arrow) {
  border-bottom: 4px solid var(--colors-neutral-90);
  margin: 4px 9px;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

:global(.tippy-popper[x-placement^='right'] .tippy-arrow) {
  border-right: 4px solid var(--colors-neutral-90);
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  margin: 6px 4px;
}

:global(.tippy-popper[x-placement^='left'] .tippy-arrow) {
  border-left: 4px solid var(--colors-neutral-90);
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  margin: 6px 4px;
}

:global(.tippy-tooltip [x-circle]) {
  background-color: var(--colors-neutral-90);
  font-family: Inter, system-ui, sans-serif;
}

:global(.tippy-tooltip.link-theme) {
  background-color: color(var(--colors-neutral-100) tint(97%)) !important;
  box-shadow: 0 1px 1px 0 color(var(--colors-neutral-100) a(20%)),
    inset 0 0 0 1px color(var(--colors-neutral-100) tint(90%));
  padding: 0.5rem 1.5rem;

  :global(.tippy-arrow) {
    border: 0;
  }
}
