.root {
  padding: 0 1rem;

  .detailsContainer {
    display: flex;
    flex-direction: column;
    padding-top: 5rem;
  }

  .keywords {
    padding: 2rem 3rem 0;
  }

  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 42rem;
  }

  .loadingText {
    padding-left: 1rem;
    color: var(--colors-neutral-100);
    font-size: 1.75rem;
  }

  .emptyAuthors {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 7rem;

    .title {
      font-size: 14px;
      font-weight: 600;
      color: var(--colors-neutral-80);
    }

    .subtitle {
      padding-top: 1rem;
      font-size: 12px;
      color: var(--colors-neutral-70);
      text-align: center;
      width: 270px;
      word-break: break-word;
    }
  }

  .empty_authors {
    color: var(--colors-neutral-40);
  }
}
