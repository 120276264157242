.dueDate {
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem 2rem;

  font-weight: 500;
  font-size: 1.25rem;
  line-height: 2rem;

  &.notDue {
    background-color: var(--colors-blue-5);
    box-shadow: inset 0px 1px 0px 0px var(--colors-blue-20),
      inset 0px -1px 0px 0px var(--colors-blue-20);
    color: var(--colors-blue-100);
  }

  &.dueSoon {
    background-color: var(--colors-yellow-5);
    box-shadow: inset 0px 1px 0px 0px var(--colors-yellow-30),
      inset 0px -1px 0px 0px var(--colors-yellow-30);
    color: var(--colors-yellow-130);
  }

  &.isDue {
    background-color: var(--colors-red-5);
    box-shadow: inset 0px 1px 0px 0px var(--colors-red-20),
      inset 0px -1px 0px 0px var(--colors-red-20);
    color: var(--colors-red-100);
  }
}
