.fields {
  display: flex;

  &:last-of-type {
    margin-bottom: 4rem;
  }

  .inputs {
    display: flex;
  }
}
