.root {
  display: flex;
  flex-direction: column;

  background-color: var(--colors-neutral-2);

  .list {
    display: flex;
    flex-direction: column-reverse;

    max-height: 43rem;
    overflow: auto;

    border-top: 1px solid var(--colors-neutral-20);

    & > *:not(:last-child) {
      border-top: 1px solid var(--colors-neutral-20);
    }
  }

  .newReply {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    padding: 2rem;

    border-top: 1px solid var(--colors-neutral-20);

    .body {
      display: flex;
      gap: 1rem;
    }

    .footer {
      display: flex;
      justify-content: flex-end;
      gap: 1rem;
    }
  }
}
