.toggle {
  height: 4rem;
  width: 4rem;
  padding: 0.5rem;

  border-radius: 0.75rem;
  background-color: var(--colors-neutral-10);

  &.selected {
    background-color: var(--colors-blue-90);
  }
}

.card {
  z-index: 2;
}
