.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 7rem;
}

.content {
  margin-top: 3rem;
  overflow-y: auto;
}
