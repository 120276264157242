.root {
  display: flex;
  flex-direction: column-reverse;
  gap: 1.25rem;

  position: fixed;
  right: 6rem;
  bottom: 6rem;
  z-index: 1;

  :global(.toast-enter) {
    opacity: 0;
    transform: translate(calc(100% + 6rem), -5rem);
  }

  :global(.toast-enter-active) {
    opacity: 1;
    transform: translate(0, 0);
    transition: opacity 200ms ease-out, transform 200ms ease-out;
  }

  :global(.toast-exit) {
    max-height: 20rem;
  }

  :global(.toast-exit-active) {
    overflow: hidden;
    opacity: 0;
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;

    transition: opacity 200ms ease-in, max-height 200ms ease-in, padding-top 200ms ease-in,
      padding-bottom 200ms ease-in;
  }

  :global(.toast-exit-done) {
    display: none;
  }
}
