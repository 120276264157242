.pageContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.routerContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
}

.windowContainer {
  height: 100%;
}
