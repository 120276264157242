.root {
  display: flex;
  flex-direction: column;

  .removed {
    font-weight: 400;
    font-style: italic;
    color: var(--colors-neutral-40);
  }

  .email {
    font-size: 1.25rem;
    line-height: 2rem;

    color: var(--colors-neutral-60);
  }
}
