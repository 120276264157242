.box {
  width: 2rem;
  height: 2rem;
  border: 1px solid var(--colors-neutral-20);
  margin: 1rem 0rem 1rem 0.5rem;

  &.transparent {
    border-style: dashed;
  }
}
