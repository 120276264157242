.root {
  position: relative;

  .currentVersionCard {
    height: 10rem;
  }

  .card {
    height: 18rem;
  }

  .indicator {
    position: absolute;
    top: 1rem;
    left: -4.05rem;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    background-color: var(--colors-blue-90);
    box-shadow: 0 0 0 1px var(--colors-blue-105);

    &.active {
      background-color: var(--colors-white);
    }
  }
}

.root > div > div > div:first-child {
  height: 6.5rem;
}

.root > div > div ~ div {
  padding-top: 0.5rem;
}
