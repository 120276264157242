hyperlink-element {
  display: inline;
  outline: none;
  color: var(--colors-blue-100);

  &:hover {
    background-color: var(--colors-blue-20);
    border-radius: 2px;
  }

  &[data-href] {
    text-decoration: underline;

    &[selected='true'] {
      background-color: var(--colors-blue-20);
      border-radius: 2px;
    }
  }

  

/** 
 * Avoid tab-element and field-element to handle tooltip behavior when is a child of hyperlink-element
 * This will propagate the hover event to hyperlink-element which will show the tooltip
 * Issue: https://envisionpharma.atlassian.net/browse/DDC-12403
 */
  tab-element, field-element {
    pointer-events: none;
  }
}
