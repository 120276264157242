.details {
  display: flex;

  &:not(:first-child) {
    padding: 1rem 0 0 0;
  }

  .labelContainer {
    display: flex;
    min-width: 25rem;
    padding-left: 2rem;
    flex-direction: row;
    justify-content: flex-start;

    .label {
      color: var(--colors-neutral-70);
      font-size: 1.75rem;
      line-height: 3rem;

      &.isTitle {
        color: var(--colors-neutral-100);
        font-weight: 500;
      }
    }
  }

  .valueContainer {
    display: block;
    flex: 1;
    font-size: 1.75rem;
    color: var(--colors-neutral-100);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 3rem;

    .noValue {
      color: var(--colors-neutral-40);
    }
  }
}
