.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .message {
    margin-top: 1rem;
    margin-bottom: 2rem;
    line-height: 3rem;
  }

  .bar {
    margin-bottom: 4rem;
  }
}
