.cell {
  display: table;
  table-layout: fixed;
  width: 100%;

  //Hack to dont cut off box-shadow of elements
  &.shadowSpacing {
    margin-left: -3px;
    height: 100%;
  }

  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: table-cell;

    //Hack to dont cut off box-shadow of elements
    &.shadowSpacing {
      padding-left: 3px;
      vertical-align: middle;
    }
  }

  .name {
    padding-left: 2rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.disabled {
    opacity: 0.5;
  }
}

.cellNoWidth {
  display: table;
  table-layout: fixed;

  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: table-cell;
    max-width: 260px;
  }

  .name {
    padding-left: 2rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.disabled {
    opacity: 0.5;
  }
}

.objectName {
  display: flex;
  align-items: center;
  flex-wrap: 'nowrap';

  .nodeIconWrapper {
    width: 4rem;
    height: 4rem;
  }
}
