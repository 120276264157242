.lineContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 2rem;
  font-size: 12px;

  .typePreview {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 0.5rem;
    max-width: 4rem;
  }

  .linePreview {
    height: 1rem;
    background-color: var(--colors-neutral-10);
  }
}
