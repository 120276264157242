.root {
  height: calc(100 * var(--vh));
  width: 100%;
  background: var(--colors-blue-110);
  padding-top: 6rem;
  color: var(--colors-white);
  text-align: center;

  .title {
    margin: 2rem 10rem 6rem;
    font-size: 2.25rem;
    font-weight: 600;
    @media (min-width: 600px) {
      margin: 4rem 23rem 7rem;
    }
  }

  .text {
    text-align: left;
    margin: 0 5rem;
    font-size: 1.5rem;
    line-height: 2.25rem;
    @media (min-width: 600px) {
      font-size: 1.75rem;
      margin: 0 23rem;
    }
  }
}
