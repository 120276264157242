.subtitle {
  font-size: 1.5rem;
  line-height: 2.5rem;
  color: var(--colors-neutral-60);
  overflow: hidden;
  text-overflow: ellipsis;

  .unknown {
    color: var(--colors-neutral-30);
    font-style: italic;
    font-weight: 400;
  }
}
