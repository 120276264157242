.root {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 8rem;
  box-shadow: inset 0px 0px 0px 7px var(--colors-white);
  border: 2px solid var(--colors-blue-50);
  background-color: var(--colors-blue-10);

  .nameContainer {
    padding-top: 1rem;
    display: flex;
    flex-direction: row;

    b {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 400px;
    }

    .greyText {
      margin-left: 0.5rem;
      color: var(--colors-neutral-50);
    }
  }

  .progress {
    width: 20rem;
    height: 2.5rem;
    background: var(--colors-white);
    border-radius: 100px;
    border: 1px solid rgba(0, 27, 68, 0.1);
    overflow: hidden;
    display: flex;
    align-items: center;

    .bar {
      width: 0;
      transition: width 0.5s ease-in-out;
      background: rgba(19, 95, 187, 0.8);
      box-shadow: inset 0px 0px 0px 2px var(--colors-white);
      height: 100%;
      border-radius: 100px;
    }
  }
}

.root1 {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  border-radius: 8px;

  box-shadow: inset 0px 0px 0px 7px var(--colors-white);
  border: 2px solid var(--colors-white);
  background-color: var(--colors-white);

  .nameContainer {
    padding-top: 1rem;
    display: flex;
    flex-direction: row;

    b {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 400px;
      font-weight: 600;
      font-size: 14px;
      color: var(--colors-neutral-80);
    }

    .greyText {
      margin-left: 0.5rem;
      color: var(--colors-neutral-50);
    }
  }

  .progress {
    width: 30rem;
    height: 1.5rem;
    background: var(--colors-blue-50);
    border-radius: 100px;
    border: 1px solid rgba(0, 27, 68, 0.1);
    overflow: hidden;
    display: flex;
    align-items: center;

    .bar {
      width: 0;
      transition: width 0.5s ease-in-out;
      background: rgba(19, 95, 187, 0.8);
      box-shadow: inset 0px 0px 0px 2px var(--colors-white);
      height: 100%;
      border-radius: 100px;
    }
  }
}
