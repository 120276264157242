image-element {
  margin: 0 0;
  padding: 0;
  display: inline-flex;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }
}

.image[placeholder='true'] {
  background-image: url(./imageplaceholder.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-color: var(--colors-neutral-20);
}

.container {
  background-color: var(--colors-yellow-20);
  border: 1px solid var(--colors-yellow-60);
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8rem 2rem;
  margin: 0 auto;
  width: 44rem;

  .description {
    margin-top: 2rem;
    font-family: Inter;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 2rem;
    color: var(--colors-neutral-80);
    text-align: center;
  }
}
