.root {
  display: flex;
  flex-direction: column;
  margin-left: 6rem;
  margin-bottom: 2rem;

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 6rem;
    background: var(--colors-blue-10);
    border-radius: 4px;
    padding: 0 2rem;

    .parentContainer {
      display: flex;
      margin-right: auto;
      align-items: center;

      .title {
        white-space: nowrap;
        font-weight: bold;
      }
    }

    .filtersHistory {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    .buttonsContainer {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      margin-left: 3rem;
    }
  }

  .conditions {
    display: flex;
    flex-direction: column;

    .filterRow {
      margin: 1rem 2rem;
    }
  }

  .footer {
    display: flex;
    flex-direction: row;
    margin-top: 1rem;

    .searchModeToggle {
      margin-right: auto;
    }

    .searchResults {
      display: flex;
      align-items: center;

      font-size: 1.75rem;
      font-weight: 600;
      line-height: 3rem;
      color: var(--colors-neutral-50);

      .filterSetName {
        margin-right: 1rem;
        color: var(--colors-neutral-100);
      }
    }
  }

  .queryRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    padding: 1rem;

    .queryInput {
      flex: 1;
      margin-right: 1rem;
    }

    .queryActions {
      display: flex;
      flex-direction: row;
      gap: 2rem;
    }
  }
}
