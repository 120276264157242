.setting {
  display: flex;
  align-items: center;
  padding: 1.5rem 0;
  align-items: center;
  min-height: 7rem;

  .labelContainer {
    display: flex;
    min-width: 36rem;
    padding-left: 4rem;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .label {
      color: var(--colors-neutral-100);
      font-size: 1.75rem;
      line-height: 3rem;
    }
  }

  .value {
    width: 44rem;
    font-size: 1.75rem;
    line-height: 3rem;
    color: var(--colors-neutral-60);
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;

    padding-right: 1rem;
  }

  .noValue {
    color: var(--colors-neutral-30);
    font-size: 1.75rem;
    line-height: 3rem;
    width: 44rem;
    flex: 1;
  }

  .underlinedText {
    cursor: pointer;
    color: var(--colors-blue-90);
    text-decoration: underline;
    text-decoration-color: var(--colors-blue-90);
  }

  .secondValue {
    width: 21rem;
    font-size: 1.75rem;
    color: var(--colors-neutral-50);
    margin-left: 18px;
  }
}
