.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .content {
    width: 40rem;

    .label {
      font-size: 1.75rem;
      line-height: 3rem;
      color: var(--colors-neutral-100);

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .buttons {
      display: flex;
      justify-content: flex-end;
      gap: 1rem;
    }

    & + button {
      min-width: 4rem;
    }
  }
}
