.toolbar {
  position: relative;
  grid-area: toolbar;
  box-shadow: inset 0 -1px 0 0 var(--colors-neutral-20);
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr auto 1fr 1fr;
  grid-template-areas: 'left middle-left middle middle-right right';
  background-color: var(--colors-neutral-2);
  width: 100%;

  > * {
    display: flex;
    align-items: center;
  }

  .left {
    grid-area: left;
  }
  .middleleft {
    grid-area: middle-left;
    justify-self: end;
  }
  .middle {
    grid-area: middle;
  }
  .middleright {
    grid-area: middle-right;
  }
  .right {
    grid-area: right;
    justify-self: end;
  }
}

.totalPages {
  white-space: nowrap;
  margin-right: 1rem;
}
