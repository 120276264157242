.row {
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  align-items: center;
  height: 100%;

  &.selectable {
    padding-left: 6rem;
  }

  &.processing {
    opacity: 0.5;
  }

  &.overdue {
    background: var(--colors-red-10);

    &.selected {
      background: var(--colors-red-20);

      &:hover {
        background: var(--colors-red-30);
      }
    }

    &:hover {
      background: var(--colors-red-20);
    }
  }

  &.selected {
    background: var(--colors-blue-10);

    .iconWrapper {
      display: block !important;
    }

    &:hover {
      background: var(--colors-blue-30);
    }
  }

  &.deactivated {
    color: var(--colors-neutral-30) !important;

    &:hover {
      background-color: var(--colors-blue-5);
    }
  }

  &:hover {
    background: var(--colors-blue-20);

    .iconWrapper {
      display: block !important;
    }
  }

  .item {
    display: flex;
    padding-left: 1.5rem;
    align-items: center;
    height: 100%;
    border-bottom: 1px solid var(--colors-neutral-20);

    &.left {
      justify-content: flex-start;
    }
    &.center {
      justify-content: center;
    }
    &.right {
      justify-content: flex-end;
    }

    &.checkBox {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      padding: 0;
      justify-content: center;
      width: 6rem;
      height: 6rem;
      background: var(--colors-white);
      border-bottom: 0;

      .iconWrapper {
        display: none;
        height: 4rem;
        width: 4rem;
        cursor: pointer;
      }
    }

    &.flex {
      flex: 1;
      min-width: 40rem;
    }

    .ellipsis {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .name {
      padding-left: 2rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .anchor {
      color: var(--colors-blue-60);
    }

    &.disabled {
      opacity: 0.5;
    }
  }

  .nodeIconWrapper {
    width: 4rem;
    height: 4rem;
  }
}

.dropdownMenu {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 100%;
  padding: 1rem 0;
  background: var(--colors-white);
  border: 1px solid var(--colors-neutral-10);
  box-shadow: 0 0.5rem 6px 0 rgba(var(--colors-neutral-100), 0.3);
  border-radius: 0.5rem;
  width: max-content;

  .item {
    box-shadow: 0 0 0 0;
    border: 0;
    background: var(--colors-white);
    padding: 0 4rem;
    height: 4rem;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: normal;
    color: var(--colors-neutral-100);
    width: 100%;
    cursor: pointer;

    &:hover {
      background: var(--colors-blue-10);
    }
  }
}
