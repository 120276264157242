.container {
  position: absolute;
  top: 1rem;
  right: 3rem;
  background-color: var(--colors-white);
  width: 31rem;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 6px rgba(192, 192, 201, 0.75);
  border: 1px solid var(--colors-neutral-20);
  height: 5rem;
  display: flex;
  flex-direction: row;
  padding: 1rem 0 1rem 2rem;
  align-items: center;
  justify-content: space-between;
  z-index: 1;

  .text {
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 2rem;
    color: var(--colors-neutral-80);
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .toggles {
    display: flex;
    flex-direction: row;
    padding-right: 0.5rem;
  }
}
