.root {
  position: absolute;
  top: 0px;
  height: 100%;
  overflow: visible;

  .list {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }
}
