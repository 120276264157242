.content {
  grid-area: content;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: auto;

  .slides {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 5rem;
    overflow: auto;
    background-color: var(--colors-neutral-20);

    &.pan {
      cursor: grab;

      &.panning {
        cursor: grabbing;
        user-select: none;
      }
    }

    .slideContainer {
      position: relative;
      height: fit-content;
      width: fit-content;
      margin: 0 auto;
    }
  }

  .creating {
    box-shadow: 0 0 0 2px #2e92e6, 0px 6px 6px rgba(0, 0, 0, 0.25);
  }
}
