.root {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  padding: 2rem;

  border-top: 1px solid var(--colors-neutral-20);

  .body {
    display: flex;
    gap: 1rem;
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }
}
