.root {
  display: flex;
  flex-direction: column;

  width: 0rem;
  height: 100%;

  background-color: #1980d2;
  box-shadow: inset 1px 0px 0px var(--colors-blue-120);

  //Animations classes
  &.enter {
    opacity: 0;
    width: 0rem;
    min-width: 0rem;
    padding: 0;
  }
  &.enterActive {
    opacity: 1;
    width: 25rem;
    min-width: 25rem;
    padding: 4rem 1rem;
    transition: opacity 150ms ease-out, width 150ms ease-out;
  }
  &.enterDone {
    opacity: 1;
    width: 25rem;
    min-width: 25rem;
    padding: 4rem 1rem;
  }

  &.exit {
    opacity: 1;
    width: 25rem;
    min-width: 25rem;
    padding: 4rem 1rem;
  }
  &.exitActive {
    opacity: 0;
    width: 0rem;
    min-width: 0rem;
    padding: 0;
    transition: opacity 150ms ease-out, width 150ms ease-out;
  }
  &.exitDone {
    opacity: 0;
    width: 0rem;
    min-width: 0rem;
    padding: 0;
  }
}
