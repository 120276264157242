.menuContainer {
  display: flex;
  flex-direction: column;
  padding: 1rem 0;

  width: fit-content;

  .optionsContainer {
    max-height: 400px;
    overflow: auto;
  }

  .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    height: 5rem;
    padding: 0 1rem 0 4rem;
    font-size: 1.75rem;
    line-height: 3rem;
    color: var(--colors-neutral-100);
    cursor: pointer;

    .label {
      width: 20.75rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &:not(.header) {
      &:hover {
        background: var(--colors-blue-10);
      }
    }
  }

  .header {
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 2rem;
    color: var(--colors-neutral-40);
    text-transform: uppercase;
    letter-spacing: 0.7px;
    height: 4rem;
  }
}
