.group {
  margin-bottom: 1rem;

  p {
    font-weight: bold;
  }

  .items {
    display: flex;
    flex-direction: column;
  }
}
