.modalRoot {
  display: flex;
  flex-direction: column;
}

.warningDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 5.75rem;
}

.warningDivEdit {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 4.5rem;
}

.warningLabel {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2rem;
  color: var(--colors-neutral-100);
  padding-left: 1rem;
}
