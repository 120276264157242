.borderRoot {
  position: absolute;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border: 2px solid var(--colors-blue-95);
  opacity: 0.7;
}

.highlighRoot {
  position: absolute;
  background: var(--colors-user-main-100);
  box-shadow: 0px 4px 16px 0px rgba(46, 46, 76, 0.2);
  opacity: 0.15;
}

.toggle {
  position: absolute;
  cursor: pointer;
}
