.row {
  .container {
    display: flex;
    flex-direction: row;
    padding: 0 0 0 4rem;

    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 35rem;
      .title {
        font-size: 1.75rem;
        font-weight: 600;
        line-height: 3rem;
        color: var(--colors-neutral-100);
        padding-bottom: 0.5rem;
      }

      .description {
        font-size: 1.5rem;
        font-weight: 400;
        line-height: 2rem;
        color: var(--colors-neutral-70);
      }
    }
  }

  .children {
    display: flex;
    align-items: center;
    margin-left: 6rem;
  }
}
