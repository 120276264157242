.root {
  flex: 1;
  display: flex;
  flex-direction: column;

  .loading {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 7rem;
  }
}

.content {
  flex: 1;
}
