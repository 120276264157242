.root {
  display: flex;
  flex-direction: column;

  .descriptionWrapper {
    margin: 2rem;

    .actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 1rem;
    }

    .descriptionLabel {
      font-size: 1.5rem;
      line-height: 2rem;

      align-self: center;

      &.noPermissions {
        color: var(--colors-neutral-40);
      }
    }

    .editableDescription {
      display: flex;
      flex-direction: row;
      gap: 3rem;
      justify-content: space-between;
    }
  }
}
