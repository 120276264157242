.root {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dropdownContainer {
  position: relative;

  .dropdownTrigger {
    border: 1px solid black;
  }

  .dropdownList {
    position: absolute;
    margin-top: 1rem;
    border-radius: 0.5rem;
    border: 1px solid black;
    display: none;

    &.open {
      display: flex;
    }

    .dropdownItem {
      border: 1px solid black;
    }
  }
}
