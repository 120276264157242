$screen-tiny-min: 768px;
$screen-smaller-min: 992px;
$screen-small-min: 1200px;

// Small devices
@mixin tiny {
  @media (min-width: #{$screen-tiny-min}) {
    @content;
  }
}

// Medium devices
@mixin smaller {
  @media (min-width: #{$screen-smaller-min}) {
    @content;
  }
}

// Large devices
@mixin small {
  @media (min-width: #{$screen-small-min}) {
    @content;
  }
}
