.root {
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;

  &.overlay {
    z-index: 1052;
  }
}
