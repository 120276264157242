.root {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-color: var(--colors-blue-5);
}

.content {
  text-align: center;
  min-height: 390px;
  margin: 6rem auto 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
