@import 'assets/styles/config.scss';

.root {
  background: var(--colors-blue-100);
  padding: 5rem;
  min-width: 61rem;
  width: 38.125%;
  display: flex;
  flex-direction: column;
  .text {
    margin: 11rem 0 auto;
    font-weight: 600;
    color: var(--colors-white);
    font-size: 6rem;
    line-height: 9rem;
  }
  .customMessage {
    font-weight: 600;
    font-size: 2rem;
    line-height: 3rem;
    color: var(--colors-blue-10);
    margin-bottom: 5rem;
  }
}

@include small {
  .root {
    .text {
      font-size: 4vw;
      line-height: 5.4vw;
    }
  }
}
