.panelContent {
  &:not(.editor) {
    padding: 0;
  }

  &.editor {
    display: flex;
    flex-direction: column;
    padding: 3rem 2rem;
    min-height: 0;
    flex: 1;
  }
}
