note-element {
  vertical-align: super;
  font-size: smaller;
  color: inherit;
  cursor: pointer;

  + note-element {
    margin-left: 0.5rem;
  }

  + track-ins-element > note-element:first-child {
    margin-left: 0.5rem;
  }

  + track-del-element > note-element:first-child {
    margin-left: 0.5rem;
  }
}
