.block {
  font-size: 1.75rem;
  line-height: 3rem;
  + .block {
    margin-top: 3rem;
  }
}

.bold {
  font-weight: 500;
}
