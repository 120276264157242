.footer {
  position: relative;
  grid-area: footer;
  padding: 1rem;
  background-color: var(--colors-neutral-2);
  width: 100%;
  display: flex;
  box-shadow: inset 0 1px 0 0 var(--colors-neutral-20);

  &.editor {
    padding: 0 1rem;
  }

  .content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'left right';
    width: 100%;

    > * {
      display: flex;
      align-items: center;
    }

    .left {
      grid-area: left;
    }
    .right {
      grid-area: right;
      justify-self: end;
    }
  }
}
