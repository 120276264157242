.root {
  position: relative;
  width: 100%;
  margin: 0 auto;
  background: var(--colors-blue-90);

  .profileToggle {
    display: flex;
  }
}

.menu {
  margin-bottom: 0;
}
