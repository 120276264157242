.waitingValidation {
  height: 53rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  height: 53rem;
  display: flex;
}
