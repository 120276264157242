.url {
  margin-top: 1rem;
  margin-bottom: 1rem;
  max-height: 18rem;
  line-height: 3rem;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 6; /*max-height / line-height*/
  -webkit-box-orient: vertical;

  color: var(--colors-blue-100);
}
