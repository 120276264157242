.menu {
  grid-area: menu;
  box-shadow: inset 0 -1px 0 0 var(--colors-neutral-20);
  padding: 1rem;
  display: flex;
  position: relative;

  .leftSide {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50%;
  }

  .rightSide {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50%;
  }

  .section {
    display: flex;
    height: 100%;
    align-items: center;
    position: relative;
  }

  .totalPages {
    white-space: nowrap;
    margin-right: 1rem;
  }
}
