.options {
  cursor: pointer;
  display: flex;
  padding: 1rem 0.5rem;
  border-radius: 0.5rem;

  .arrowIcon {
    margin-right: 1rem;
  }

  .optionsLabels {
    color: var(--colors-neutral-100);

    &.loadingLabel {
      color: var(--colors-neutral-20);
    }
  }

  &.selected {
    background-color: var(--colors-blue-10);
  }

  &.loadingMenu {
    cursor: auto;
  }
}
