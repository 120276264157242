.content {
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
  overflow: auto;

  .list {
    padding: 1rem 1rem;
    > :not(:last-child) {
      margin-bottom: 2rem;
    }

    .tags {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      row-gap: 0.5rem;
      margin-top: 1rem;

      > :not(:last-child) {
        margin-right: 0.5rem;
      }
    }
  }
}
