.grid_item {
  cursor: pointer;
  border-radius: 0.5rem;
  background-color: var(--colors-neutral-5);
  box-shadow: inset 0px 0px 0px 1px var(--colors-neutral-20);
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), inset 0px 0px 0px 1px var(--colors-neutral-20);
  }
}
