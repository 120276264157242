.root {
  background-color: var(--colors-blue-5);
  height: 100%;
  padding: 4rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  .message {
    font-size: 3rem;
    line-height: 4rem;
    font-weight: 600;
  }

  .footer {
    margin-top: auto;
    * {
      color: var(--colors-neutral-30);
    }
  }
}
