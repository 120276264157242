.root {
  height: 100%;
  width: 100%;

  .setting {
    display: flex;
    align-items: flex-start;
    padding: 2rem 0 1.865rem 0;

    .dates {
      display: flex;
      flex-direction: column;
      padding: 0.5rem 0 1rem 0;
    }

    .label {
      color: var(--colors-neutral-100);
      font-size: 14px;
      min-width: 36rem;
      padding-left: 4rem;
    }

    .value {
      display: flex;
      justify-content: space-between;
      width: 30rem;
      font-size: 14px;
      color: var(--colors-neutral-50);
    }
  }
}
