.container {
  background-color: var(--colors-neutral-2);
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr auto 6rem;
  grid-template-rows: 8rem 5rem 5rem 1fr 5rem;
  gap: 0px 0px;
  grid-template-areas:
    'title title title title'
    'menu menu menu menu'
    'toolbar toolbar toolbar toolbar'
    'content content rightpanel sidebar'
    'footer footer footer footer';

  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Likely future */
  overflow: hidden;
}
