.header {
  display: flex;
  justify-content: space-between;
}

.noTitle {
  font-size: 12px;
  font-weight: 500;
  color: var(--colors-neutral-40);
}

.information {
  font-size: 12px;
  line-height: 2rem;
  color: var(--colors-neutral-100);

  .imported {
    margin: 1rem 0;
    color: var(--colors-neutral-40);
  }

  .emptyData {
    font-size: 12px;
    color: var(--colors-neutral-40);
  }
}

.title {
  font-size: 12px;
  font-weight: 500;
  color: var(--colors-neutral-100);
  height: 4rem;
  line-height: 2rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  .id {
    font-size: 12px;
    font-weight: 600;
    color: var(--colors-blue-100);
    margin-right: 1rem;
  }
}

.citation {
  margin-bottom: 1rem;
  background-color: var(--colors-white);
  border: 1px solid var(--colors-neutral-20);
  border-radius: 0.5rem;

  .buttons {
    border-top: 1px solid var(--colors-neutral-20);
    padding: 1rem;
    display: flex;
  }
}
