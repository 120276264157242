.search {
  padding-top: 0.5rem;
  margin-bottom: 3rem;
}

.container {
  height: 28.1rem;

  .list {
    height: 100%;
    overflow: auto;
    .row {
      .item {
        padding: 1rem 2rem 0.5rem 2rem;
      }
    }
  }
}
