.unapproving {
  margin-top: 4rem;
  font-weight: 600;
  color: var(--colors-neutral-90);

  .block {
    margin: 1rem 0 0 2rem;
    color: var(--colors-neutral-80);
    font-weight: 400;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }

  .noPermissions {
    background-color: var(--colors-neutral-5);
    border-radius: 3px;
    color: var(--colors-neutral-40);
    font-weight: 400;
    width: 250px;
    margin: 1rem 0 0 2rem;
    padding: 0 1rem;
    height: 3rem;
    display: flex;
    align-items: center;
  }
}
