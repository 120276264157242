.root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .top {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .middle {
    display: flex;
    width: 100%;
    height: 100%;
  }

  .bottom {
    display: flex;
    flex-direction: column;
  }
}
