.title {
  padding: 1rem 0;
}

.searchPreview {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  margin-top: 4rem;
}
