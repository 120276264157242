.root {
  display: flex;
  flex-direction: column;

  .approvalNoteWrapper {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    margin: 2rem;

    .header {
      font-size: 1.5rem;
      line-height: 2rem;

      color: var(--colors-neutral-100);
    }
    .body {
      font-size: 1.5rem;
      line-height: 2rem;
      color: var(--colors-neutral-80);

      background-color: var(--colors-green-5);
      border: 1px solid var(--colors-green-10);
      border-radius: 4px;

      padding: 1rem;
    }
    .footer {
      font-size: 1.25rem;
      line-height: 2rem;
      color: var(--colors-neutral-50);
    }
  }
}
