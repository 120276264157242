.backgroundContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(46, 46, 76, 0.5);
  z-index: 1051;

  .mainContainer {
    position: relative;
    width: 50rem;
    top: 20rem;
    margin-left: auto;
    margin-right: auto;
    background: var(--colors-neutral-100);
    border: 1px solid var(--colors-neutral-105);
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem 5rem;

    .textContainer {
      font-size: 1.75rem;
      line-height: 3rem;
      font-weight: 500;
      padding-top: 2.5rem;
      color: var(--colors-white);
      text-align: center;
    }
  }
}
