.labels {
  flex: 1;
  flex-direction: column;
  justify-content: center;
  display: flex;

  .author {
    display: flex;
    align-items: center;
    font-weight: 500;
    height: 2rem;
    font-size: 12px;
    color: var(--colors-neutral-100);
  }

  .order {
    color: var(--colors-blue-90);
    font-size: 12px;
    line-height: 2rem;
  }
}

.readMore {
  cursor: pointer;
  font-size: 12px;
  color: var(--colors-blue-70);
  margin-top: 1rem;
}

.text {
  color: var(--colors-neutral-100);
  font-size: 1.5rem;
  word-break: break-word;
  white-space: pre-line;

  &.readLess {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
