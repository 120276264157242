.instances {
  margin-top: 3rem;

  .empty {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2rem;
    color: var(--colors-neutral-40);
  }

  .counter {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2rem;
    color: var(--colors-neutral-100);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .list {
    margin-top: 1.5rem;
    height: 18rem;
    max-height: 18rem;
    overflow: auto;
  }
}
