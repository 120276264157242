.loading {
  display: flex;
  flex-direction: column;
  align-items: center;

  .icon {
    margin-bottom: 4rem;
  }

  span {
    margin-top: 3rem;
  }
}
