.panel {
  grid-area: rightpanel;
  display: flex;
  flex-direction: column;
  min-height: 0;
  width: 49rem;
  box-shadow: inset 1px 0 0 0 var(--colors-neutral-20);

  .content {
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 0;
  }
}

.content {
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
}
