.root {
  flex: 1;
  display: flex;
  flex-direction: column;

  .inputContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-bottom: 1rem;
  }
}

.span {
  font-size: 12px;
  color: var(--colors-neutral-40);
}
