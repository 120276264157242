.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 2rem;

  padding: 1rem;
  min-height: 5rem;
  width: 100%;

  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: inset 0 0 0 1px var(--colors-neutral-10);
  border-radius: 3px;

  .content {
    font-size: 1.75rem;
    font-weight: 400;
    color: var(--colors-neutral-100);
    line-height: 3rem;

    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
