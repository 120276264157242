.base {
  position: absolute;

  .base {
    cursor: pointer;
  }

  .strikethrough {
    position: absolute;
    top: 50%;
    left: 0;
    border-bottom-style: solid;
    width: 100%;
  }

  &.selected {
    box-shadow: 0 0 0 1px var(--colors-blue-95), 0px 4px 4px rgba(0, 0, 0, 0.25);
    &.canMove {
      z-index: 6;
    }
  }

  .svg {
    overflow: visible;
    fill: none;
    pointer-events: visible;
    cursor: default;

    > * {
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.toggle {
  position: absolute;
  left: calc(100% + 1rem);
  height: 4rem;
  width: 4rem;
  border-radius: 0.75rem;
  background-color: var(--colors-neutral-10);
  padding: 0.5rem;
  z-index: 5;
  cursor: pointer;

  &.selected {
    background-color: var(--colors-blue-90);
  }

  &.canMove {
    cursor: move;
  }
}

.card {
  z-index: 10;
  cursor: default;

  &.cardOverlap {
    z-index: 1052;
  }
}

.resize {
  top: 0;
  left: 0;
  position: absolute;

  .area {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: move;
  }

  .edge {
    cursor: move;
    background: var(--colors-blue-95);
    position: absolute;

    &.t {
      width: 100%;
      height: 1px;
      top: -1px;
      left: 0;
    }

    &.r {
      width: 1px;
      height: 100%;
      top: 0;
      left: 100%;
    }

    &.b {
      width: 100%;
      height: 1px;
      top: 100%;
      left: 0;
    }

    &.l {
      width: 1px;
      height: 100%;
      top: 0;
      left: -1px;
    }
  }

  .node {
    position: absolute;
    height: 1rem;
    width: 1rem;
    background-color: var(--colors-blue-70);
    cursor: move;

    &.nw {
      cursor: nw-resize;
      top: -4px;
      left: -4px;
    }
    &.n {
      cursor: n-resize;
      top: -4px;
      left: calc(50% - 4px);
    }
    &.ne {
      cursor: ne-resize;
      top: -4px;
      left: calc(100% - 4px);
    }
    &.e {
      cursor: e-resize;
      top: calc(50% - 4px);
      left: calc(100% - 4px);
    }
    &.se {
      cursor: se-resize;
      top: calc(100% - 4px);
      left: calc(100% - 4px);
    }
    &.s {
      cursor: s-resize;
      top: calc(100% - 4px);
      left: calc(50% - 4px);
    }
    &.sw {
      cursor: sw-resize;
      top: calc(100% - 4px);
      left: -4px;
    }
    &.w {
      cursor: w-resize;
      top: calc(50% - 4px);
      left: -4px;
    }
  }
}

.item {
  padding: 0 1rem;

  &:hover {
    background-color: var(--colors-blue-20);
    cursor: pointer;
  }

  display: flex;
  align-items: center;
  height: 4rem;
  font-size: 1.5rem;
  line-height: 2rem;
  cursor: pointer;

  .selection {
    margin-right: 0.5rem;
    visibility: hidden;
  }

  .color {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 0.75rem;
    border-style: solid;
    border-width: 1px;
    margin: 0 1.75rem 0 0.75rem;

    &.none {
      border-color: var(--colors-neutral-70);
      background-color: var(--colors-white);
      position: relative;
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 45%;
        height: 1px;
        width: 100%;
        background-color: var(--colors-red-100);
        transform: rotate(-45deg);
      }
    }
    &.black {
      border-color: var(--colors-neutral-105);
      background-color: var(--colors-neutral-90);
    }
    &.yellow {
      border-color: var(--colors-yellow-105);
      background-color: var(--colors-yellow-100);
    }
    &.green {
      border-color: var(--colors-green-105);
      background-color: var(--colors-green-95);
    }
    &.red {
      border-color: var(--colors-red-105);
      background-color: var(--colors-red-80);
    }
    &.blue {
      border-color: var(--colors-blue-105);
      background-color: var(--colors-blue-95);
    }
  }

  .text {
    margin-left: 0.5rem;
  }

  &.isSelected {
    .selection {
      visibility: visible;
    }
  }
}
