.root {
  overflow: auto;
  flex: 1;
  margin: 0 -2rem;
  padding: 0 2rem;

  .emptyView {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
  }
}

.infoTasks {
  height: 4rem;
  display: flex;
  align-items: center;
  margin-top: 1rem;

  .infoText {
    font-size: 12px;
    line-height: 2rem;
    color: var(--colors-neutral-60);
  }
}

.card {
  margin-top: 1rem;
}
