.navigation {
  grid-area: leftpanel;
  width: 33rem;
  box-shadow: inset -1px 0 0 var(--colors-neutral-20);

  .title {
    height: 8rem;
    padding: 2rem;
    font-size: 2rem;
    line-height: 4rem;
    font-weight: 600;
    color: var(--colors-neutral-95);
    background-color: var(--colors-neutral-10);
    box-shadow: inset 0 -1px 0 0 var(--colors-neutral-20), inset -1px 0 0 var(--colors-neutral-20);
    margin-bottom: 3rem;
  }
}
