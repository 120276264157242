.root {
  flex: 1;
  padding: 2rem 6rem 0 0;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  height: 8rem;

  .search {
    width: 35rem;
    margin-left: 4rem;
  }
}

.message {
  margin-left: 6rem;
  font-size: 1.5rem;
  height: 2rem;
  color: var(--colors-neutral-80);
  line-height: 2rem;
  margin-bottom: 2rem;
}

.contentWrap {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 6rem;
}
