.root {
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;

    font-weight: 600;
    font-size: 1.75rem;
    line-height: 3rem;
    color: var(--colors-neutral-100);
  }

  .versionList {
    display: flex;
    gap: 2rem;

    margin-top: 2rem;

    .container {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      font-size: 1.75rem;
      line-height: 3rem;

      &.titles {
        color: var(--colors-neutral-40);
        width: 5rem;
      }
      &.values {
        color: var(--colors-neutral-100);
      }
    }
  }
}

.copyright {
  font-size: 1.5rem;
  line-height: 2rem;

  color: var(--colors-neutral-70);
}
