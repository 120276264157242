figure-element {
  display: block;
  clear: both !important; /* TODO: Is this clear really necessary? */
  text-align: left;
  margin: 0 0 15pt 0;
  background-color: var(--suggestionBackgroundColor);
  max-width: var(--contentWidth, 100%);

  /* margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 40px;
  margin-inline-end: 40px; */

  &[data-vanish='true'] {
    img {
      border-bottom: 2px dashed var(--colors-blue-100);
    }
  }

  &[data-alignment='left'] {
    text-align: left;
  }

  &[data-alignment='center'] {
    text-align: center;
  }

  &[data-alignment='right'] {
    text-align: right;
  }

  &[data-alignment='justify'] {
    text-align: justify;
    text-justify: inter-word;

    /* Mozilla specific styles */
    @supports (-moz-appearance: none) {
      white-space: normal;
    }
  }

  &[lock] {
    box-shadow: inset var(--colors-neutral-5) var(--contentWidth) 0,
      0 0 0 1px var(--colors-neutral-20);
  }
}
