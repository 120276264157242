.breadCrumb {
  margin-left: 6rem;
  align-items: center;
  font-size: 1.75rem;
  color: var(--colors-neutral-80);
  font-weight: 600;
  display: flex;
  flex: 1;
  overflow: hidden;
  margin-right: 2rem;
}
