.controls {
  position: fixed;
  bottom: 4rem;
  left: calc(50%);
  transform: translate(-50%, 0);
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
  height: 6rem;
  background-color: var(--colors-neutral-2);
  box-shadow: inset 0 0 0 1px var(--colors-neutral-20);
  border-radius: 0.5rem;

  .pagination {
    font-weight: 500;
    font-size: 1.75rem;
    line-height: 3rem;
  }
}
