.body {
  font-size: 2rem;
  color: var(--colors-neutral-100);

  .content {
    .field {
      height: 15rem;
    }

    .admin {
      align-items: center;

      .label {
        font-size: 1.75rem;
        margin: 0 1rem 0 2rem;
      }

      &:hover {
        .label {
          color: var(--colors-neutral-40);
        }
      }
    }

    .disabled {
      color: var(--colors-neutral-40);
    }

    .align {
      display: flex;
      align-items: center;

      &.start {
        justify-content: flex-start;
      }

      &.between {
        justify-content: space-between;

        .field {
          width: 48%;
        }
      }
    }
  }
}
