.root {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 4rem;
  height: 4rem;

  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 500;
  color: var(--colors-neutral-60);

  background-color: var(--colors-neutral-20);
  border-radius: 100%;
  cursor: pointer;
}
