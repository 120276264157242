.item {
  display: flex;
  align-items: center;
  height: 6rem;
  width: 100%;
  padding: 0 2rem;
  box-shadow: inset 0px -1px 0px 0px var(--colors-neutral-20);

  .name {
    flex: 1;
    margin-left: 2rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:hover:not(.disabled) {
    background-color: var(--colors-blue-10);

    .colRight {
      visibility: visible;
    }
  }

  &.selected {
    background-color: var(--colors-blue-20);

    &:hover {
      background-color: var(--colors-blue-20);
    }
  }

  &.disabled {
    color: var(--colors-neutral-20);
    cursor: not-allowed;
  }

  .colRight {
    cursor: pointer;
    visibility: hidden;
    width: 24px;
    height: 24px;
  }
}
