.root {
  padding: 0 0 0 6rem;
  margin-top: 2rem;

  .content {
    display: flex;
    align-items: center;
    font-size: 1.75rem;
    font-weight: 400;
    color: var(--colors-neutral-100);
    line-height: 3rem;

    .bold {
      font-weight: 600;
    }
  }
}
