.root {
  flex: 1;
  display: flex;
  flex-direction: column;
  max-height: 100%; /*Needed to constrain the height so it can overflow*/
  min-width: 0;

  .inputContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-bottom: 2rem;
  }
}
