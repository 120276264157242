.tabsWrapper {
  display: flex;
  flex-direction: row;
}

.emptyStateWrapper {
  display: flex;
  justify-content: center;
}

.filterSetsContainer {
  overflow: auto;
  max-height: 57.5rem;
}
