.root {
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;

  .message {
    font-size: 1.75rem;
    line-height: 3rem;
    font-weight: 500;
    margin-bottom: 2rem;
  }
}
