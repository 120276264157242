.root {
  flex: 1;
  padding: 0 1rem;

  .disclaimer {
    font-size: 1.5rem;
    color: var(--colors-neutral-100);
    margin-top: 2rem;
    margin-left: 0.5rem;
  }

  .userRow {
    display: flex;
    align-items: center;
    height: 6rem;
    border-bottom: 1px solid var(--colors-neutral-20);

    div.icon {
      position: relative;
      margin-right: 1rem;

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.5rem;
        height: 4rem;
        width: 4rem;
        box-shadow: inset 0 1px 0 0 rgba(var(--colors-white), 0.3);
        border: 1px solid var(--colors-dark-blue-100);
        background: var(--colors-blue-80);

        &.warning {
          opacity: 0.25;
        }

        &.gray {
          opacity: 0.5;
          background: var(--colors-neutral-5);
          border: 1px solid var(--colors-neutral-50);
        }
      }

      .actionIcon {
        display: none;
        cursor: pointer;
      }

      .warningSign {
        position: absolute;
        right: -1px;
        bottom: 0;
        height: 12px;
        display: flex;
        width: 15px;
        align-items: flex-end;
        justify-content: flex-end;
      }

      &.disabled {
        opacity: 0.25;
      }

      &:not(.disabled):hover {
        /* .icon {
          opacity: 0.25;
        } */

        .actionIcon {
          cursor: pointer;
          width: 4rem;
          height: 4rem;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 0;
          left: 0;
          background-color: rgba(256, 256, 256, 0.5);
        }

        .warningSign {
          display: none;
        }
      }
    }
  }
}
