.rootBulletList {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 0.5rem;
  height: 4rem;
  opacity: 0.5;
  width: 6rem;
  justify-content: space-around;

  &:not(.disabled):hover,
  &.selected,
  &.open {
    opacity: 1;
    background: var(--colors-neutral-10);
  }

  &.selected,
  &.open {
    box-shadow: inset 0 0 0 1px var(--colors-neutral-20);
  }

  &.disabled {
    opacity: 0.15;
    cursor: not-allowed;
  }
}

.menu {
  padding: 1rem;
  opacity: 1;
}

.styles {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 13rem 13rem;
  max-height: 41rem;
  overflow: auto;
  padding: 0 1rem;
  margin-bottom: 2rem;
  min-width: 30rem;

  .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    border: solid 1px var(--colors-neutral-20);
    width: 13rem;
    height: 12rem;
    padding: 1rem;

    &:hover {
      box-shadow: 0 0 2px 0 var(--colors-blue-100);
    }

    &.selected {
      box-shadow: 0 0 0 2px rgba(29, 148, 243, 0.35);
      border: solid 1px var(--colors-neutral-50);
    }
  }
}
