.root {
  display: flex;
  align-items: center;
  width: 100%;

  > * {
    display: flex;
    align-items: center;
  }

  .left {
    margin-right: auto;
    column-gap: 2rem;
  }

  .right {
    margin-left: auto;
  }
}
