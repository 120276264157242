.pdfPage {
  position: relative;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  margin: 0 auto;

  &.drawing {
    cursor: url(./pencil-cursor.png), auto;
  }

  .overlays {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    line-height: 1;
    text-align: initial;
    text-size-adjust: none;
    forced-color-adjust: none;

    .span::selection {
      background: rgba(0, 0, 255, 0.2);
    }

    .br::selection {
      background: transparent;
    }

    .br,
    .span {
      position: absolute;
      color: transparent;
      white-space: pre;
      padding: 0;
      margin: 0;
      transform-origin: 0% 0%;
    }
  }
}
