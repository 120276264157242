.header {
  display: flex;
  height: 8rem;

  .actionsContainer {
    display: flex;
    background: var(--colors-white);
  }

  .togglesContainer {
    display: flex;
    background: var(--colors-white);
    margin-left: 6rem;
  }
}
