.root {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 2rem 0 0 0;

  .header {
    display: flex;
    height: 8rem;
  }

  .page {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    padding: 2rem 6rem 3rem;
    min-height: 0;
  }
}
