.root {
  display: flex;
  align-items: center;
  height: 6rem;
  padding-left: 2rem;
  cursor: pointer;

  &:hover {
    background: var(--colors-blue-10);
  }

  &:active {
    background: var(--colors-blue-20);
  }

  .addAccount {
    display: flex;
    flex-direction: row;
    align-items: center;

    font-size: 1.5rem;
    font-weight: 500;
    color: var(--colors-neutral-100);
    line-height: 2rem;
  }
}
