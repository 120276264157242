.root {
  display: flex;
  flex-direction: column;
  min-height: 0;

  @supports not (-ms-high-contrast: none) {
    /* Non-IE styles here */
    flex: 1;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE styles here */
    flex-grow: 1;
  }

  &.noOverflow {
    overflow: hidden !important;
  }
}
