.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;

  .commentsList {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &.listMode {
      gap: 0.5rem;
    }

    overflow: auto;

    //Hack to dont cut off box-shadow of elements
    margin: -4px;
    padding: 4px;
  }

  .emptyState {
    margin-top: 3rem;
  }

  .emptyStateSearchBy {
    font-size: 1.75rem;
    font-weight: 600;
    color: var(--colors-neutral-100);
    line-height: 3rem;
    text-align: center;
    margin-top: 1rem;
  }

  .commentsControl {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 2rem 0;

    .commentsCounter {
      font-size: 1.5rem;
      font-weight: 400;
      color: var(--colors-neutral-60);
      line-height: 2rem;
    }
  }
}
