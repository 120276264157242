.pageContainer {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: var(--colors-dark-blue-95);

  .logoContainer {
    position: absolute;
    top: 1rem;
  }

  .errorCode {
    margin-top: 14rem;
    font-size: 27.5rem;
    font-weight: 800;
    letter-spacing: 1.25rem;
    line-height: normal;
    text-align: center;
    color: var(--colors-dark-blue-60);
  }

  .title {
    font-size: 6rem;
    font-weight: bold;
    line-height: normal;
    color: var(--colors-white);
    margin-top: 0.625rem;
    margin-bottom: 1rem;
  }

  .description {
    font-size: 2rem;
    color: var(--colors-white);
    line-height: normal;
    margin-bottom: 3rem;
  }

  .footer {
    position: absolute;
    bottom: 4rem;
  }
}
