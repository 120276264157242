.collapser {
  margin-top: 1rem;
  margin-bottom: 3rem;

  .information {
    max-height: 28rem;
    overflow-y: scroll;
    margin-top: 1rem;

    *:not(:first-child) {
      margin-top: 1.5rem;
    }
  }
}
