.emptyState {
  padding: 2rem 3rem;
  display: flex;
  justify-content: center;
}

.emptyStateFiltersApplied {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.emptyStateSearchBy {
  font-size: 1.75rem;
  font-weight: 600;
  color: var(--colors-neutral-100);
  line-height: 3rem;
  text-align: center;
  margin-top: 1rem;
}

.tasksControl {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1rem 0rem 1rem;

  .tasksCounter {
    font-size: 1.5rem;
    font-weight: 400;
    color: var(--colors-neutral-60);
    line-height: 2rem;
  }
}

.taskList {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  overflow: auto;
  padding: 1rem;

  &.listMode {
    gap: 0.5rem;
  }
}
