.list {
  overflow: auto;
  height: 100%;
  padding: 1rem;
  margin: -1rem;

  .style {
    margin: 0;
  }
}

.virtualized {
  padding: 1rem;
  margin: -1rem;
}
