.container {
  margin-bottom: 2rem;

  .text {
    padding: 0 0 1.5rem 0;
    font-size: 1.5rem;
  }
  .currentStyle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 3rem;

    .multipleStyles {
      color: var(--colors-neutral-60);
    }
  }
}
