.information {
  margin-top: 7rem;

  .title {
    font-size: 1.75rem;
    font-weight: 500;
    color: var(--colors-neutral-100);
    line-height: 3rem;
  }

  .body {
    width: 54rem;
    padding-top: 0.5rem;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2rem;
    color: var(--colors-neutral-80);
  }
}
