.area {
  + .area {
    margin-top: 2rem;
  }

  .header {
    font-weight: 500;
    font-size: 1.75rem;
    line-height: 5rem;
    box-shadow: inset 0 -1px 0 0 var(--colors-neutral-20);
  }

  .fields {
    margin-top: 1rem;
    display: flex;
  }
}

.color {
  width: 12rem;
  height: 3rem;
  border-radius: 2px;
}

.squares {
  display: grid;
  grid-template: 1fr 1fr / 1fr 1fr 1fr 1fr;
  gap: 2rem;
  padding: 1.5rem;

  .square {
    height: 3rem;
    width: 3rem;
    border-radius: 2px;
    cursor: pointer;

    &:hover {
      box-shadow: 0 0 0 4px var(--colors-blue-10);
    }

    &.selected {
      box-shadow: 0 0 0 3px var(--colors-blue-20), 0 0 0 4px var(--colors-blue-30);
    }
  }
}
