.menus {
  width: 20rem;
  margin-right: 1.5rem;
  overflow: auto;

  .instancesContainer {
    margin-bottom: 4rem;

    &:last-child {
      margin-bottom: 0;
    }

    .label {
      font-size: 12px;
      letter-spacing: 1.5px;
      color: var(--colors-neutral-60);
      margin-bottom: 1rem;
    }
  }
}
