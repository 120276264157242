[ispagenode='true'] {

  table {
    width: var(--tableWidth);
    height: max-content;
    clear: both !important;
    display: table;
    border-collapse: collapse !important;
    margin: 0;
    transition: none !important;
    position: relative;
    /* break-before: avoid;*/
    /* break-inside: avoid;  */

    max-width: 55.87cm;

    placeholder-element {
      --padding: 0px;
    }

    caption {
      display: table-caption;

      &:focus {
        outline: none;
      }
    }

    tbody {
      vertical-align: baseline;

      &[data-vertical-alignment='t'],
      &[data-vertical-alignment='top'] {
        vertical-align: top;
      }

      &[data-vertical-alignment='c'],
      &[data-vertical-alignment='center'] {
        vertical-align: middle;
      }

      &[data-vertical-alignment='b'],
      &[data-vertical-alignment='bottom'] {
        vertical-align: bottom;
      }

      tr {
        &[data-vanish='true'] {
          border: 2px dashed var(--colors-blue-100);
        }
        
        min-height: 0.04cm;
        max-height: 55.87cm;
        /* break-inside: avoid; */
        /* break-after: avoid; */
      }
    }

    &[data-text-alignment='left'] {
      paragraph-element,
      figure-element {
        text-align: left;
      }
      table {
        margin-left: auto;
      }
    }

    &[data-alignment='l'],
    &[data-alignment='left'] {
      margin-right: auto;
      caption {
        text-align: left;
      }
    }

    &[data-text-alignment='center'] {
      paragraph-element,
      figure-element {
        text-align: center;
      }
      table {
        margin: 0 auto;
      }
    }

    &[data-alignment='c'],
    &[data-alignment='center'] {
      --remainingWidth: calc(var(--contentWidth) / 2 - var(--tableWidth) / 2);
      margin-left: Max(0.001px, var(--remainingWidth));
      caption {
        text-align: center;
      }
    }

    &[data-text-alignment='right'] {
      paragraph-element,
      figure-element {
        text-align: right;
      }
      table {
        margin-left: auto;
      }
    }

    &[data-ar='true'] {
      paragraph-element,
      table {
        word-break: normal;
      }
    }

    &[data-alignment='r'],
    &[data-alignment='right'] {
      --remainingWidth: calc(var(--contentWidth) - var(--tableWidth));
      margin-left: Max(0.001px, var(--remainingWidth));
      caption {
        text-align: right;
      }
    }

    &[data-text-alignment='justify'] {
      paragraph-element,
      figure-element {
        text-align: justify;
      }
      table {
        margin-left: auto;
      }
    }

    &[data-alignment='j'],
    &[data-alignment='justify'] {
      caption {
        text-align: justify;
        text-justify: inter-word;
      }
    }

    &[lock] {
      box-shadow: inset var(--colors-neutral-5) var(--tableWidth) 0, 0 0 0 1px var(--colors-neutral-20);
    }

    > .optionsContainer {
      position: absolute;
      left: 0;
      top: 0;
      width: 0;
      height: 100%;

      > span {
        opacity: 0.5;
      }

      &[visible='true'] {
        visibility: visible;

        &:hover > span,
        &[enabled='true'] > span {
          opacity: 1;
        }
      }
    }
  }
}
