.body {
  .content {
    .loading {
      margin-bottom: 2rem;
    }

    .message {
      font-weight: 400;
      line-height: 3rem;
    }

    .messageFooter {
      margin-top: 3rem;
    }
  }
}
