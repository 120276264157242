.root {
  flex: 1;
  display: flex;
  margin-top: 3rem;
  min-height: 0;

  .list {
    flex: 1;
    padding-right: 2rem;
    display: flex;
    flex-direction: column;
    min-width: 0;
    min-height: 68rem;

    .filter {
      display: flex;
      align-items: center;

      margin: 0 0 2rem 0;

      &.export {
        margin: 0 0 3rem 0;
      }
    }

    .title {
      font-size: 2rem;
      font-weight: 600;
      line-height: 3rem;
      color: var(--colors-neutral-100);
      margin-right: auto;
    }
  }

  .information {
    display: flex;
    flex-direction: column;
  }
}
