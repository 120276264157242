.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;

  .info {
    display: flex;
    flex-direction: column;
    align-items: center;

    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    padding: 0 2.5rem;

    .username {
      font-size: 1.75rem;
      font-weight: 600;
      color: var(--colors-neutral-100);
      line-height: 3rem;
      margin-top: 1rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100%;
    }

    .email {
      font-size: 1.25rem;
      font-weight: 400;
      color: var(--colors-neutral-60);
      line-height: 2rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100%;
    }
  }
}
