.popover {
  padding: 1.5rem 0;
  width: 19rem;

  .item {
    padding: 0 2rem 0 1rem;

    &:hover {
      background-color: var(--colors-blue-20);
      cursor: pointer;
    }

    display: flex;
    align-items: center;
    height: 4rem;
    font-size: 1.5rem;
    line-height: 2rem;
    cursor: pointer;

    .selection {
      margin-right: 0.5rem;
      visibility: hidden;
    }

    .color {
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 0.75rem;
      border-style: solid;
      border-width: 1px;
      margin: 0 0.75rem;

      &.none {
        border-color: var(--colors-neutral-70);
        background-color: var(--colors-white);
        position: relative;
        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 45%;
          height: 1px;
          width: 100%;
          background-color: var(--colors-red-100);
          transform: rotate(-45deg);
        }
      }
      &.black {
        border-color: var(--colors-neutral-105);
        background-color: var(--colors-neutral-90);
      }
      &.yellow {
        border-color: var(--colors-yellow-105);
        background-color: var(--colors-yellow-100);
      }
      &.green {
        border-color: var(--colors-green-105);
        background-color: var(--colors-green-95);
      }
      &.red {
        border-color: var(--colors-red-105);
        background-color: var(--colors-red-80);
      }
      &.blue {
        border-color: var(--colors-blue-105);
        background-color: var(--colors-blue-95);
      }
    }

    .text {
      margin-left: 0.5rem;
    }

    .strokeWidth {
      background: var(--colors-neutral-60);
      width: 100%;
    }

    &.isSelected {
      .selection {
        visibility: visible;
      }
    }
  }
}

.textbox {
  padding: 2rem 2.75rem 2rem 2rem;
  width: 41rem;

  .row {
    display: flex;
    justify-content: space-between;

    + .row {
      margin-top: 1rem;
    }
  }

  .textStyles {
    display: flex;
    width: 100%;
  }

  .container {
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: inset 0 0 0 1px var(--colors-neutral-20);
    width: 100%;
    height: 4rem;
    margin-left: 1rem;
    cursor: pointer;

    &:hover {
      box-shadow: inset 0 0 0 1px var(--colors-neutral-40);
    }

    .color {
      border-radius: 0.5rem;
      width: 100%;
      height: 100%;
    }
  }
  .colors {
    padding: 2rem;
    display: flex;

    .color {
      border-radius: 0;
      height: 3rem;
      width: 3rem;
      cursor: pointer;

      &:hover {
        box-shadow: 0 0 0 1px white, 0px 0px 4px rgba(0, 0, 0, 0.5);
      }

      &.selected {
        box-shadow: 0 0 0 1px white, 0px 0px 4px rgba(0, 0, 0, 0.5);
      }

      + .color {
        margin-left: 0.5rem;
      }
    }
  }
}
