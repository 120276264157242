.root {
  grid-area: toolbar;
  height: 5rem;
  box-sizing: border-box;
  border-bottom: 1px solid var(--colors-neutral-20);

  .container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 0 0.75rem 0 1rem;
    border-top: 1px solid var(--colors-neutral-20);
  }
}
