.textDrop {
  height: 19px;
  font-size: 14px;
  color: var(--colors-neutral-80);
  font-weight: 500;
}

.avatarInfo {
  display: flex;
  height: 3rem;
  font-size: 14px;
  font-weight: 500;
  margin-top: 2rem;

  .avatarText {
    width: 74px;
    color: var(--colors-neutral-80);
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .avatarSize {
    color: var(--colors-neutral-50);
    margin-left: 4.5px;
  }
}

.editAvatarUpload {
  width: 480px;
  height: 397px;
  border-radius: 8px;
  box-shadow: inset 0 0 0 7px var(--colors-white);
  border: solid 2px var(--colors-blue-50);
  display: flex;
  align-items: center;
  flex-direction: column;
}

.button {
  margin-top: 12px;
}

.zoom {
  display: flex;
  align-items: center;
  margin-top: 16px;
  height: 20px;
}

.slider {
  -webkit-appearance: none;
  width: 77px;
  height: 8px;
  object-fit: contain;
  border-radius: 2px;
  background-color: var(--colors-neutral-20);
  box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.5);
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: var(--colors-white);
  border: solid 1px var(--colors-neutral-50);
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: var(--colors-white);
  border: solid 1px var(--colors-neutral-50);
  cursor: pointer;
}
