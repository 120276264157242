.popoverContainer {
  position: relative;
  width: 33.25rem;
  padding: 2rem 3rem;
  text-align: left;

  .triangle {
    width: 1rem;
    height: 1rem;
    background: var(--colors-white);
    position: absolute;
    top: 2rem;
    right: -0.6rem;
    transform: rotate(45deg);
    border-right: 1px solid var(--colors-neutral-20);
    border-top: 1px solid var(--colors-neutral-20);
  }

  .title {
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 2rem;
  }

  .rule {
    display: flex;
    align-items: center;
    height: 3rem;
    margin-bottom: 1rem;

    .ruleName {
      color: var(--colors-neutral-40);
      font-size: 1.5rem;
      line-height: 2rem;
      margin-left: 1rem;

      &.verifiedName {
        color: var(--colors-neutral-100);
      }
    }
  }

  .note {
    font-size: 1.25rem;
    line-height: 2rem;
    color: var(--colors-neutral-60);
    margin-top: 2rem;
  }
}
