.preview {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  display: -webkit-box;
  justify-content: space-between;
  text-align: left !important;
  min-height: 0;
  max-height: 11rem;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;

  &::before {
    text-transform: none;
  }
}

.background {
  width: 100%;
}
