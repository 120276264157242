.root {
  padding-top: 4rem;
  overflow: auto;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: var(--colors-dark-blue-95);
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  font-size: 14px;
  text-align: center;
  line-height: 3rem;

  .container {
    margin-top: 8rem;

    .error {
      font-size: 27.5rem;
      line-height: 33.25rem;
      letter-spacing: 10px;
      font-weight: 800;
      text-align: center;
      color: var(--colors-dark-blue-60);
    }

    .sorry {
      margin-top: 6rem;
      font-size: 48px;
      font-weight: 600;
    }
  }

  .footer {
    position: absolute;
    bottom: 25px;
  }
}
