.body {
  display: flex;
  flex-direction: column;

  .label {
    height: 5rem;
    padding: 1rem 0;
  }

  .spaces {
    display: flex;
    flex-direction: row;
    margin: 4rem 0;
  }

  .side {
    flex: 1;
    display: flex;
    flex-direction: column;

    .input {
      width: 25rem;
      min-width: 25rem;
    }
  }
}

.section {
  margin-bottom: 3rem;

  &:not(:first-child) {
    margin-top: 5rem;
  }
}

.content {
  padding: 0 1rem;

  .paragraphSpacing {
    display: flex;
    flex-direction: row;
    gap: 2rem;

    .column {
      display: flex;
      flex-direction: column;
    }
  }
}
