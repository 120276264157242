page-break-element {
  font-family: Inter;
  color: var(--colors-neutral-60);
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  outline: 0;
  text-rendering: optimizeSpeed;

  &.block {
    --leftMargin: 0pt;
    --rightMargin: 0pt;
    --marginSize: calc(var(--leftMargin) + var(--rightMargin));
    margin-left: var(--leftMargin);
    margin-right: var(--rightMargin);
    user-select: none;
    background-color: var(--suggestionBackgroundColor);
    height: 40px;

    width: calc(var(--contentWidth) - var(--marginSize));

    @supports not (-ms-high-contrast: none) {
      /* Non-IE styles here */
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      /* IE styles here */
      display: block;
      text-align: center;
      padding-top: 12px;
    }

    &[lock] {
      box-shadow: inset var(--colors-neutral-5) var(--contentWidth) 0,
        0 0 0 1px var(--colors-neutral-20);
    }
  }

  &.inline {
    display: inline;

    &[onlyChild] {
      width: 98%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      white-space: nowrap;
      overflow: hidden;
      text-decoration: inherit;
    }
  }
}
