.root {
  height: 100%;
  width: 100%;
  background: var(--colors-blue-5);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 4rem;

  .container {
    width: 45rem;
    text-align: center;

    .title {
      font-weight: 600;
      font-size: 3.5rem;
      line-height: 4rem;
    }

    .description {
      margin: 2rem 0;
      color: var(--colors-neutral-80);
      font-size: 1.75rem;
      line-height: 3rem;
    }
  }

  .content {
    margin-top: 10rem;
  }
}
