.root {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  position: sticky;
  z-index: 3;
  top: 0;
  background-color: var(--colors-neutral-2);
}

.container {
  display: flex;
  align-items: center;
  flex-direction: row;
  border-bottom: 1px solid var(--colors-neutral-20);
  height: 2.5rem;

  &.webLayout {
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-around;
  }
}

.ruler {
  display: flex;
  height: 2.375rem;
  background-color: var(--colors-neutral-2);
}

.rectangle {
  background-color: var(--colors-neutral-20);
  box-shadow: inset 1px 0px 0px rgba(190, 190, 198, 1);
  opacity: 0.7;
  height: 2.375rem;
  width: 100%;

  &.left {
    box-shadow: inset -1px 0px 0px rgba(190, 190, 198, 1);
  }
}

.leftMargin {
  position: absolute;
  display: flex;
  height: 2.375rem;
  background-color: var(--colors-neutral-10);
  box-shadow: inset -1px 0px 0px rgba(225, 225, 229, 1);
}

.rightMargin {
  position: absolute;
  height: 2.375rem;
  background-color: var(--colors-neutral-10);
  box-shadow: inset 1px 0px 0px rgba(225, 225, 229, 1);
}

.groupsContainer {
  display: flex;
}

.group {
  display: flex;
  flex-direction: row;
  z-index: 2;
  pointer-events: none;
}

.cm {
  display: flex;
  align-items: flex-end;
  height: 1rem;
  margin: 9px 0 0 0;
}

.first {
  padding: 0;
  height: 2.5rem;
  margin: 0 1rem;
}

.marginMmContainer {
  display: flex;
  align-items: flex-end;
  margin-bottom: 2px;

  .marginMm {
    height: 0.5rem;
    border-left: 1px solid var(--colors-neutral-20);
    z-index: 2;
  }
}

.marginMm:first-child {
  height: 0.5rem !important;
}

.marginMm:last-child {
  padding: 0 !important;
}

.marginMm:nth-last-child(n + 2) {
  height: 8px;
}

.mm {
  height: 0.5rem;
  padding: 0 0 0 1rem;
  border-left: 1px solid var(--colors-neutral-20);

  &.number {
    padding: 0;
    border-left: 1px solid var(--colors-neutral-40);
    margin: 0 1rem;
  }
}

.cmNumber {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.label {
  font-size: 7px;
  color: var(--colors-neutral-40);
  font-weight: 500;
  line-height: 1rem;
  text-align: center;
  margin: 2px 0 3px 0;
}

.mm:first-child {
  padding: 0 1rem 0 0;
}

.mm:last-child {
  padding: 0;
}
.mm:nth-of-type(2) {
  height: 8px;
}

.indentationIcon {
  position: absolute;
  padding-top: 0.5rem;
  z-index: 4;
}

.tabulationIcon {
  position: absolute;
  padding-top: 0.875rem;
  z-index: 4;
}
