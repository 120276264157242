.root {
  display: flex;

  .img_container {
    width: 9rem;
    height: 9rem;
    text-align: center;
    margin-right: 1rem;

    .image {
      object-fit: scale-down;
      max-width: 9rem;
      max-height: 9rem;
      width: auto;
      height: auto;
      border: 1px solid var(--colors-neutral-30);

      &.noTemplate {
        border: 1px dashed var(--colors-neutral-20);
        background-color: var(--colors-white);
        width: 6.5rem;
        height: 9rem;
        margin: 0 1rem;
      }
    }
  }

  .name {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 2rem;
    margin: 1.5rem 0 1rem 0;
  }
}
