.root {
  display: flex;
  flex-direction: row;

  padding: 1rem 0 1rem 4rem;

  .avatar {
    min-width: 4rem;
  }

  .container {
    display: flex;
    flex-direction: column;

    margin-left: 1rem;
    overflow: hidden;

    .name {
      font-weight: 500;
      font-size: 1.5rem;
      line-height: 2rem;
      color: var(--colors-neutral-100);
    }

    .email {
      font-weight: 400;
      font-size: 1.25rem;
      line-height: 2rem;
      color: var(--colors-neutral-60);
    }

    .ellipsis {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &:hover {
    background: var(--colors-neutral-10);
  }
}
