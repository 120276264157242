.root {
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;

    padding: 1rem 0 2rem 0;
  }
}
