.root {
  height: 100%;
  display: grid;
  grid-template-columns: auto 1fr auto 6rem;
  grid-template-rows: 8rem 6rem 1fr 6rem;
  grid-template-areas:
    'title title title title'
    'toolbar toolbar toolbar toolbar'
    'leftpanel content rightpanel sidebar'
    'footer footer footer footer';
}
