.root {
  flex: 1;
  color: var(--colors-neutral-80);
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: calc(100vh - 62rem);
}

.linkCell {
  height: 3rem;
  overflow: hidden;
  line-height: 2rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.noInfo{
  font-size: 1.75rem;
  line-height: 3rem;
  color: var(--colors-neutral-60);
  font-weight: 400;
  height: 3rem;
}

.emptyView {
  margin-top: 7rem;
  display: flex;
  justify-content: center;
}
