.table {
  width: 100%;
}

.root {
  margin-top: 2rem;
}

.container {
  margin-top: 3rem;
  overflow-y: auto;
  height: 100%;
}

.noAuthenticators {
  margin-top: 8rem;
  display: flex;
  justify-content: center;
}

.emptyMessage {
  display: flex;
  justify-content: center;
  padding-top: 8rem;
  font-size: 14px;
  color: var(--colors-neutral-80);
}

.connection {
  display: flex;
}

.id {
  flex: 1;
  text-align: left;
}
.service {
  width: 23rem;
  text-align: left;
}
.creation {
  width: 23rem;
  text-align: left;
}
.remove {
  padding: 0 4rem;
  width: 1rem;
  color: var(--colors-white);
}

.value {
  text-align: left;
  border-bottom: 1px solid var(--colors-neutral-20);
}
