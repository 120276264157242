.root {
  width: 20rem;
  height: 28rem;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  border-radius: 0.5rem;

  &.active {
    border: 1px solid var(--colors-neutral-40);
    box-shadow: 0px 0px 0px 2px rgba(29, 148, 243, 0.35);
    border-radius: 4px;
  }

  .img_container {
    width: 16rem;
    height: 18rem;
    text-align: center;

    .img {
      object-fit: scale-down;
      max-width: 16rem;
      max-height: 18rem;
      width: auto;
      height: auto;
      cursor: pointer;
      border: 1px solid transparent;

      &.noTemplate {
        border: 1px dashed var(--colors-neutral-20);
        background-color: var(--colors-white);
        width: 13.75rem;
        height: 18rem;
        margin: 0 1rem;
      }

      &:hover {
        border-color: var(--colors-neutral-40);
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
      }
    }
  }

  .name {
    margin-top: 2rem;
    text-align: center;
    color: var(--colors-neutral-80);
    font-size: 1.5rem;
    line-height: 2rem;
    height: 4rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
