.menu {
  z-index: 10;
  max-height: calc(100% - 20px);
  max-width: 45rem;
  margin: 1rem 0 1rem 1rem;

  &.overlap {
    z-index: 1052;
  }
}
