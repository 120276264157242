.forgotPassword {
  color: var(--colors-neutral-20);
  font-size: 14px;
  line-height: 1.5em;
  margin: 6px 0 0;
  text-align: left;
}

.link {
  color: var(--colors-blue-100) !important;

  &:hover {
    color: var(--colors-blue-100);
    border-bottom: 1px solid var(--colors-blue-100);
    cursor: pointer;
  }
}
