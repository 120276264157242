.toggleDropdown {
  position: relative;
  text-transform: capitalize;
  width: 20rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: var(--colors-neutral-2);
  color: var(--colors-neutral-100);
  padding-left: 1rem;
  height: 4rem;
  border-radius: 0.5rem;

  &.disabled {
    color: var(--colors-neutral-40);
  }

  &:not(.disabled):hover {
    background: var(--colors-neutral-10);
  }

  &:not(.disabled):focus {
    outline: 0;

    &:active {
      box-shadow: 0 0 0 0;
      background-color: var(--colors-neutral-10) !important;
    }
  }

  &:not(.disabled):active {
    box-shadow: 0 0 0 0;
    background-color: var(--colors-neutral-10) !important;
  }

  .text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.5rem;
    color: var(--colors-neutral-100);
  }
}

.arrowDiv {
  margin-left: 2rem;
  margin-right: 1rem;
}

.item {
  position: relative;
  padding-left: 4rem;
  padding-right: 4rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 0;
  background: var(--colors-white);
  width: 100%;
  height: 4rem;

  &:hover {
    background: var(--colors-blue-50);
    color: var(--colors-white);
  }

  &:focus {
    outline: 0;

    &:active {
      outline: 0;
      box-shadow: 0 0 0 0;
      background: var(--colors-white);
    }
  }

  &:active {
    box-shadow: 0 0 0 0;
    background: var(--colors-white);
  }
}
