.root {
  overflow: auto;
  height: 100%;
  padding: 3rem 3rem 2rem 0;

  .history {
    display: flex;
    width: 100%;

    .list {
      display: flex;
      flex-direction: column;
      row-gap: 2rem;
      width: 100%;
    }
  }
}

.downloadOriginal {
  padding: 3rem 3rem 0rem 2rem;
}
