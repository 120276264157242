.root {
  font-size: 1.75rem;
  font-weight: 600;
  color: var(--colors-blue-100);
  line-height: 3rem;

  .userName {
    font-weight: 500;
    color: var(--colors-neutral-80);
  }
}
