.root {
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 6rem;
    padding: 0 1rem;
    background-color: var(--colors-blue-5);
    border-radius: 0.5rem;

    .section {
      display: flex;
      align-items: center;
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 2rem;

      .empty {
        color: var(--colors-neutral-40);
      }

      .properties {
        display: flex;
        align-items: center;
        gap: 4.125rem;

        .property {
          color: var(--colors-neutral-60);
        }
      }
    }
  }

  .body {
    margin-top: 1rem;

    .properties {
      display: flex;
      justify-content: space-between;
      position: relative;

      .section {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        font-size: 14px;
        font-weight: 400;
        line-height: 3rem;
        color: var(colors-neutral-100);

        .radioButtons {
          display: flex;
          gap: 2rem;
        }
      }
    }
  }
}
