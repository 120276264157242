@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}

.spinner {
  width: 70px;
  text-align: center;

  .bounce1 {
    animation-delay: -0.32s;
  }

  .bounce2 {
    animation-delay: -0.16s;
  }

  div {
    width: 13px;
    height: 13px;
    background-color: var(--colors-white);
    margin-left: 4px;
    margin-right: 4px;
    border-radius: 100%;
    display: inline-block;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  &.blue {
    div {
      background-color: var(--colors-blue-100);
    }
  }
}
