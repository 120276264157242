:root {
  --width: 20rem;
}

.root {
  flex: 1;
  padding: 2rem 6rem 0 0;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  height: 8rem;

  .search {
    width: 35rem;
    margin-left: 4rem;
  }
}

.content {
  flex: 1;
  display: flex;
  margin-top: 2rem;
  min-height: 0;
  margin-left: 6rem;

  .tableWrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}
