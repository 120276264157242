.label {
  margin-top: 2rem;
  font-size: 1.5rem;
  line-height: 2rem;
  padding: 2rem 0;
  border-bottom: 1px solid var(--colors-neutral-20);
}

.list {
  overflow: auto;
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem 1rem 1rem;
    border-bottom: 1px solid var(--colors-neutral-20);

    .user_label {
      font-size: 1.5rem;
      line-height: 2rem;
      color: var(--colors-neutral-40);
      white-space: nowrap;
    }
  }
}
