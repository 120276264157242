.root {
  display: flex;
  flex-direction: column;

  width: 41rem;

  .settings {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 4rem;
  }
}
