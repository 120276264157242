.root {
  width: 100%;
  height: 100%;
}

.tabs {
  display: flex;
}

.tab {
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &.disabled {
    color: var(--colors-neutral-50);
    background: var(--colors-white);
  }

  &.tabCursor {
    cursor: pointer;
  }
}
