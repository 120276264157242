.root {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 400px;
}

.circle {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 37.5rem;
  height: 37.5rem;
  background-color: var(--colors-red-80);
  border-radius: 50%;
  border: 3px solid var(--colors-red-100);
  cursor: pointer;

  .icon {
    width: 96px;
    height: 96px;
    margin-top: -3rem;
    margin-bottom: 1rem;
  }

  .title {
    font-size: 2.25rem;
    text-align: center;
    width: 100%;
    padding: 0 2rem;
    color: var(--colors-white);
    margin-bottom: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .subTitle {
    text-align: center;
    width: 100%;
    padding: 0 4rem;
    color: var(--colors-white);
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
