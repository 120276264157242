:root {
  --width: 20rem;
}

.root {
  flex: 1;
  padding: 2rem 6rem 0 0;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  height: 8rem;

  .search {
    width: 35rem;
    margin-left: 4rem;
  }
}

.innerContainer {
  display: flex;
  background: var(--colors-white);
}

.content {
  display: flex;
  flex: 1;
  margin-top: 2rem;
  min-height: 0;
  margin-left: 6rem;

  .tableWrapper {
    display: flex;
    flex-direction: column;
    flex: 1;

    .name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.emptyView {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
}
