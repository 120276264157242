.list {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 4rem;

  .empty {
    margin-top: 6rem;
  }

  .loading {
    margin-top: 6rem;
  }

  .center {
    display: flex;
    justify-content: center;
  }

  .align {
    display: flex;
    align-items: center;
  }

  .deactivated {
    color: var(--colors-neutral-30);
  }

  .email {
    color: var(--colors-neutral-100);
    overflow: hidden;
  }

  .deactivated {
    &.email {
      color: var(--colors-neutral-30);
    }

    .avatar {
      opacity: 0.3;
    }
  }

  .tooltip {
    padding: 2rem;
    font-size: 1.75rem;
    color: var(--colors-neutral-100);

    &.hidden {
      display: none;
    }
  }

  .popper {
    display: flex;
    flex-direction: column;
    width: 30rem;

    .content {
      padding: 1rem 0 1rem 0;
      width: 100%;

      .option {
        display: flex;
        align-items: center;
        height: 5rem;
        padding-left: 4rem;
        color: var(--colors-neutral-100);

        &.danger {
          color: var(--colors-red-100);
        }

        &:hover {
          background-color: var(--colors-blue-10);
        }
      }
    }
  }
}
