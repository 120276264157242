comment-element {
  margin: 0 0;
  padding: 0;
}

comment-element[disabled] {
  background-color: var(--colors-neutral-2) !important;
  box-shadow: unset !important;

  format-element[highlightcolor] {
    background-color: var(--colors-neutral-2) !important;
    box-shadow: unset !important;
  }
}
