.noAssignee {
  display: flex;
  column-gap: 1rem;
  align-items: center;

  .avatarPlaceholder {
    width: 4rem;
    height: 4rem;

    background: var(--colors-neutral-10);
    border: 1px solid var(--colors-neutral-20);
    border-radius: 100%;
  }

  .label {
    font-style: italic;
    color: var(--colors-neutral-40);
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2rem;
  }
}
