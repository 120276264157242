.popover {
  padding: 2rem 3rem 3rem;
  width: 28rem;

  .title {
    font-size: 1.75rem;
    font-weight: 500;
    line-height: 3rem;
  }

  .checkboxes {
    margin-top: 3rem;
  }

  .button {
    display: flex;
    justify-content: flex-end;
  }
}
