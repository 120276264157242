.table {
  width: 100%;
}

.removeButton {
  width: auto;
}

.active {
  padding: 0 2.5rem;
  border-radius: 0.5rem;
  background-color: var(--colors-blue-10);
  height: 3rem;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--colors-neutral-80);
}

.platform {
  width: 33rem;
  text-align: left;
}

.ipAdress {
  width: 33rem;
  text-align: left;
}

.expire {
  flex: 1;
  text-align: left;
}

.value {
  text-align: left;
}

.name {
  width: 33rem;
  text-align: left;
}

.lastAccess {
  width: 23rem;
  text-align: left;
}

.ipAdressDevice {
  flex: 1;
  text-align: left;
}

.remove {
  padding: 0 4rem;
  width: 14rem;
  color: var(--colors-white);
}

.container {
  margin-top: 3rem;
  overflow-y: auto;
  height: 100%;
}
