.divider {
  margin: 2rem 0;
  border-top: 1px solid var(--colors-neutral-20);
}

.content {
  padding: 0 2rem;
}

.template {
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
}
