.timezoneLabel {
  height: 5rem;
  font-size: 14px;
  color: var(--colors-neutral-100);
  display: flex;
  align-items: center;
}

.timezone {
  width: 100%;
}
