.root {
  margin-top: 3rem;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: inset 0 -1px 0 var(--colors-neutral-20);
    font-size: 1.75rem;
    font-weight: 500;
    height: 5rem;
  }

  .table {
    .row {
      height: 5rem;
      box-shadow: inset 0 -1px 0 var(--colors-neutral-20);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 2rem;
      font-size: 1.5rem;

      .value {
        color: var(--colors-neutral-50);
      }
    }
  }
}
