.root {
  position: relative;
  width: 25rem;
  min-width: 25rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: var(--colors-blue-90);
  z-index: 3;
  padding: 0 1rem 1rem;

  .content {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none !important;

    li {
      margin: 0 !important;
    }
  }

  &.collapsed {
    width: 7rem;
    min-width: 7rem;

    &:hover {
      .expander {
        color: var(--colors-white);
        left: 4rem;
      }
    }

    .expander {
      left: 1rem;

      &:hover {
        color: var(--colors-white);
        left: 4rem;
      }
    }
  }

  &.expanded {
    width: 25rem;
    min-width: 25rem;
  }

  .expander {
    color: var(--colors-blue-90);
    position: absolute;
    width: 6rem;
    height: 6rem;
    padding-right: 5px;
    border-radius: 50%;
    background: var(--colors-blue-90);
    left: 19rem;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    transition: left 200ms ease;
    z-index: -1;

    &:hover {
      left: 22rem;
      cursor: pointer;
    }
  }

  &:hover {
    .expander {
      left: 22rem;
      color: var(--colors-white);
    }
  }

  //Animations classes
  &.animated {
    &.enter {
      width: 7rem;
      min-width: 7rem;
    }
    &.enterActive {
      width: 25rem;
      min-width: 25rem;
      transition: all 150ms ease-out;

      .expander {
        display: none;
        left: 0;
      }
    }
    &.enterDone {
      width: 25rem;
      min-width: 25rem;
    }
    &.exit {
      width: 25rem;
      min-width: 25rem;
    }
    &.exitActive {
      width: 7rem;
      min-width: 7rem;
      transition: width 150ms ease-out;

      .expander {
        display: none;
        left: 0;
      }
    }
    &.exitDone {
      width: 7rem;
      min-width: 7rem;
    }
  }

  .filler {
    display: flex;
    flex-direction: column;
    flex: 1;
    background: var(--colors-blue-90);
  }
}
