.root {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  border-bottom: 1px solid var(--colors-neutral-20);
  font-size: 1.75rem;
  color: var(--colors-neutral-50);
  height: 6rem;
  font-weight: 600;

  &.selectable {
    margin-left: 6rem;
  }

  .header {
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: 1.5rem;

    &.flex {
      flex: 1;
      min-width: 40rem;
      padding-left: 1.5rem;
    }

    &:hover {
      color: var(--colors-neutral-100);

      .icon {
        visibility: visible;

        &.show {
          path {
            stroke: var(--colors-neutral-80);
          }
        }
      }
    }

    .icon {
      visibility: hidden;

      &.show {
        visibility: visible;
      }
    }
  }
}

.cursor {
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}
