.referenceKeepOriginal {
  display: flex;
  align-items: center;
}

.convert_container {
  margin: 1rem 0 4rem 0;
  display: flex;
  align-items: center;
}
