.section {
  height: 7rem;
  width: 7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: solid 1px var(--colors-neutral-20);

  &:last-child {
    border-right: 0;
  }
  &:hover {
    border: 0;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1), inset 0 0 0 1px var(--colors-neutral-40);

    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }
}

.popover {
  border-radius: 0.5rem;
  padding: 1rem;
  box-shadow: inset 0px 0px 0px 1px var(--colors-neutral-20);
  max-height: 19rem;
  overflow: auto;
  background-color: var(--colors-white);

  .label {
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 1rem;
  }

  .grid {
    display: grid;
    grid-template-columns: 8rem 8rem 8rem 8rem;
    grid-auto-rows: 8rem;
    grid-gap: 1rem 1rem;
  }

  .char_grid {
    display: grid;
    grid-template-columns: 4rem 4rem 4rem 4rem 4rem 4rem;
    grid-auto-rows: 4rem;
    grid-gap: 1rem 1rem;
  }
}
