.root {
  display: flex;
  flex-direction: column;
  flex: 1;

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 6rem;
    padding: 2rem;

    &.header {
      font-weight: 500;

      background-color: var(--colors-blue-10);
      border-radius: 0.5rem;
    }

    .quantity {
      color: var(--colors-neutral-60);
    }
  }
}
