.label {
  margin: 1rem 0;
  line-height: 3rem;
  font-size: 1.75rem;
}

.description {
  height: 4rem;
  padding: 1rem 0;
  font-size: 1.5rem;
  color: var(--colors-neutral-60);
  line-height: 2rem;
}
