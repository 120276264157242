.elementStatusContainer {
  margin-top: 3rem;
  overflow-y: auto;
  height: 100%;
}

.loading {
  margin-top: 15rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .message {
    margin-top: 3rem;
    line-height: 3rem;
    font-size: 1.75rem;
    color: var(--colors-neutral-95);
  }
}
