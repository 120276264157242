.rootEdit {
  padding-right: 1rem;
  margin-right: -1rem;

  overflow: auto;

  .chapterNumber {
    display: flex;
    margin-top: 1rem;
    margin-bottom: 4rem;

    &.chapterNumberEdit {
      margin-bottom: 1.5rem;
    }
    & > * {
      width: 100%;

      &:first-of-type {
        margin-right: 2rem;
      }
    }
  }

  &.editionMode {
    height: auto;
  }
}
