.notes {
  display: flex;
  flex-direction: column;

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 6rem;
    font-size: 2rem;
    font-weight: 600;
    border-bottom: 1px solid var(--colors-neutral-20);
    margin-bottom: 1rem;
  }
}

.notesList {
  display: flex;
  overflow: auto;
  flex-direction: column;
  flex: 1;
  padding: 1rem;
  margin: -1rem;

  > * {
    margin-bottom: 1rem;
  }

  .empty_state {
    margin: 0 6rem;
  }
}
