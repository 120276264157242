.popover {
  display: flex;
  flex-direction: row;
  top: 0;
  left: 100%;
  padding: 1rem 0;
  background: var(--colors-white);
  border: 1px solid var(--colors-neutral-100-tint-90);
  box-shadow: 0 0.5rem 6px 0 var(--colors-neutral-100-a-30);
  border-radius: 0.5rem;
  width: max-content;
}
