.root {
  display: flex;
  flex-direction: column;

  &:hover {
    background-color: var(--colors-blue-10);

    .suffix {
      display: block !important;
    }
  }

  &.selected {
    background-color: var(--colors-blue-20);
  }

  &.beingRemoved {
    background-color: var(--colors-red-20);

    &:hover {
      background-color: var(--colors-red-20);
    }
  }

  .row {
    display: grid;
    grid-template-columns: auto auto 1fr;
    align-items: center;

    padding: 1rem 1rem 1rem 2rem;

    .prefix {
      margin-right: 2rem;
    }

    .content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      overflow: hidden;

      padding: 0.5rem 0;

      .value {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .suffix {
      margin-left: auto;

      display: none;
    }
  }
}

.removingRoot {
  display: flex;
  flex-direction: column;

  background-color: var(--colors-red-20);

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;

    padding: 1.5rem 2rem;

    justify-content: space-between;

    .value {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .actions {
      display: flex;
      flex-direction: row;
      gap: 1rem;
    }
  }
}
