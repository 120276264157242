.root {
  display: flex;
  flex-direction: column;
  min-height: 0; /*Needed to constrain the height so it can overflow*/

  .tagsWrapper {
    margin: 2rem;
    margin-bottom: 0;
    min-height: 0; /*Needed to constrain the height so it can overflow*/
  }
}
