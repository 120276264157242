.root {
  display: flex;

  &.disabledInteraction *:not(#skipControl) {
    pointer-events: none !important;
  }

  &.enabledInteraction *:not(#skipControl) {
    pointer-events: all !important;
  }
}
