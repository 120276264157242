.root {
  background: var(--colors-yellow-100);
  border: 1px solid var(--colors-yellow-110);
  border-radius: 0.5rem;
  padding: 0.25rem 1rem;
  font-weight: 600;
  line-height: 2rem;
  font-size: 1.25rem;
  color: var(--colors-neutral-80);
  cursor: pointer;
}
