approved-element {
  position: relative;

  @supports not (-ms-high-contrast: none) {
    /* Non-IE styles here */
    display: flex;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE styles here */
    display: block;
  }

  position: relative;
  cursor: default;

  .content {
    width: auto;
    height: auto;
    display: block;
    outline: 0;

    > * {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -25px;
    width: 12px;
    background-color: #B9ECC4;
    border-radius: 5px;
    z-index: 2;
    border: 2px solid white ;
  }

}

.container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

/* .selectableElement {
  position: absolute;
  display: inline-flex;
  align-items: flex-end;
  top: 0;
  bottom: 0;
  right: 0;

} */

.noSelect {
  user-select: none;
}

.iconWrapper {
  position: absolute;
  width: 40px;
  min-width: 40px;
  max-width: 40px;
  margin-left: -40px;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0;
  align-self: flex-start;
  cursor: default;
}

.contentWrapper {
  flex-grow: 1;
  border-radius: 2px;
  background-color: transparent;
  border: solid 1px #d5d5db;
  display: flex;
  flex-direction: column;
  min-height: 36px;
  cursor: default;
}

.content {
  width: auto;
  height: auto;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  outline: 0;
}

.users {
  padding: 0 20px;
  height: 32px;
  width: auto;
  margin-bottom: 0;
  border-top: solid 1px #d5d5db;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  user-select: none;
}

.userElement {
  margin: 0 4px;
}

.approvedByTextElement {
  margin: 0 4px;
}

.permissionsTextElement {
  margin: 0 4px 0 14px;
}
