.root {
  display: flex;
  flex-direction: row;

  .titleWrapper {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    margin-left: 1rem;

    .title {
      font-size: 1.75rem;
      font-weight: 600;
      line-height: 2.5rem;
      color: var(--colors-neutral-100);

      width: 44rem;
      white-space: 'pre-wrap';
      overflow-wrap: 'break-word';
    }
  }

  .suffixWrapper {
    display: flex;
    flex-direction: column;

    margin-left: auto;

    .timeAgo {
      font-size: 1.5rem;
      line-height: 2.5rem;

      text-align: center;

      color: var(--colors-neutral-60);
    }
  }
}
