.container {
  position: absolute;
  width: 44px;
  height: 44px;
  border-radius: 22px;
  background: var(--colors-white);
  box-shadow: 0px 4px 16px rgba(46, 46, 76, 0.2);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  > * {
    transition: all ease-out 0.3s;
    &:first-child {
      position: absolute;
      left: 6px;
      bottom: 7px;
    }
    &:last-child {
      position: absolute;
      top: 7px;
      right: 6px;
      transform: rotateY(180deg);
    }
  }

  .number {
    visibility: hidden;
    opacity: 0;
    font-weight: 500;
    font-size: 2rem;
    line-height: 3rem;
  }

  &:hover {
    > :first-child,
    > :last-child {
      visibility: hidden;
      opacity: 0;
    }
    .number {
      visibility: visible;
      opacity: 1;
    }
  }
}
