.title {
  display: flex;
  flex-direction: row;
  flex: 1;

  .order {
    color: var(--colors-blue-100);
    font-size: 12px;
    font-weight: 600;
    line-height: 2rem;
  }

  .label {
    color: var(--colors-neutral-100);
    font-size: 12px;
    font-weight: 500;
    line-height: 2rem;
    margin-left: 1rem;
  }
}
