.container {
  position: relative;

  .upload {
    cursor: pointer;
    opacity: 0;
    position: absolute;
    height: 0;
    width: 0;
    top: 0;
    left: 0;
  }
}
