.root {
  .body {
    width: 100%;
    height: 30rem;

    .empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 4rem;
    }

    .loading {
      display: flex;
      width: 100%;
      justify-content: center;
      padding-top: 7rem;
    }
  }
}
