.row {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 3rem;
  font-size: 1.75rem;
  flex: 1;

  .inlineToggleContainer {
    display: flex;
    align-items: center;
    height: 100%;
    color: var(--colors-neutral-50);
  }
}
