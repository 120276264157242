.root {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 2rem 0;

  .quantityIndicator {
    font-size: 1.5rem;
    line-height: 2rem;
    color: var(--colors-neutral-60);
  }
}
