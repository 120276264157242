.tabs {
  display: flex;
  margin-bottom: 2rem;
  border-bottom: 1px solid var(--colors-neutral-20);

  padding: 1.5rem 2rem 0;

  & > * {
    flex: 1;
  }
}
