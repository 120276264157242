.description {
  line-height: 3rem;
}

.group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 3rem;

  font-size: 1.5rem;
  line-height: 2rem;

  .title {
    font-size: 1.75rem;
    font-weight: 500;
    line-height: 3rem;
  }
}
