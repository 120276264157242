format-element {
  display: inline;
}

format-element[backgroundcolor] {
  comment-element {
    background: #ffedba;
  }

  comment-element[selected] {
    background-color: #ffe07c;
  }

  track-ins-element[selected] {
    background-color: inherit;
  }

  track-del-element[selected] {
    background-color: inherit;
  }
}

format-element[bold='true'] {
  font-weight: bold;
}

format-element[bold='false'] {
  font-weight: normal;
}

format-element[italic='true'] {
  font-style: italic;
}

format-element[italic='false'] {
  font-style: normal;
}

format-element[superscript='true'] {
  vertical-align: super;
  font-size: 63% !important;
}

format-element[superscript='false'] {
  vertical-align: auto;
}

format-element[subscript='true'] {
  vertical-align: sub;
  font-size: 63% !important;
}

format-element[subscript='false'] {
  vertical-align: auto;
}
