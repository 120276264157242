.setting {
  display: flex;
  align-items: flex-start;
  padding: 2rem 0;
  border-bottom: 1px solid var(--colors-neutral-10);

  .label {
    color: var(--colors-neutral-100);
    font-size: 14px;
    min-width: 36rem;
    padding-left: 4rem;
  }

  .value {
    width: 40rem;
    font-size: 14px;
    color: var(--colors-neutral-50);
  }
}
