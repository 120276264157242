.root {
  overflow: auto;
  flex: 1;
  margin: 0 -2rem;
  padding: 0 2rem;

  .emptyView {
    display: flex;
    justify-content: center;
    margin: 3rem 3rem 0 3rem;
  }
}

.referenceStyleLabel {
  padding: 1.5rem 0;

  line-height: 2rem;
  font-size: 1.5rem;

  color: var(--colors-neutral-100);
}

.filters {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  border-bottom: solid 1px var(--colors-neutral-20);
  border-top: solid 1px var(--colors-neutral-20);

  .fixedBox {
    display: flex;
    cursor: pointer;
    align-items: center;
    height: 4rem;

    &.isOpen {
      margin-bottom: 0.5rem;
    }

    .showFilter {
      margin-left: 1rem;
      font-size: 12px;
      font-weight: 500;
      line-height: 2rem;
      color: var(--colors-neutral-80);
    }
  }

  .filterWrapper {
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin-left: 0.5rem;
  }
}

.infoTasks {
  height: 4rem;
  display: flex;
  align-items: center;
  margin-top: 1rem;

  .infoText {
    font-size: 12px;
    line-height: 2rem;
    color: var(--colors-neutral-60);
  }
}

.card {
  margin-top: 1rem;
}
