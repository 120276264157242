.root {
  display: flex;
  flex-direction: column;

  .title {
    font-size: 1.75rem;
    font-weight: 500;
    color: var(--colors-neutral-70);
    line-height: 3rem;
  }

  .content {
    font-size: 1.75rem;
    font-weight: 500;
    color: var(--colors-neutral-100);
    line-height: 3rem;
    word-break: break-all;

    .empty {
      font-size: 1.75rem;
      font-weight: 500;
      color: var(--colors-neutral-40);
      line-height: 3rem;
    }
  }
}
