.editor {
  flex: 1;
  min-width: 0;

  color: var(--colors-neutral-100);

  &.editCursor {
    cursor: text;
  }

  &.editable {
    background-color: var(--colors-white);

    border: 1px solid var(--colors-neutral-20);
    border-radius: 0.5rem;

    line-height: 2rem;

    .editorInput {
      padding: 2rem;
      word-break: break-word;

      min-height: 9rem !important;

      &.singleLine {
        padding: 0.75rem 0rem;
        margin: auto 2rem;
        word-break: keep-all;

        min-height: 4rem !important;
        white-space: pre !important;
        overflow: hidden;
      }
    }
  }

  &.transparent {
    color: black;
  }

  .placeholder {
    font-weight: normal;
    font-style: normal;
    text-decoration: none;
    line-height: 2.5rem;
  }

  &.readLess {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  .inlineMention {
    color: var(--colors-blue-100);
  }
}

.stretched {
  height: 100%;
}

.breakAll {
  word-break: break-word;
}

.paragraph {
  margin: 0;
}
