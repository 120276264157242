.switchContainer {
  width: 40rem;
  height: 4rem;
  margin-bottom: 2rem;
}

.descendants {
  padding: 1rem;
  border-radius: 0.5rem;
  border: 1px solid var(--colors-blue-20);
  background: var(--colors-blue-10);
  margin-bottom: 2rem;

  &.active {
    background: var(--colors-yellow-60);
    border: 1px solid var(--colors-yellow-110);
  }
}

.activeView {
  color: var(--colors-neutral-80);
  font-weight: 600;
  background: var(--colors-blue-10);
  border: 1px solid var(--colors-neutral-40);
  box-shadow: inset 0 1px 0 0 var(--colors-white);
}

.shareContainer {
  display: flex;
  margin-bottom: 2rem;
}

.collaboratorsHeader {
  font-size: 1.75rem;
  font-weight: 600;
  color: var(--colors-neutral-100);
  line-height: 3rem;
  margin-bottom: 2rem;
}
