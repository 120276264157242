.root {
  display: flex;
  color: var(--colors-neutral-60);
  font-weight: 400;
  align-items: center;
  font-size: 1.5rem;
  height: 6rem;
  border-top: 1px solid var(--colors-neutral-20);
  line-height: 2rem;
}

.selectedItems {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.filter {
  flex: 1;
  display: flex;
  align-items: center;
}
