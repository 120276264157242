.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 4rem;

  .message {
    padding: 2rem 0;
    font-weight: 600;
    text-align: center;

    .link {
      color: var(--colors-blue-100);
      cursor: pointer;
    }
  }
}
