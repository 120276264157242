.slide {
  background-color: white;
  position: relative;
  margin: 0 auto;

  &.thumbnailSlide {
    /**
     * Set as a block element so that the thumbail container of the slide
     * can fit with the slide dimensions.
     * I believe this doesnt have to be only for thumbnail slides
     * but for the bugfix i will only leave for thumbnail
     */
    display: block;
  }
}
