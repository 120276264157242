track-ins-element {
  --marginSize: 0px;

  width: auto;
  background-color: transparent;
  margin: 0;

  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  vertical-align: inherit;

  &[replacewith]:empty:after,
  &[replacewithsibling]:empty:after {
    content: '\00B6';
  }

  > track-del-element {
    color: inherit !important;
  }

  & > figure-element {
    max-width: max-content;
  }

  &[lock] {
    box-shadow: inset var(--colors-neutral-5) var(--contentWidth) 0,
      0 0 0 1px var(--colors-neutral-20);
  }
}

track-ins-element[selected] {
  background-color: transparent;
}

track-ins-element[breakline='true']::before {
  content: '\21B5';
}
