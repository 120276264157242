.dropzone {
  width: 416px;
  height: 200px;
  border-radius: 1rem;
  display: flex;
  border: dashed 2px var(--colors-neutral-20);
  align-items: center;
  flex-direction: column;
  background-color: var(--colors-neutral-5);
  box-shadow: 0 0 0 5px var(--colors-white), inset 0 0 0 7px var(--colors-white);
}

.text {
  width: 226px;
  height: 38px;
  opacity: 0.5;
  font-size: 14px;
  color: var(--colors-neutral-80);
  text-align: center;
  padding-top: 1.5rem;
}

.checkbox {
  padding-top: 2rem;
}

.checkboxText {
  font-size: 14px;
  line-height: 1.71;
  color: var(--colors-neutral-80);
  padding-left: 0.5rem;
  padding-top: 0.5rem;
}

.warning {
  width: 416px;
  height: 292px;
  border-radius: 8px;
  background-color: var(--colors-yellow-40);
  box-shadow: inset 0 0 0 7px var(--colors-white);
  border: solid 2px var(--colors-yellow-100);
  justify-content: center;
  flex-direction: column;
  display: flex;
  align-items: center;
}

.name {
  width: 70px;
  height: 19px;
  font-size: 14px;
  font-weight: 600;
  color: var(--colors-neutral-80);
}

.error {
  width: 372px;
  height: auto;
  border-radius: 4px;
  background-color: var(--colors-yellow-60);
  border: solid 1px var(--colors-yellow-110);
  font-size: 14px;
  color: var(--colors-neutral-80);
  padding: 1.5rem 2rem;
  margin: 0.5rem 1rem;
}

.clearButton {
  cursor: pointer;
  position: absolute;
  top: 5rem;
  right: 5rem;
}

.clean {
  width: 416px;
  height: 176px;
  border-radius: 1rem;
  box-shadow: inset 0 0 0 7px var(--colors-white);
  background-color: var(--colors-blue-10);
  border: solid 2px var(--colors-blue-50);
  justify-content: center;
  flex-direction: column;
  display: flex;
  align-items: center;
}

.comment {
  margin-top: 3rem;
  margin-bottom: 2rem;
  font-size: 1.75rem;
  color: rgb(88, 88, 112);
  font-weight: 600;
}

.progress {
  width: 158px;
  height: 16px;
  background: var(--colors-white);
  border-radius: 100px;
  border: 1px solid rgba(0, 27, 68, 0.1);
  overflow: hidden;

  .bar {
    width: 50px;
    transition: width 0.5s ease-in-out;
    background: rgba(19, 95, 187, 0.8);
    box-shadow: inset 0 0 0 0 var(--colors-neutral-10);
    height: 12px;
    margin-top: 0.1rem;
    margin-left: 0.5rem;
    border-radius: 8px;
  }
}

.info {
  display: flex;
  height: 19px;
  font-size: 14px;
  font-weight: 600;
  padding: 0rem 14rem;
}

.link {
  display: block;
  color: var(--colors-blue-100);
  cursor: pointer;
  font-size: 1.5rem;
  margin-top: 0.5rem;
  text-align: center;
}

.append {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.75rem;
  color: var(--colors-neutral-50);
  margin-top: 1rem;
}

.value {
  flex: 1;
  margin-right: 1rem;
  max-width: 90px;
  height: 19px;
  display: inline-block;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 600;
  color: var(--colors-neutral-80);
  margin-top: 1rem;
}

.input {
  width: 156px;
  height: 32px;
  border-radius: 4px;
  background-color: var(--colors-white);
  border: solid 1px var(--colors-neutral-20);
  padding: 0.5rem;
}

.loading {
  display: flex;
  justify-content: center;
  flex-direction: column;

  .loadingIcon {
    display: flex;
    justify-content: center;
  }
  .loadingIndicator {
    margin-top: 6rem;
    display: flex;
    justify-content: center;
  }

  .loadingText {
    margin-top: 3rem;
    text-align: center;
  }
}
