.annotationType {
  display: flex;
  align-items: center;
  gap: 1rem;

  font-size: 1.5rem;
  font-weight: 400;
  color: var(--colors-neutral-60);
  line-height: 2rem;

  &.notification {
    color: var(--colors-neutral-70);
  }
}
