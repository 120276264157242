.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8rem 10rem 5.5rem 10rem;
  background: var(--colors-red-5);
  border: 2px solid var(--colors-red-70);
  border-radius: 1rem;

  .message {
    text-align: center;
    font-size: 1.75rem;
    font-weight: 500;
    line-height: 3rem;
  }

  .button {
    width: 100%;
    padding: 0 3.5rem 0 3.5rem;
  }
}

.confirmation {
  line-height: 3rem;
}
