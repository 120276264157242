.root {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2000;
  align-content: center;
  align-items: center;
  justify-content: center;
  display: flex;
  overflow: hidden;

  .spinner {
    background: none;
    position: relative;
    width: 200px;
    height: 200px;
    transform: scale(0.6);

    div {
      width: 24px;
      height: 24px;
      margin-left: 4px;
      margin-top: 4px;
      position: absolute;

      div {
        width: 100%;
        height: 100%;
        border-radius: 15px;
        background: white;
      }
    }
  }
}

.spinner > div:nth-of-type(1) > div {
  animation: spin-anim 1s linear infinite;
  animation-delay: 0s;
}

.spinner > div:nth-of-type(1) {
  transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
}

.spinner > div:nth-of-type(2) > div {
  animation: spin-anim 1s linear infinite;
  animation-delay: 0.12s;
}

.spinner > div:nth-of-type(2) {
  transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
}

.spinner > div:nth-of-type(3) > div {
  animation: spin-anim 1s linear infinite;
  animation-delay: 0.25s;
}

.spinner > div:nth-of-type(3) {
  transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
}

.spinner > div:nth-of-type(4) > div {
  animation: spin-anim 1s linear infinite;
  animation-delay: 0.37s;
}

.spinner > div:nth-of-type(4) {
  transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
}

.spinner > div:nth-of-type(5) > div {
  animation: spin-anim 1s linear infinite;
  animation-delay: 0.5s;
}

.spinner > div:nth-of-type(5) {
  transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
}

.spinner > div:nth-of-type(6) > div {
  animation: spin-anim 1s linear infinite;
  animation-delay: 0.62s;
}

.spinner > div:nth-of-type(6) {
  transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
}

.spinner > div:nth-of-type(7) > div {
  animation: spin-anim 1s linear infinite;
  animation-delay: 0.75s;
}

.spinner > div:nth-of-type(7) {
  transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
}

.spinner > div:nth-of-type(8) > div {
  animation: spin-anim 1s linear infinite;
  animation-delay: 0.87s;
}

.spinner > div:nth-of-type(8) {
  transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
}

@keyframes spin-anim {
  0% {
    opacity: 1;
    transform: scale(1.5);
  }

  100% {
    opacity: 0.1;
    transform: scale(1);
  }
}
