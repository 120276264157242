.root {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 1.5rem;

  &.white {
    color: var(--colors-dark-blue-20);
  }

  &.hero {
    font-size: 1.25rem;
    line-height: 2rem;
    font-weight: 500;
    color: var(--colors-blue-50);
  }
}
