.container {
  + .container {
    margin-top: 1.5rem;
  }
  .option {
    display: flex;
    align-items: center;

    .fields {
      display: flex;
      align-items: center;
      margin-right: 1rem;
      border-radius: 0.5rem;
      background-color: #efeff1;
      height: 7rem;
      padding-left: 1rem;
      flex: 1;
      box-shadow: inset 0 0 0 1px var(--colors-neutral-20);
      gap: 1rem;

      &.deleting {
        background: var(--colors-red-30);
        padding-left: 2rem;

        > div:first-child {
          font-size: 1.5rem;
          line-height: 2rem;
          width: 67rem;
        }
      }

      .field {
        flex: 1;
      }

      .toggle {
        background: white;
        padding: 1.5rem 1rem;
        box-shadow: inset 0 0 0 1px var(--colors-neutral-20);
        border-radius: 0 0.5rem 0.5rem 0;
      }
    }
  }

  .extra {
    margin-top: 2rem;
    border-radius: 0.5rem;
    background: var(--colors-neutral-2);
    box-shadow: inset 0 0 0 1px var(--colors-neutral-20);
    padding: 2rem 3rem;
    margin-right: 12rem;

    .information {
      display: flex;
      gap: 2rem;
    }
  }
}

.autoSaveRoot {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;

  margin-right: auto;
}

.searchableCheckbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;

  margin-top: 1rem;

  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2rem;

  color: var(--colors-neutral-40);
}

.exportToVeevaCheckbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;

  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    margin: 1rem 0 0;

    font-size: 1.5rem;
    font-weight: 500;
    line-height: 2rem;
    color: var(--colors-neutral-40);
  }
}
