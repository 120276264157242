.root {
  display: flex;
  height: 4rem;
  overflow: hidden;

  &.online {
    height: 4.2rem;
  }

  &.large {
    height: 5rem;

    &.online {
      height: 5.2rem;
    }
  }

  .avatar {
    margin-right: 1.5rem;
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 2rem;
    text-align: left;
    overflow: hidden;

    &.large {
      height: 4.5rem;
      padding-top: 0.4rem;
    }

    .name {
      font-size: 1.75rem;
      line-height: 2rem;
    }

    .username {
      color: var(--colors-neutral-60);
      line-height: 2rem;
      font-size: 1.5rem;
    }

    .ellipsis {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .ellipsis {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
