.root {
  padding: 0.25rem 0.75rem;

  border-radius: 1rem;

  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2rem;

  background: var(--colors-blue-10);
  color: var(--colors-blue-100);

  &.activeUser {
    background: var(--colors-blue-90);
    color: var(--colors-blue-5);
  }

  &.envision {
    border-radius: 0.75rem;
  }
}
