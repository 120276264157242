.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .text {
    font-size: 3rem;
    font-weight: 600;
    line-height: 4rem;
  }

  p {
    color: var(--colors-neutral-70);
    font-size: 1.75rem;
    margin: 0 auto;
    line-height: 3rem;
  }

  form {
    width: 300px;
    margin: 0 auto;
  }
}

.logoContainer {
  margin-bottom: 6rem;
}
