.rootBlock {
  margin-bottom: 4rem;

  &.editionMode {
    &:last-of-type {
      margin-bottom: unset;
    }
  }
}

.title {
  font-weight: 500;
  line-height: 3rem;
  color: var(--colors-neutral-100);
  margin-bottom: 1rem;
}
