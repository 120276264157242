table-of-labels-element {
  display: block;
  outline: none;
  width: calc(var(--contentWidth));

  &:focus,
  &:focus-within,
  &:visited,
  &:active,
  &:hover {
    outline: none;
  }

  &[selected='true'],
  &:hover {
    box-shadow: inset var(--contentWidth) 0px 0px var(--colors-blue-30),
      -0.5rem 0 0 var(--colors-blue-30), 0.5rem 0 0 var(--colors-blue-30);
    background-color: var(--colors-blue-30);
  }

  &[lock] {
    box-shadow: inset var(--colors-neutral-5) var(--contentWidth) 0,
      0 0 0 1px var(--colors-neutral-20);
  }

  > paragraph-element {
    --rightMargin: 0px !important;
  }

  hyperlink-element {
    color: inherit;
  }

  field-element[data-type='cr'][data-format='pn'] {
    color: inherit;
  }
}
