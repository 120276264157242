.root {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;

  .title {
    margin-top: 9rem;
    margin-bottom: 2rem;
    font-weight: 600;
    font-size: 6rem;
    line-height: 9rem;
    color: var(--colors-blue-5);
  }

  .description {
    width: 51rem;
    font-weight: 500;
    font-size: 2.25rem;
    line-height: 3rem;
    color: var(--colors-blue-20);
    margin-bottom: auto;
  }

  .onBoardingRoot {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 12rem;
    margin-bottom: 6rem;
    background-color: var(--colors-white);
    overflow: auto;

    .title {
      font-weight: 600;
      font-size: 4rem;
      line-height: 4rem;
      color: var(--colors-neutral-100);
      margin-bottom: 6.5rem;
    }

    .form {
      width: 45rem;
      margin-bottom: 2.5rem;

      .name {
        display: flex;

        .inputs {
          flex: 1;
        }
      }

      .password {
        width: 45rem;
      }
    }

    .footer {
      width: 45rem;
      height: 4rem;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}
