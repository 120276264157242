.text {
  color: var(--colors-neutral-100);
  font-size: 1.5rem;
  word-break: break-word;
  white-space: pre-line;
  line-height: 2rem;

  &.readLess {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
