.title {
  font-size: 1.75rem;
  font-weight: 500;
  line-height: 3rem;
  color: var(--colors-neutral-100);
}

.list {
  margin-top: 2rem;
  padding-left: 4rem;
  max-height: 15rem;
  overflow: auto;

  .item {
    font-size: 1.5rem;
    line-height: 2rem;
    padding-bottom: 1rem;
  }
}

.text {
  margin: 1rem 0 3rem 0;
  color: var(--colors-neutral-80);
}
