.root {
  width: 35rem;
  min-width: 35rem;
  margin-left: 5rem;

  .content {
    display: flex;
    flex-direction: column;
    height: 100%;

    .body {
      overflow: auto;
    }
  }

  .emptyState {
    margin-top: 9rem;
    overflow: auto;
  }
}
