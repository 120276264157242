.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: var(--colors-neutral-10);
  box-shadow: inset 1px -1px 0px 0px var(--colors-neutral-20);
  height: 8rem;
  padding: 2.5rem;

  & > span {
    font-size: 2rem;
    font-weight: 600;
    color: var(--colors-neutral-95);
    line-height: 3rem;
    text-transform: capitalize;
  }
}
