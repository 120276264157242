.panelheading {
  &:not(.editor) {
    height: 8rem;
    padding: 2rem;
    font-size: 2rem;
    line-height: 4rem;
    font-weight: 600;
    color: var(--colors-neutral-95);
    background-color: var(--colors-neutral-10);
    box-shadow: inset 0 -1px 0 0 var(--colors-neutral-20);
    display: flex;
    justify-content: space-between;
  }

  &.editor {
    height: 6rem;
    color: var(--colors-white);
    font-weight: 600;
    font-size: 1.75rem;
    line-height: 6rem;
    text-align: center;
    background: var(--colors-blue-90);
    border: var(--colors-blue-110);
  }
}
