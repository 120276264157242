.container {
  margin-bottom: 2rem;
  padding: 0 2rem;

  overflow: auto;

  &.setting {
    background: var(--colors-white);
    margin-top: 3rem;
    padding: 0;
  }

  .metadataGroup {
    margin-top: 0.5rem;

    &:not(:first-child) {
      margin-top: 3rem;
    }
  }

  .manualContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .info {
    display: flex;
    justify-content: space-between;
  }

  .text {
    font-size: 1.5rem;
    font-weight: 500;
    color: var(--colors-neutral-100);
    line-height: 2rem;
  }

  .labels {
    font-size: 1.5rem;
    line-height: 2rem;
    color: var(--colors-neutral-40);
  }

  .values {
    font-size: 1.5rem;
    line-height: 2rem;
    color: var(--colors-neutral-100);
    margin-left: 4rem;
  }

  .row {
    line-height: 2rem;
    padding: 0 0 2rem 0;
  }

  .rowValue {
    line-height: 2rem;
    padding: 0 0 2rem 0;
    max-width: 15rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.metadataItemContainer {
  display: flex;
  padding: 0 0 2rem 0;
  justify-content: space-between;
  .labelContainer {
    display: flex;
    font-size: 1.5rem;
    line-height: 2rem;
    color: var(--colors-neutral-40);
    max-width: 13.5rem;
  }

  .valueContainer {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.5rem;
    line-height: 2rem;
    color: var(--colors-neutral-100);
    max-width: 15rem;
    flex: 1;

    &.noValue {
      color: var(--colors-neutral-60);
    }
  }
}

.header {
  height: 8rem;
  background: var(--colors-dark-blue-5);
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--colors-neutral-100);
  font-size: 18px;
  font-weight: 600;
  padding: 0 2rem;
}

.loading {
  margin-top: 8rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .message {
    margin-top: 2rem;
    color: var(--colors-neutral-100);
    font-size: 1.75rem;
    line-height: 3rem;
    font-weight: 500;
  }
}
