.buttons {
  display: flex;
  flex-direction: row;
}

.suggestions {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
  margin-bottom: 4rem;

  .errorMessage {
    font-size: 1.5rem;
    line-height: 2rem;

    color: var(--colors-neutral-40);
  }
}

.no_suggestions {
  font-size: 1.5rem;
  line-height: 2rem;
  color: var(--colors-neutral-40);
  margin: 2rem 0 4rem;
  word-break: break-word;
}

.untrackedNote {
  font-size: 1.5rem;
  line-height: 2rem;
  color: var(--colors-neutral-40);

  margin-top: 3.5rem;
}
