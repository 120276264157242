.root {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  -ms-content-zooming: none;

  .iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    border: none;
    display: block;
  }
}
