equation-element {
  display: inline !important;
  vertical-align: middle;

  .container {
    user-select: none;
    display: inline;
    font-size: inherit;

    > * {
      user-select: none;
      display: inline-block !important;
      padding: 0.5rem;
      border-radius: 0.25rem;
      margin: 0 !important;
    }
  }

  &[selected='true'] {
    .container {
      * {
        background-color: var(--colors-blue-20);
      }
    }
  }

  .errorContainer {
    user-select: none;
    display: inline-block !important;
    vertical-align: middle;
    width: 238px;
    height: 6rem;
    position: relative;
    box-shadow: inset 0px 0px 0px 1px var(--colors-yellow-60);
    background-color: var(--colors-yellow-20);
    border-radius: 2px;
  }

  .wrapper {
    display: flex;
    flex-direction: flex-row;
    height: 100%;
    align-items: center;
    padding: 0 2rem;
  }

  .iconWrapper {
    width: 40px;
    min-width: 40px;
    max-width: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: default;
  }

  .contentWrapper {
    justify-content: center;
    align-items: center;
    color: var(--colors-neutral-50);
    outline: 0;
  }

  .info {
    color: var(--colors-neutral-80);
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: center;
  }

  *::selection {
    background-color: var(--colors-dark-blue-5);
  }
}
