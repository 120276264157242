.root {
  margin-left: 6rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--colors-neutral-20);
  background: var(--colors-neutral-10);
  height: 6rem;

  .icon {
    padding-left: 1.5rem;
    margin-right: 2rem;
  }
}
