citations-group-element {
  display: inline;

  /* &::after {
    content: attr(data-content) ']';
  }

  &::before {
    content: attr(data-content) '[';
  } */

  /* & > track-ins-element:not(:first-of-type),
  & > track-del-element:not(:first-of-type),
  & > citation-element:not(:first-of-type) {
    &::before {
      content: ',';
    }
  } */
}

citations-group-element[superscript='true'] {
  vertical-align: super;
  font-size: 63% !important;
}
