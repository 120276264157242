.labelContainer {
  display: flex;

  .label {
    color: var(--colors-neutral-70);
    font-size: 1.5rem;
    line-height: 2rem;
    width: 19rem;
    font-weight: 400;

    &.disabled {
      color: var(--colors-neutral-20);
    }
  }
}
