.uploading {
  padding: 6rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60rem;
  border-radius: 1rem;
  box-shadow: 0 2px 6px 0 var(--colors-neutral-10), inset 0 1px 3px 0 rgba(var(--colors-black), 0.5);
  border: solid 2px var(--colors-blue-50);
  background-color: var(--colors-blue-5);
  font-size: 1.75rem;
  line-height: 3rem;
  color: var(--colors-neutral-100);
}

.buttons {
  display: flex;
  justify-content: flex-end;
}
