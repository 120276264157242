.root {
  display: flex;
  gap: 1rem;
  margin-right: 1rem;
  justify-content: flex-end;

  .button {
    width: 9rem;
  }
}
