.accounts {
  max-height: 31rem;
  overflow-y: auto;
  margin-bottom: 3rem;

  .savedAccount {
    + .savedAccount {
      margin-top: 1rem;
    }
  }
}
.remove-account {
  margin: 2rem 0;
  text-align: center;
}
