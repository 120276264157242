.container {
  display: flex;
  margin-top: 5rem;

  .templateContainer {
    width: 18rem;
    height: 20rem;
    text-align: center;
    margin-right: 1rem;

    .img {
      object-fit: scale-down;
      max-width: 15rem;
      max-height: 20rem;
      width: auto;
      height: 100%;
      border: 1px solid var(--colors-neutral-30);
    }
  }

  .infoContainer {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
    width: 61rem;

    .name {
      font-size: 2rem;
      font-weight: 600;
      line-height: 3rem;
      margin-bottom: 1rem;
    }

    .descriptionContainer {
      font-size: 1.5rem;
      line-height: 2rem;
      color: var(--colors-neutral-30);
      max-height: 14rem;
      overflow: auto;

      &.description {
        color: var(--colors-neutral-80);
      }
    }
  }

  .readMore {
    cursor: pointer;
    font-size: 1.5rem;
    line-height: 2rem;
    color: var(--colors-blue-70);
    margin: 1rem 0;
  }
}
