.root {
  width: 60rem;
  max-height: 80rem;
  overflow-y: hidden;

  .header {
    display: flex;
    align-items: center;
    padding: 1.5rem 2rem;

    .title {
      color: var(--colors-neutral-100);
      font-size: 2rem;
      font-weight: 600;
      line-height: 3rem;
    }

    .actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .actionsButtons {
        display: flex;
        align-items: center;
      }
    }
  }

  .listHeader {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .list {
    height: 80rem;
    max-height: 80rem;

    .section {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .sectionLabel {
        margin-left: 2rem;
        margin-bottom: 0.5rem;

        font-size: 1.5rem;
        font-weight: 600;
        line-height: 2rem;
        letter-spacing: 1px;
        text-transform: uppercase;

        color: var(--colors-neutral-30);
      }
    }
    :global(.ReactVirtualized__Grid),
    :global(.ReactVirtualized__Grid__innerScrollContainer) {
      position: inherit !important;
    }

    .emptyState {
      display: flex;
      justify-content: center;

      padding-top: 10rem;
    }
  }
}
