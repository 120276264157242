.container {
  grid-area: content;
}

.root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--colors-white);
  overflow-y: auto;

  &.canvas {
    background-color: var(--colors-white);
  }

  &.readOnlyMode {
    background: var(--colors-neutral-10);
  }

  &.pageLayout {
    background-color: var(--colors-neutral-10);
  }

  .inputRoot {
    position: relative;
    width: 102rem;
    margin: 2rem 0;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
    align-self: center;
    height: auto;

    @supports not (-ms-high-contrast: none) {
      /* Non-IE styles here */
      flex: 1;
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      /* IE styles here */
      flex-grow: 1;
    }

    &.canvas {
      width: 100%;
      align-self: unset;
      box-shadow: 0 0 0 0;
      margin: 0;
    }
  }
}

.document {
  width: 100%;
  height: 100%;
  z-index: 1;
  position: absolute;
}
