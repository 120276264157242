.item {
  box-shadow: inset 0px -1px 0px 0px var(--colors-neutral-20);
  height: 5rem;
  padding: 1.5rem 0 1.5rem 2rem;
  font-size: 1.5rem;
  line-height: 2rem;
  display: flex;
  justify-content: space-between;

  .itemLabel {
    white-space: nowrap;
    max-width: 18rem;
  }

  .itemValue {
    color: var(--colors-neutral-50);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 1rem;
    max-width: 21.5rem;
  }
}
