.popoverContainer {
  border-radius: 2px;
  box-shadow: 0 3px 6px 0 rgba(213, 213, 219, 0.75);
  border: solid 1px var(--colors-neutral-20);
}

.root {
  padding: 1rem;

  .label {
    font-size: 12px;
    color: var(--colors-neutral-100);
  }

  .noColor {
    display: flex;
    align-items: center;
    padding: 1rem 0.75rem;
    cursor: pointer;
    border: solid 1px var(--colors-white);

    &:hover {
      border-radius: 3px;
      border: solid 1px var(--colors-neutral-10);
      background-color: var(--colors-neutral-5);

      .square {
        background-color: var(--colors-white);
      }
    }

    .square {
      height: 3rem;
      width: 3rem;
      border: solid 1px var(--colors-neutral-40);
      margin-right: 2rem;
      position: relative;
    }

    .square:after {
      content: '';
      position: absolute;
      border-top: 1px solid var(--colors-red-90);
      width: 32px;
      transform: rotate(135deg);
      transform-origin: 0% 0%;
      left: 23px;
    }
  }

  .blacks {
    display: grid;
    column-gap: 0.5rem;
    grid-template-columns: repeat(10, 3rem);
    margin-bottom: 1rem;
    margin-top: 1rem;
    padding: 0 0.75rem;

    .square {
      border-color: rgba(151, 151, 151, 0.5);
    }
  }

  .documentColors {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  .colors {
    display: grid;
    column-gap: 0.5rem;
    grid-template-columns: repeat(10, 3rem);
    padding: 0 0.75rem;

    .color {
      .colorWrapper {
        margin-top: -1px;
        &.selected,
        &:hover {
          margin-top: -2px;
          margin-bottom: -2px;
          + *.selected,
          + *:hover {
            margin-top: -3px;
          }
        }
        .square {
          border-color: rgba(0, 27, 68, 0.2);
        }
      }
    }
  }

  .colorWrapper {
    background: var(--colors-white);
    z-index: 0;
    position: relative;
    .square {
      height: 3rem;
      width: 3rem;
      border-width: 1px;
      border-style: solid;
    }

    &.selected,
    &:hover {
      z-index: 2;
      cursor: pointer;
      height: 26px;
      width: 26px;
      padding: 1px;
      margin: -1px;
      box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.5), inset 0 0 0 0 rgba(0, 0, 0, 0.2);
      + *.selected {
        z-index: 1;
      }
    }
  }

  .customColor {
    padding: 0.75rem;
    border: solid 1px var(--colors-white);
    cursor: pointer;

    &:hover {
      border-radius: 3px;
      border: solid 1px var(--colors-neutral-10);
      background-color: var(--colors-neutral-5);
    }
  }
}
