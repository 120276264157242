.body {
  display: flex;
  height: 56rem;

  .header {
    margin-bottom: 1rem;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    line-height: 3rem;
    width: 25rem;
    cursor: pointer;

    &:hover {
      background: var(--colors-blue-5);
    }

    &.active {
      background: var(--colors-blue-20);
    }
  }

  .empty_list {
    margin: 8rem 10.5rem 0;
    text-align: center;

    .title {
      font-weight: 500;
      line-height: 3rem;
      margin-bottom: 1rem;
      font-size: 1.75rem;
    }

    .description {
      color: var(--colors-neutral-70);
      line-height: 2rem;
      font-size: 1.5rem;
    }
  }

  .list {
    flex: 1;
    overflow: auto;
    padding-right: 1rem;

    .table {
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    .group {
      padding: 1.5rem 1rem;
      background: var(--colors-blue-5);
      border-radius: 0.5rem;
      font-size: 1.5rem;
      line-height: 2rem;
      font-weight: 500;
      margin-bottom: 2rem;
      position: sticky;
      top: 0;
      z-index: 2;
    }
  }
}
