.root {
  grid-area: sidebar;
  display: flex;

  .toggles {
    display: flex;
    flex-direction: column;
    background: var(--colors-neutral-2);
    width: 6rem;
    box-shadow: inset 1px 0 0 0 var(--colors-neutral-20);
    padding: 1rem;
    overflow: auto;
    height: 100%;

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.dropdownToggle {
  position: relative;
  width: 100%;
  background: var(--colors-white);
  font-weight: 600;
  color: var(--colors-neutral-80);
  font-size: 1.5rem;
  height: 5rem;
  display: flex;
  align-items: center;
  padding-left: 2.5rem;
  padding-right: 4rem;
  border: 0;
  border-bottom: 1px solid var(--colors-neutral-40);
  border-radius: 0;
  box-shadow: 0 0 0 0;

  .caret {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 2rem;
    height: 100%;
  }
}

.dropdownMenu {
  width: 100%;
  transform: none !important;
  top: 100% !important;
  border-radius: 0;
}

.dropdownItem {
  display: block;
  width: 100%;
  background: var(--colors-white);
  font-weight: 600;
  color: var(--colors-neutral-80);
  font-size: 1.5rem;
  height: 5rem;
  display: flex;
  align-items: center;
  padding-left: 2.5rem;
  border: 0;
  box-shadow: 0 0 0 0;

  &:hover {
    background: var(--colors-blue-20);
  }
}
