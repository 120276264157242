//Styling: https://github.com/vkbansal/react-contextmenu/blob/master/docs/usage.md
:global(.react-contextmenu-submenu) {
  display: flex;
  align-items: center;
  padding-left: 6rem;
  padding-right: 1rem;
  height: 4rem;
  cursor: pointer;
  position: relative;
  color: var(--colors-neutral-100);
  outline: none;

  &:hover:not(.disabled) {
    background: var(--colors-blue-10);

    .icon {
      opacity: 1;
    }
  }
}

.submenuChildren {
  margin-left: 1rem;
  margin-right: 1rem;
  padding: 1rem 0;
  border-radius: 0.25rem;
  background-color: var(--colors-white);
  border: solid 1px var(--colors-neutral-20);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  width: 30rem;
  height: 100%;
  min-height: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
