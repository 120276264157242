.customColorPicker {
  display: flex;
  flex-direction: column;
  align-items: center;
  .saturation {
    position: relative;
    height: 22.375rem;
    width: 38rem;
    border-radius: 2px;
    border: 1px solid var(--colors-neutral-10);
  }

  .wrapper {
    display: flex;
    margin-top: 2rem;
    margin-bottom: 3rem;

    .material {
      display: flex;
      flex-direction: column;

      .hue {
        height: 2rem;
        width: 30rem;
        position: relative;

        .huePointer {
          width: 1rem;
          height: 2.5rem;
          border-radius: 2px;
          margin: -2px 0;
          border: solid 1px var(--colors-neutral-40);
          background-color: var(--colors-white);
        }
      }
    }
  }

  .checkboard {
    margin-left: 2rem;
    height: 7rem;
    width: 6rem;
    position: relative;
    border-radius: 3px;
    border: solid 1px var(--colors-neutral-10);
  }

  .checkboard div {
    border-radius: 2px;
  }
}
