.header {
  font-size: 1.5rem;
  margin-top: 2rem;
}

.comment {
  margin-top: 1rem;
  background: var(--colors-green-10);
  border: 1px solid var(--colors-green-60);
  border-radius: 0.5rem;
  padding: 2rem;
  font-size: 1.5rem;
  line-height: 2.25rem;
  color: var(--colors-neutral-80);
}
