.redacted {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  color: var(--colors-neutral-60);
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
