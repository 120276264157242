.twister {
  margin-right: 1rem;
  transition: transform 200ms linear;
  transform: rotate(-90deg);
  cursor: pointer;

  &.open {
    transform: rotate(0deg);
  }

  &.hidden {
    visibility: hidden;
  }
}
