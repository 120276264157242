.topbar {
  position: relative;
  height: 7rem;
  width: 100%;
  background-color: var(--colors-neutral-2);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem 2rem;
  box-shadow: inset 0 -1px 0 var(--colors-neutral-20);

  .buttons {
    position: absolute;
    left: 2rem;
    top: 1.5rem;
    display: flex;
  }

  .title {
    display: flex;
    margin: 0 auto;
    font-weight: 600;
    font-size: 2rem;
    line-height: 3rem;
    color: var(--colors-neutral-90);
  }

  .close {
    position: absolute;
    right: 2rem;
    top: 1.5rem;
  }
}
