.root {
  display: flex;

  .title {
    display: flex;
    align-items: center;

    font-weight: 500;
    font-size: 1.5rem;
    line-height: 2rem;
    color: var(--colors-neutral-80);

    margin-left: 1rem;
  }
}

.filterList {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 1rem 0rem 2rem;
}
