.description {
  margin-bottom: 3rem;
}

.subtitle {
  font-weight: 500;
  margin-bottom: 1rem;
}

.font {
  margin-bottom: 1rem;
  line-height: 2rem;
  font-size: 1.5rem;
  color: var(--colors-neutral-90);
}
