.root {
  display: flex;
  margin-bottom: 2rem;
  line-height: 3rem;
  font-size: 1.75rem;
  word-break: break-word;
  justify-content: space-between;
}

.text {
  color: var(--colors-neutral-100);
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 3rem;
}
