.description {
  color: var(--colors-neutral-100);
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 3rem;
}

.container {
  display: flex;

  .group {
    margin-right: 12rem;

    .shortcut {
      font-size: 2.25rem;
      font-weight: 600;
      margin-bottom: 1rem;
    }

    .label {
      color: var(--colors-neutral-80);
      font-size: 1.5rem;
    }

    &:first-child {
      padding-left: 2rem;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
