.root {
  padding: 0 0 0 6rem;
  margin-top: 2rem;

  .content {
    display: flex;
    align-items: center;

    .arrow {
      font-size: 1.75rem;
      font-weight: 500;
      color: var(--colors-neutral-100);
      line-height: 3rem;
      margin: 0 1rem 0 1rem;
    }
  }
}
