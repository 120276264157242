.title_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  font-size: 2rem;
  height: 6rem;
  border-bottom: 1px solid var(--colors-neutral-20);

  .close {
    cursor: pointer;
  }
}

.filters_container {
  display: flex;
  height: 4rem;
}

.results {
  font-size: 1.5rem;
  color: var(--colors-neutral-80);
  margin: 1rem 1rem 0 1rem;
}

.tree_header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: 1.5rem;
  line-height: 2rem;
  color: var(--colors-neutral-60);

  margin: 2rem 0 1rem;
  height: 4rem;
}

.tree_container {
  margin-right: -1rem;
  padding-right: 1rem;
  flex: 1;
  overflow: auto;

  .tree_item_container {
    margin: 1rem 0;
    .tree_item {
      width: 100%;
      border-radius: 0.5rem;
      display: flex;
      justify-content: space-between;
      gap: 1rem;
      height: 3rem;
      cursor: pointer;

      .content {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 31rem;
        color: var(--colors-neutral-80);
      }

      &.p {
        height: 3rem;
        font-size: 1.75rem;
        color: var(--colors-neutral-100);
      }

      &.tbl,
      &.citation,
      &.figure {
        height: 2rem;
        font-size: 1.5rem;
        color: var(--colors-neutral-80);
      }

      .text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &.main-title {
        font-weight: 600;
        color: var(--colors-neutral-100);
        height: 4rem;
        font-size: 1.75rem;
        color: var(--colors-neutral-95);
      }

      .twister {
        margin-right: 1rem;
        transition: transform 200ms linear;
        transform: rotate(-90deg);
        cursor: pointer;

        &.open {
          transform: rotate(0deg);
        }

        &.hidden {
          visibility: hidden;
        }
      }

      &:hover {
        background: var(--colors-neutral-10);
      }

      .wordCount {
        display: flex;
        align-items: center;
        font-size: 1.5rem;
        line-height: 2rem;
        color: var(--colors-neutral-60);
      }

      .tree_itemTitle {
        display: flex;
        align-items: center;
        font-size: 1.75rem;
        overflow: hidden;
      }
    }
  }
}

.empty_state {
  margin: 7rem 8rem 0;
  line-height: 2rem;
  text-align: center;

  overflow: hidden;
  text-overflow: ellipsis;

  .title {
    font-size: 1.75rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  .description {
    font-size: 1.5rem;
    color: var(--colors-neutral-70);
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.filters_popover_container {
  min-width: 21.5rem;
  border-radius: 0;
  box-shadow: 0 4px 8px 0 rgba(var(--colors-neutral-100), 0.2);

  .filters_popover_item {
    padding: 2rem;
    margin: 0 4px;
    border-bottom: 1px solid var(--colors-neutral-10);
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:last-child {
      border-bottom: 0;
    }
  }
}

.loading_state {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 7rem 8rem 0;
  line-height: 2rem;

  .loading_text {
    font-size: 1.5rem;
    font-weight: 500;
    color: var(--colors-neutral-90);
    margin-top: 2rem;
  }
}
