invalid-element {
  user-select: none;

  &.inline {
    display: inline;
    background-color: #fef9bd;
    border: solid 1px var(--colors-neutral-20);
    color: var(--colors-neutral-50);
    outline: 0;
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: center;
    padding: 0 5px;
  }

  &.block {
    margin-top: 5px;
    margin-bottom: 5px;
    width: calc(var(--contentWidth) - var(--marginSize));

    @supports not (-ms-high-contrast: none) {
      /* Non-IE styles here */
      display: flex;
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      /* IE styles here */
      display: block;
    }

    .container {
      height: 6rem;
      width: 100%;
      display: flex;
      flex-direction: row;
      margin: 2px 0;
      position: relative;
    }

    .iconWrapper {
      position: absolute;
      height: 100%;
      width: 40px;
      min-width: 40px;
      max-width: 40px;
      margin-left: -40px;
      top: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-self: flex-start;
      cursor: default;
    }

    .contentWrapper {
      flex-grow: 1;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background-color: #fef9bd;
      border: solid 1px var(--colors-neutral-20);
      color: var(--colors-neutral-50);
      outline: 0;
    }

    .info {
      font-family: Inter;
      font-size: 12px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      text-align: center;
    }
  }
}
