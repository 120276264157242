.root {
  text-align: center;
  letter-spacing: 1px;
  height: 2rem;
  text-transform: uppercase;
  .language {
    & > span {
      cursor: pointer;
      transition: color 0.15s ease;
    }
  }
  &.hero {
    text-align: left;
    font-weight: 500;
    padding: 0;
    height: 2rem;
    font-size: 1.25rem;
    line-height: 2rem;
    color: var(--colors-blue-30);
    & > span {
      height: 2rem;
      font-size: 1.25rem;
      line-height: 2rem;
      &:hover {
        color: var(--colors-blue-5);
      }
      &.active {
        font-weight: bold;
        color: var(--colors-blue-5);
      }
    }
  }
  &.white {
    text-align: center;
    line-height: 1.33;
    color: var(--colors-dark-blue-20);
    & > span {
      &:hover {
        color: var(--colors-blue-50);
      }
      &.active {
        color: var(--colors-blue-50);
      }
    }
  }
}
