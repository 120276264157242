.root {
  display: grid;
  grid-template-columns: 15rem 15rem;
  row-gap: 1rem;

  padding: 2rem;

  font-size: 1.5rem;
  line-height: 2rem;

  .label {
    color: var(--colors-neutral-40);
  }
}
