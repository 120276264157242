.labels {
  flex: 1;
  flex-direction: column;
  justify-content: center;
  display: flex;

  .author {
    display: flex;
    align-items: center;
    font-weight: 500;
    height: 2rem;
    font-size: 12px;
    color: var(--colors-neutral-100);
  }

  .order {
    color: var(--colors-blue-90);
    font-size: 12px;
    line-height: 2rem;
  }
}

.editSubcomment {
  padding: 2rem;
  border-top: solid 1px var(--colors-neutral-20);
  background-color: var(--colors-neutral-5);

  .editButtons {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
  }
}
