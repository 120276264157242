.search {
  position: relative;

  .icon {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    padding: 0.5rem 2rem 1rem 1rem;
  }
}

.listTags {
  margin-top: 4rem;
  overflow: auto;
  padding-right: 5rem;
  display: flex;
  flex-wrap: wrap;
}
