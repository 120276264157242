.actionsContainer {
  display: flex;
  background: var(--colors-white);
  margin-right: 4rem;
}

.togglesContainer {
  display: flex;
  background: var(--colors-white);
  margin-right: 1rem;
}
