.root {
  display: flex;
  flex-direction: column;

  .text {
    font-size: 1.75rem;
    line-height: 3rem;
    color: var(--colors-neutral-80);
    margin: 3rem 1rem 0 1rem;
  }

  .labelText {
    color: var(--colors-neutral-100);
    font-size: 1.75rem;
    font-weight: 500;
    line-height: 3rem;
    margin-top: 5rem;
  }
}
