.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;

  .text {
    text-align: center;
    margin-top: 2rem;
    font-size: 1.75rem;
    line-height: 3rem;
    font-weight: 500;
  }
}
