.root {
  cursor: text;
  user-select: none;

  &.selected {
    user-select: auto;
  }

  /**
   * The first paragraph should not be affected by the spcBef property, which will be rendered as a padding-top
   * The last paragraph should not be affected by the spcAft property, which will be rendered as a padding-bottom
   * Source: PowerPoint online is doing this, they have a css rule 
   * for the first paragraph to dont have a margin-top.
   */
  &.firstParagraph {
    padding-top: 0 !important;
  }
  &.lastParagraph {
    padding-bottom: 0 !important;
  }
}
