.trackedActionsContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 4rem;
  border-radius: 4px;
  margin-left: 0.5px;
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: normal;
  color: var(--colors-neutral-100);

  &.readOnlyMode {
    color: var(--colors-neutral-60);
  }

  .trackedActionsSwitch {
    position: relative;
    display: flex;
    align-items: center;
  }

  .labelContainer {
    margin-right: 1rem;

    &.disabled {
      color: var(--colors-neutral-40);
    }
  }
}
