.editableRoot {
  display: flex;
  flex-direction: column;
  flex: 1;

  font-size: 1.5rem;
  line-height: 2rem;

  .labeledContent {
    display: flex;
    flex-direction: row;

    .labelsWrapper {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      flex: 1;

      color: var(--colors-neutral-80);

      :first-child {
        display: flex;
        align-items: center;
        height: 4rem;
      }
    }

    .valuesWrapper {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      flex: 1;

      min-width: 18rem;

      &.large {
        gap: 3.5rem;
      }

      > * {
        justify-content: flex-end;
      }
    }
  }

  .reminderList {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    margin-top: 1.5rem;
  }
}

.uneditableRoot {
  display: flex;
  flex-direction: row;

  font-size: 1.5rem;
  line-height: 2rem;

  .labelsWrapper {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    flex: 1;

    color: var(--colors-neutral-40);
  }

  .valuesWrapper {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    flex: 1;

    .unset {
      color: var(--colors-neutral-20);
    }
  }
}
