.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .text {
    font-size: 3rem;
    font-weight: 600;
    line-height: 4rem;
    color: var(--colors-neutral-100);
  }
}

.logoContainer {
  position: relative;
  margin-bottom: 5rem;
}
