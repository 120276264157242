.root {
  margin-top: 2rem;

  .title {
    padding-left: 1rem;
    font-size: 1.75rem;
    line-height: 3rem;
    color: var(--colors-neutral-60);
    margin-bottom: 1rem;
  }

  .checkbox {
    padding-left: 1rem;
    display: flex;

    .icon {
      margin-left: 1rem;
      display: flex;
      align-items: center;
    }
  }

  .templateInfo {
    margin-top: 1rem;
    padding-left: 5rem;
    font-size: 1.5rem;
    line-height: 2rem;
    display: flex;
    margin-right: 4rem;
    white-space: nowrap;

    .name {
      margin-left: 3rem;
      color: var(--colors-neutral-50);
      direction: rtl;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
