paragraph-element {
  --hanging: 0pt;
  --leftMargin: 0pt; /* important to properly support word indentations */
  --rightMargin: 0pt;
  --marginSize: calc(var(--leftMargin) - var(--hanging) + var(--rightMargin));
  --lineHeight: 1;

  margin-left: calc(var(--leftMargin) - var(--hanging));
  margin-right: var(--rightMargin);
  padding-left: var(--leftPadding);
  width: auto;
  position: relative;
  clear: both !important;
  min-height: var(--lineHeight, 10pt);

  outline: 0;

  line-height: calc(var(--lineHeight, 1));

  font-family: Times New Roman;
  font-size: 10pt;
  color: rgb(0, 0, 0);
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;

  display: block;
  word-wrap: break-word;
  white-space: pre-wrap;

  --afterContent: '\200B';
  --beforeMargin: 0pt;

  br {
    font-size: var(--fontSize); /* to work with line height */
    font-family: var(--fontFamily);
    font-weight: var(--fontWeight);
    font-style: var(--fontStyle);
  }

  &:before {
    content: var(--afterContent);
    margin-right: var(--beforeMargin);
    display: var(--beforeDisplay, inline-block);
    min-width: var(--beforeWidth);
    text-indent: var(--beforeTextIndent);
    font-size: var(--fontSize);
    font-weight: var(--fontWeight);
    color: var(--color);
    font-style: var(--fontStyle);
    font-family: var(--fontFamily);
    vertical-align: var(--verticalAlign);
    text-decoration: var(--strikethrough);
  }

  track-ins-element[replacewith],
  track-ins-element[replacewithsibling],
  track-del-element[replacewith],
  track-del-element[replacewithsibling] {
    font-family: var(--fontFamily);
    font-size: var(--fontSize);
    font-weight: var(--fontWeight);
    font-style: var(--fontStyle);
    vertical-align: var(--verticalAlign);
  }

  > format-element:first-child:last-child:empty::before,
  > track-ins-element:not(track-ins-element[replacewith]):first-child:last-child:empty::before,
  > track-ins-element:not(track-ins-element[replacewithsibling]):first-child:last-child:empty::before,
  > track-del-element:not(track-del-element[replacewith]):first-child:last-child:empty::before,
  > track-del-element:not(track-del-element[replacewithsibling]):first-child:last-child:empty::before {
    content: '\200B';
  }

  /* WARN: selector must be like this to have a proper hierarchy */
  &[element_type='p'][data-alignment='l'],
  &[element_type='p'][data-alignment='left'] {
    text-align: left;
  }

  /* WARN: selector must be like this to have a proper hierarchy */
  &[element_type='p'][data-alignment='c'],
  &[element_type='p'][data-alignment='center'] {
    text-align: center;
  }

  /* WARN: selector must be like this to have a proper hierarchy */
  &[element_type='p'][data-alignment='r'],
  &[element_type='p'][data-alignment='right'] {
    text-align: right;
  }

  /* WARN: selector must be like this to have a proper hierarchy */
  &[element_type='p'][data-alignment='j'],
  &[element_type='p'][data-alignment='justify'] {
    text-align: justify;
    text-justify: inter-word;

    /* Mozilla specific styles */
    @supports (-moz-appearance: none) {
      white-space: normal;
    }
  }

  &[lock] {
    box-shadow: inset var(--colors-neutral-5) var(--contentWidth) 0,
      0 0 0 1px var(--colors-neutral-20);
  }
}

:global(.dodoc-preview) {
  display: block;
  --hanging: 0pt;
  --leftMargin: 0pt; /* important to properly support word indentations */
  --rightMargin: 0pt;
  --beforeMargin: 1rem;
  --lineHeight: 1.2;
  margin-left: calc(var(--leftMargin) - var(--hanging));
  margin-right: var(--rightMargin);
  padding-left: var(--leftPadding);

  font-family: Times New Roman;
  font-size: 10pt;
  color: rgb(0, 0, 0);
  text-align: left;
  line-height: calc(var(--lineHeight, 1));
  margin-top: 0;
  margin-bottom: 0;
}

:global(.dodoc-preview::before) {
  margin-right: var(--beforeMargin);
  display: var(--beforeDisplay, inline-block);
  min-width: var(--beforeWidth);
  text-indent: var(--beforeTextIndent);
}
