.root {
  text-align: center;

  &.hero {
    text-align: left;
    padding-right: 1rem;
  }
}

.copyrightAndVersion {
  color: var(--colors-blue-50);
  line-height: 2rem;
  font-size: 1.25rem;
  margin-top: 1rem;
}
