.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    color: var(--colors-neutral-70);
    font-size: 2rem;
    margin: 2rem auto;
    line-height: 3.5rem;
  }

  form {
    width: 300px;
    margin: 0 auto;
  }
}

.logoContainer {
  width: 96px;
  height: 96px;
  margin: 0 auto;

  .logo {
    width: 100%;
    height: 100%;
  }
}

.link {
  color: var(--colors-blue-100) !important;

  &:hover {
    color: var(--colors-blue-100);
    border-bottom: 1px solid var(--colors-blue-100);
    cursor: pointer;
  }
}
