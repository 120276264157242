.list {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 34rem;

  .loading {
    padding-top: 8rem;
  }

  .empty {
    padding-top: 3rem;
  }

  .icon {
    opacity: 0.5;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

  .unkown {
    font-style: italic;
    color: var(--colors-neutral-40);
  }
}

.center {
  display: flex;
  justify-content: center;
}
