.root {
  display: flex;
  flex-direction: column;
  width: 30rem;
  padding: 2rem 0 2rem 4rem;

  .title {
    color: var(--colors-neutral-40);
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: 0.7px;
    text-transform: uppercase;
    margin-top: 2rem;
  }

  .permissions {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    margin-top: 2rem;
  }
}
