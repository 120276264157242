/* @function repeat($char, $times) {
  $content: "";

  @for $i from 1 through $times {
    $content: $content + $char;
  }
  @return $content;
} */

tab-element {
  display: inline-flex; /* Important for ToC|ToL, to respect tab width */
  overflow: hidden;
  white-space: pre;
  justify-content: center;
  text-decoration: inherit;
  text-indent: 0;
}

/*
 tab-element::after {
  width: 0;
  content:'\200B';
}
tab-element[data-leading="d"]::after {
  width: 0;
  white-space: nowrap;
  content:repeat(".", 220);
}

tab-element[data-leading="h"]::after {
  width: 0;
  white-space: nowrap;
  content:repeat("-", 220);
}

tab-element[data-leading="u"]::after {
  width: 0;
  white-space: nowrap;
  content:repeat("_", 220);
} */


