.root {
  flex: 1;
  display: flex;
  margin-top: 3rem;
  min-height: 0;

  .search {
    flex: 1;
    padding-right: 2rem;
    display: flex;
    flex-direction: column;
    min-width: 0;
    min-height: 68rem;

    .inputs {
      display: flex;
      margin-bottom: 2rem;
      z-index: 4;
    }

    .results {
      padding-left: 1rem;
      height: 6rem;
      line-height: 6rem;
      color: var(--colors-neutral-80);
      font-weight: 600;
      font-size: 1.5rem;
    }
  }

  .information {
    display: flex;
    flex-direction: column;
  }
}
