.root {
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 100%;
  height: 6rem;

  padding: 1rem 2rem;

  .elementTitle {
    margin-left: 2rem;
    margin-right: auto;
    font-weight: 500;
    font-size: 2rem;
    line-height: 3rem;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
