.item {
  display: flex;
  padding-left: 1rem;
  align-items: center;
  height: 100%;

  &.flex {
    flex: 1;
    min-width: 40rem;
  }

  .name {
    padding-left: 2rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
