.root {
  display: flex;
  height: 7rem;
  box-shadow: inset 0 0 0 1px var(--colors-neutral-20);
  border-radius: 0.5rem;
  padding: 1rem 2rem;

  &.clickable {
    cursor: pointer;
  }

  + .root {
    margin-top: 1rem;
  }

  .info {
    margin-right: auto;
    overflow: hidden;
  }

  .tp {
    display: flex;
    align-items: center;
  }

  .removing {
    cursor: pointer;
    margin-left: 2rem;
    display: flex;
    align-items: center;
  }
}
