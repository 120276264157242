.root {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  min-height: 600px;
  min-width: 1230px;
  position: relative;
}

.form {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  .feedback {
    width: 45rem;
  }

  :global(.feedback-enter) {
    opacity: 0;
  }
  :global(.feedback-enter-active) {
    opacity: 1;
    transition: opacity 200ms;
    width: 45rem;
  }
  :global(.feedback-exit) {
    opacity: 1;
  }
  :global(.feedback-exit-active) {
    opacity: 0;
    transition: opacity 200ms;
    width: 45rem;
  }

  .wrapper {
    margin-top: 3rem;
    width: 45rem;
  }
}

.title {
  font-weight: 600;
  font-size: 3.5rem;
  line-height: 4rem;
  margin: 12rem 0 3rem;
}
