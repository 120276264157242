.preview {
  margin-bottom: 4rem;
  color: var(--colors-neutral-100);

  .label {
    line-height: 2rem;
    font-size: 1.5rem;
  }

  .area {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 19rem;

    .empty {
      color: var(--colors-neutral-50);
      line-height: 3rem;
      font-size: 2rem;
      font-weight: 500;
    }

    .invalid {
      display: flex;
      align-items: center;
      line-height: 3rem;
      font-size: 2rem;
      font-weight: 500;
    }
  }
}
