.root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--colors-neutral-2);
  z-index: 1051;
  padding: 6rem 4rem;
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4rem;
    font-size: 2.25rem;
    font-weight: 600;
  }

  .item {
    overflow: auto;
    padding: 2rem 0 2px 2px;
  }

  .templates {
    display: grid;
    grid-gap: 6rem 4rem;
    grid-template-columns: repeat(auto-fill, 20rem);
  }

  .footer {
    margin: 4rem 1rem 0;
    display: flex;
    justify-content: flex-end;
  }
}
