.root {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;

  .authorContainer {
    height: 6rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 4px;
    background-color: var(--colors-blue-5);

    .infoContainer {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0 3rem 0 2rem;
      height: 6rem;

      .angleIcon {
        cursor: pointer;
        margin-right: 2rem;

        &.isAngleClose {
          transform: rotate(-90deg);
        }
      }

      .mainAuthor {
        margin-left: 2rem;
      }

      .mailIcon {
        opacity: 0.15;
        cursor: pointer;
        margin-left: 2rem;
        margin-right: 3rem;

        &:hover {
          opacity: 0.5;
        }

        &.disabled {
          opacity: 0;
          cursor: default;
        }

        &.corresponding {
          opacity: 1;
        }
      }
    }

    .number {
      color: var(--colors-blue-100);
      font-weight: 600;
      margin-right: 0.5rem;
    }
    .nameContainer {
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      .unknownName {
        color: var(--colors-neutral-50);
      }
    }
  }

  .formContainer {
    padding: 2rem 3rem 0;

    .nameSection {
      display: flex;
      gap: 2rem;
    }

    .elementsContainer {
      position: relative;

      + .elementsContainer {
        margin-top: 1rem;
      }

      .buttonsContainer {
        position: absolute;
        top: 0;
        left: calc(100% + 1rem);
      }
    }
  }

  .userRow {
    display: flex;
    align-items: center;
    padding: 1.5rem 3rem 1.5rem 2rem;
    height: 6rem;
    border-radius: 4px;
    background: var(--colors-red-90);

    &.removing {
      font-size: 14px;
      color: var(--colors-red-5);

      + div {
        border-top: 0;
      }
    }
  }
}
