.page {
  overflow: auto;
  margin-top: 3rem;

  .section {
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 1;

    > .table {
      flex: 1;
      min-width: 0;
    }

    > .details {
      width: 36rem;
      margin-left: 2rem;
      display: flex;
      flex-direction: column;
      max-height: 75rem;

      .content {
        overflow: auto;

        .field {
          margin-top: 2rem;
          gap: 0.5rem;
          font-size: 1.5rem;
          font-weight: 400;
          line-height: 2rem;
          color: var(--colors-neutral-70);
          display: flex;
          flex-direction: column;

          .value {
            color: var(--colors-neutral-100, #2e2e4c);
          }

          .values {
            margin-top: 0.5rem;
            display: flex;
            flex-wrap: wrap;
            gap: 1rem;

            &.fullWidth {
              flex-direction: column;
              margin-right: 1.5rem;
            }

            .label {
              font-size: 1.25rem;
              line-height: 2rem;
              color: var(--colors-neutral-70);
            }

            .value {
              font-size: 1.25rem;
              line-height: 2rem;

              + .label {
                margin-top: 1rem;
              }
            }
          }
        }
      }
    }
  }
}

.notApplicable {
  &.table {
    color: var(--colors-neutral-40);
    font-size: 1.75rem;
    font-weight: 400;
    line-height: 3rem;
  }

  &.details {
    color: var(--colors-neutral-40);
    font-size: 1.5rem;
    font-style: italic;
    font-weight: 400;
    line-height: 2rem;
  }
}

.capitalize {
  text-transform: capitalize;
}
