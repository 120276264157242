.root {
  text-align: center;
  margin-bottom: 0;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 1px;
  font-weight: 500;

  .link {
    display: inline;
    margin: 0;
    cursor: pointer;

    a {
      transition: color 0.15s ease;
    }
  }

  &.hero {
    text-align: left;
    padding: 0;
    font-size: 1.25rem;
    line-height: 2rem;

    .link {
      color: var(--colors-blue-30);
      a {
        color: var(--colors-blue-30);
        font-size: 1.25rem;
        line-height: 2rem;

        &:hover {
          color: var(--colors-blue-5);
        }
      }
    }
  }

  &.white {
    text-align: center;
    line-height: 1.33;

    .link {
      a {
        color: var(--colors-dark-blue-20);

        &:hover {
          color: var(--colors-blue-50);
        }
      }

      span {
        color: var(--colors-dark-blue-20);
      }
    }
  }
}
