.title {
  grid-area: title;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 8rem;
  padding: 1.5rem;
  background-color: var(--colors-neutral-2);
  width: 100%;

  &.divider {
    box-shadow: inset 0 -1px 0 0 var(--colors-neutral-20);
  }

  .clickable {
    cursor: pointer;
  }

  .name {
    margin: 0 1rem;
    font-weight: 600;
    font-size: 2.25rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .text {
    word-break: break-all;
    margin: 0 2rem 0 1rem;
    color: var(--colors-neutral-100);
    display: flex;
    cursor: default;
    min-width: 0;
    &:hover {
      box-shadow: inset 0 0 0 1px var(--colors-neutral-20);
      border-radius: 0.5rem;
      height: 4rem;
      display: flex;
      align-items: center;
    }
  }

  .input {
    max-width: 75rem;
    display: inline-block;
    visibility: visible;
    margin: 0 2rem 0 1rem;
  }

  .container {
    margin-left: auto;

    .avatars {
      display: flex;
      flex-direction: row-reverse;
      margin-left: 4rem;
    }
  }

  .iEnvision {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2rem;
    color: var(--colors-neutral-70);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
