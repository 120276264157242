.search {
  padding-top: 0.5rem;
  margin-bottom: 2rem;
}

.list {
  min-height: 27rem;
  max-height: 43rem;
  height: 100%;
  overflow: auto;

  .row {
    .item {
      padding: 1rem 2rem 1rem 2rem;
    }
  }
}
