.content {
  flex: 1;
  display: flex;
  margin-top: 4rem;
  min-height: 0;
  height: 100%;

  .tableWrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}

.header {
  margin-top: 4rem;
  height: 6rem;
  line-height: 6rem;
  box-shadow: inset 0 -1px 0 var(--colors-neutral-20);
  display: flex;

  .label {
    padding-left: 1rem;
    font-weight: 500;
    font-size: 1.75rem;
    color: var(--colors-neutral-50);
  }
}

.items {
  .item {
    display: flex;
    align-items: center;
    height: 6rem;
    box-shadow: inset 0 -1px 0 0 var(--colors-neutral-20);

    .value {
      padding-left: 1rem;
    }
  }
}

.name {
  width: 30rem;
}
.property {
  width: 16rem;
}

.arrow {
  margin-left: auto;

  + .arrow {
    margin: 0;
  }
}
.options {
  margin-left: 2rem;
}
