.tooltip {
  padding: 2rem;
  font-size: 1.75rem;
  color: var(--colors-neutral-100);

  &.hidden {
    display: none;
  }
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
