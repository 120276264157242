.instance {
  display: flex;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--colors-neutral-100);
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  align-items: center;
  &.current {
    background-color: var(--colors-blue-10);
  }
  &:hover {
    background-color: var(--colors-neutral-10);
  }
  .before {
    overflow: hidden;
    text-overflow: ellipsis;
    direction: rtl;
    white-space: pre;
  }
  .after {
    overflow: hidden;
    text-overflow: ellipsis;
    &.pre {
      white-space: pre;
    }
  }
  .word {
    font-weight: 600;
    white-space: pre;
    &.highlight {
      background-color: var(--colors-blue-90);
    }
  }
}
