.customTabs {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-height: 45rem;
  overflow: auto;
  margin-left: -3px; //Hack to dont cut off box-shadow of elements
  padding-left: 3px; //Hack to dont cut off box-shadow of elements
}

.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}
