.header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 6rem;
  padding: 0 2rem;
  background-color: var(--colors-neutral-5);
  box-shadow: inset 0px -1px 0px 0px var(--colors-neutral-20);
  font-size: 2rem;
  font-weight: 500;

  .headerItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
  }
}
