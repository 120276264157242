.root {
  .wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .options {
      display: flex;
      gap: 2rem;
    }

    .currentOption {
      font-size: 1.75rem;
      font-weight: 400;
      color: var(--colors-neutral-100);
      line-height: 3rem;
    }
  }

  .fields {
    display: flex;
    gap: 3rem;
    margin-top: 3.6rem;

    .section {
      display: flex;
      flex-direction: column;
    }
  }
}
