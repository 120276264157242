.description {
  font-size: 1.75rem;
  line-height: 3rem;
}

.actions {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
}
