.root {
  display: flex;
  flex-direction: column;

  .container {
    display: flex;
    flex-direction: row;
    height: 44.875rem;
    max-height: calc(100vh - 63rem);

    .list {
      display: flex;
      flex: 1;
      flex-direction: column;
      height: 36rem;
      margin-left: 4rem;
      width: 98rem;
      margin-top: 3rem;
      max-height: calc(100vh - 70rem);
    }
  }
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 4rem;
  justify-content: space-between;

  .goBack {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .headerText {
    margin-left: 1rem;
    font-size: 1.75rem;
    font-weight: 500;
    line-height: 3rem;
    color: var(--colors-neutral-90);
  }
}

.title {
  font-size: 2.25rem;
  font-weight: 600;
  color: var(--colors-neutral-90);
  line-height: 3rem;
  margin-bottom: 4rem;
}
