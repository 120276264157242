.replyCard {
  & > * {
    //force background color
    background-color: var(--colors-card-background-reply-default-background);
  }

  .header {
    display: flex;
  }

  .footer {
    display: flex;
    margin-right: auto;
  }

  .imported {
    margin-bottom: 1rem;
    color: var(--colors-neutral-40);
  }
}
