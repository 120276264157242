.user {
  font-size: 1.75rem;
  font-weight: 500;
  color: var(--colors-neutral-80);
  line-height: 3rem;
}

.date {
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--colors-neutral-80);
  line-height: 2.5rem;
}
