.description {
  font-size: 1.5rem;
  font-weight: 400;
  color: var(--colors-neutral-60);
  line-height: 2rem;
  margin-top: 1rem;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  &.empty {
    font-style: italic;
    color: var(--colors-neutral-40);
  }
}
