.readMore {
  cursor: pointer;
  font-size: 1.5rem;
  color: var(--colors-blue-70);
  margin-top: 1rem;
}

.text {
  color: var(--colors-neutral-100);
  line-height: 2rem;
  font-size: 1.5rem;
  word-break: break-word;
  white-space: pre-line;

  &.readLess {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.infoTasks {
  min-height: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;

  .infoText {
    font-size: 1.5rem;
    line-height: 2rem;
    color: var(--colors-neutral-60);
  }

  .listMode {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    line-height: 2rem;
    color: var(--colors-neutral-100);
  }
}

.emptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 12rem;
  padding: 2rem;
  margin-top: 3rem;

  .emptyTitle {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 2rem;
    text-align: center;
    color: var(--colors-neutral-80);
    margin-bottom: 1rem;
  }

  .emptyDesc {
    width: 26.5rem;
    font-size: 1.5rem;
    line-height: 2rem;
    text-align: center;
    color: var(--colors-neutral-60);
  }
}

.tasksContainer {
  padding: 1rem;
  margin: -1rem;
  overflow: auto;

  .minifiedCard {
    cursor: pointer;
    display: flex;
    width: 43rem;
    height: 5rem;
    border-radius: 0.5rem;
    border: solid 1px var(--colors-neutral-20);
    background-color: var(--colors-white);
    padding: 1rem 2rem;
    align-items: center;

    &:hover {
      border-color: var(--colors-neutral-40);
      box-shadow: 0 1px 2px 0 rgba(var(--colors-black), 0.4);

      &.outdated {
        border-color: var(--colors-neutral-40);
        box-shadow: 0 1px 2px 0 rgba(var(--colors-black), 0.4), inset 3px 0 0 0 var(--colors-red-80);
      }
    }

    &.selected {
      border-color: var(--colors-neutral-50);
      box-shadow: 0 0 0 2px var(--colors-blue-100) a(35%);

      &:hover {
        border-color: var(--colors-neutral-50);
        box-shadow: 0 0 0 2px var(--colors-blue-100) a(35%);
      }
    }

    &.done {
      box-shadow: inset 3px 0 0 0 var(--colors-green-80);
      background-color: var(--colors-green-10);
    }

    &.deleted {
      box-shadow: inset 3px 0 0 0 var(--colors-red-80);
      background-color: var(--colors-red-10);

      &.selected {
        border-color: var(--colors-neutral-50);
        box-shadow: 0 0 0 2px rgba(var(--colors-blue-100), 0.35),
          inset 3px 0 0 0 var(--colors-red-80);

        &:hover {
          border-color: var(--colors-neutral-50);
          box-shadow: 0 0 0 2px rgba(var(--colors-blue-100), 0.35);
        }
      }
    }

    .order {
      font-size: 1.5rem;
      line-height: 2rem;
      color: var(--colors-neutral-80);
      margin-right: 0.5rem;
    }

    .description {
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 1.5rem;
      line-height: 2rem;
      color: var(--colors-neutral-100);
    }
  }
}

.appendHeader {
  height: 8rem;
  padding: 2rem;
  border-bottom: solid 1px var(--colors-neutral-20);
  background-color: var(--colors-neutral-5);

  &.done {
    background-color: var(--colors-green-10);
  }

  &.deleted {
    background: var(--colors-red-10);
  }

  .assignedTo {
    font-size: 1.5rem;
    line-height: 2rem;
    color: var(--colors-neutral-60);
  }

  .moreContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .noAvatar {
    width: 4rem;
    height: 4rem;
    border: solid 1px var(--colors-neutral-20);
    background-color: var(--colors-neutral-10);
    margin-right: 1rem;
    border-radius: 50%;
  }

  .noAssigned {
    flex: 1;
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    font-style: italic;
    line-height: 2rem;
    color: var(--colors-neutral-40);
  }
}

.labels {
  flex: 1;

  .author {
    overflow: hidden;
    font-weight: 500;
    color: var(--colors-neutral-100);
    font-size: 1.5rem;
    line-height: 2rem;
    max-height: 2rem;
  }

  .taskNumber {
    font-size: 1.5rem;
    line-height: 2rem;
    color: var(--colors-neutral-80);
  }
}

.replyContainer {
  padding: 2rem;
  border-top: solid 1px var(--colors-neutral-20);
  background-color: var(--colors-neutral-5);

  .reply {
    display: flex;
  }

  .replyButtons {
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;
  }
}

.subComment {
  border-radius: 0;
  background-color: var(--colors-neutral-5);
  border-top: solid 1px var(--colors-neutral-20);
}

.numberOfReplies {
  color: var(--colors-neutral-60);
  font-size: 1.75rem;
  padding-top: 0.6rem;
}

.leftPanel {
  margin-right: auto;
  display: flex;
  align-items: center;
}
