.root {
  display: flex;

  .img_container {
    width: 10rem;
    height: 10rem;
    text-align: center;
    margin-right: 2rem;

    .img {
      object-fit: scale-down;
      max-width: 10rem;
      max-height: 10rem;
      width: auto;
      height: auto;
      border: 1px solid var(--colors-neutral-30);

      &.noTemplate {
        border: 1px dashed var(--colors-neutral-20);
        background-color: var(--colors-white);
        width: 6.5rem;
        height: 9rem;
        margin: 0 1rem;
      }
    }
  }

  .title {
    line-height: 3rem;
    font-weight: 500;
  }
}
