.content {
  padding: 1rem 3rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;

  .emptyState {
    padding: 2rem 2rem;

    .description {
      font-size: 1.5rem;
      color: var(--colors-neutral-70);
      line-height: 2rem;
      text-align: center;

      width: 37rem;
      margin-top: 1rem;
      word-wrap: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .tasksControl {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 2rem 0 2rem 0;

    .tasksCounter {
      font-size: 1.5rem;
      font-weight: 400;
      color: var(--colors-neutral-60);
      line-height: 2rem;
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    overflow: auto;

    //Hack to dont cut off box-shadow of elements
    margin: -4px;
    padding: 4px;
  }
}
