.container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: var(--colors-blue-100);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  color: var(--colors-white);
  margin-top: 15rem;
}
