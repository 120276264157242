.root {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 2rem 0 0 0;

  .header {
    display: flex;
    height: 8rem;
  }

  .page {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    padding: 2rem 6rem 3rem;
    min-height: 0;
  }
}

.userPermissionsText {
  display: flex;
  flex-direction: column;

  .title {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.33;
  }

  .subtitle {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.71;
  }
}

.autocompleteContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
