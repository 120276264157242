.option {
  width: 24.625rem;
  height: 5rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 4rem 1rem 0;
  padding: 1rem;

  &.active {
    background-color: var(--colors-blue-10);
  }

  .text {
    font-size: 1.75rem;
    line-height: 2re;
    color: var(--colors-neutral-100);
    margin-left: 1rem;
  }
}
