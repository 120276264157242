.header {
  display: flex;
  align-items: center;

  height: 3rem;

  font-size: 14px;
  font-weight: 500;
  color: var(--colors-neutral-100);
}

.footer {
  padding: 1rem;
}

.feedbackDueDate {
  height: 4rem;
  color: var(--colors-red-100);
  font-size: 1.5rem;
  font-weight: 500;
  padding: 1rem 0;
  line-height: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.label {
  padding: 1.5rem 0;
  height: 5rem;
  font-size: 1.5rem;
}

.required {
  color: var(--colors-red-100);
  font-weight: 500;
}
