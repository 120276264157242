.list {
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-left: 4rem;
  max-height: 15rem;
  overflow: auto;

  .item {
    padding-bottom: 1rem;
  }
}
