.dropzone {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.root {
  flex: 1;
  display: flex;
  margin-top: 3rem;
  min-height: 0;

  &.drop {
    margin-top: 6rem;
  }

  .list {
    flex: 1;
    padding-right: 2rem;
    display: flex;
    flex-direction: column;
    min-width: 0;
    min-height: 68rem;

    .filter {
      padding-bottom: 1rem;
    }
  }

  .information {
    display: flex;
    flex-direction: column;
  }
}
