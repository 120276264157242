.root {
  .title {
    font-size: 1.25rem;
    font-weight: 500;
    color: var(--colors-neutral-40);
    line-height: 2rem;
    text-transform: uppercase;
    letter-spacing: 0.7px;
    margin: 2rem 0 1rem 2rem;
  }

  .list {
    max-height: 87.5rem;
    overflow-y: auto;
  }
}
