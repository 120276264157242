.root {
  flex: 1;
  display: flex;
  margin-top: 2rem;
  min-height: 0;
  height: 54rem;
}

.noFlex {
  flex: auto !important;
}
