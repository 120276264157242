.list {
  margin-top: 2rem;
  overflow: auto;
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem 1rem 1rem;
    border-bottom: 1px solid var(--colors-neutral-20);

    .user_label {
      font-size: 1.5rem;
      line-height: 2rem;
      color: var(--colors-neutral-60);
      white-space: nowrap;
    }
  }
}
