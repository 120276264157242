.handlerRoot {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  width: 100%;
}

.preceding {
  font-size: 1.5rem;
  align-self: center;
  white-space: nowrap;
}

.emptyField {
  margin-left: auto;
  display: flex;
}
