.root {
  flex: 1;
  padding: 2rem 6rem 0 0;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  height: 8rem;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  min-height: 0;
}
