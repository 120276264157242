.root {
  display: flex;
  justify-content: space-between;

  &.column {
    flex-direction: column;

    .header {
      display: flex;
      margin-bottom: 1rem;
    }
  }

  .tagZone {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }

  .label {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 2rem;
  }
}
